import { Field, Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup"; // For form validation
import { useTranslation } from "react-i18next";
import CustomButton from "../../../components/buttons/Button";
import { ButtonSize, ButtonVariant } from "../../../components/buttons/types";
import { StepContactsProps } from "../../../@types";

// List of countries with codes
const countryOptions = [
  { name: "Portugal", code: "+351", format: "9 digits" },
  { name: "United States", code: "+1", format: "10 digits" },
  { name: "United Kingdom", code: "+44", format: "10 digits" },
  // Add more countries here if needed
];

// Validation schema
const validationSchema = Yup.object({
  firstName: Yup.string().required("Primeiro nome é obrigatório"),
  lastName: Yup.string().required("Último nome é obrigatório"),
});

export default function StepContacts({ onNext, onSkip }: StepContactsProps) {
  const { t } = useTranslation("onboarding");

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        onNext({
          ...values,
        });
      }}
    >
      {({ values, handleChange, setFieldValue, errors, touched, isValid }) => {
        const noFieldsEmpty =
          values.firstName.length > 0 && values.lastName.length > 0;
        const disabled = !isValid || !noFieldsEmpty;

        return (
          <div className="flex w-full mt-20">
            <div className="flex flex-col">
              <h1 className="text-xl font-bold leading-7 mb-1">
                Dados Pessoais
              </h1>
              <h2 className="text-base mt-2">Por favor insira os seus dados pessoais</h2>

              <Form className="w-full mt-4">
                <div>
                  <label className="text-base mb-1 block">Nome*</label>
                  <Field
                    type="text"
                    placeholder="Nome"
                    className={`border focus:ring-default focus:border-default rounded-lg px-4 py-3.5 text-base w-full border-gray-300 ${
                      touched.firstName && errors.firstName
                        ? "border-red-800"
                        : ""
                    }`}
                    id="firstName"
                    name="firstName"
                    value={values?.firstName}
                    onChange={handleChange}
                    required
                  />
                  {touched.firstName && errors.firstName ? (
                    <div className="text-red-800 text-sm">
                      {errors.firstName}
                    </div>
                  ) : null}
                </div>

                <div>
                  <label className="text-base mb-1 block">Nome*</label>
                  <Field
                    type="text"
                    placeholder="Apelido"
                    className={`border focus:ring-default focus:border-default rounded-lg px-4 py-3.5 text-base w-full border-gray-300 ${
                      touched.lastName && errors.lastName
                        ? "border-red-800"
                        : ""
                    }`}
                    id="lastName"
                    name="lastName"
                    value={values?.lastName}
                    onChange={handleChange}
                    required
                  />
                  {touched.lastName && errors.lastName ? (
                    <div className="text-red-800 text-sm">
                      {errors.lastName}
                    </div>
                  ) : null}
                </div>

                {/* Submit Button */}
                <div className="w-full flex justify-between mt-6">
                  <CustomButton
                    className={`w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                      disabled
                        ? "bg-gray-400"
                        : "bg-indigo-600 hover:bg-indigo-700"
                    }`}
                    disabled={disabled}
                    type="submit"
                    variant={ButtonVariant.PRIMARY}
                    size={ButtonSize.MEDIUM}
                  >
                    Próximo
                  </CustomButton>
                </div>
              </Form>
            </div>
          </div>
        );
      }}
    </Formik>
  );
}
