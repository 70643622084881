
const BilledIcon = ({color = "#8D8B8B"} : {color?: string}) => {



    return (
        <svg
            width={26}
            height={26}
            fill="none"
        >
            <path
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={3}
                d="M18.25 9.43C17.23 7.953 15.63 7 13.832 7 10.749 7 8.25 9.798 8.25 13.25s2.499 6.25 5.582 6.25c1.798 0 3.397-.952 4.418-2.43M7 11.375h5m-5 3.75h5m12.5-1.875c0 6.213-5.037 11.25-11.25 11.25S2 19.463 2 13.25 7.037 2 13.25 2 24.5 7.037 24.5 13.25Z"
            />
        </svg>
    )

}
export default BilledIcon