import { FormikProps } from 'formik';
import { ReactElement, useState } from 'react';


import { IMaintenance, Material } from '../../../../@types';
import { WarningModal } from '../../../../components/WarningModal';
import { FormArrayElement } from '../../../../components/FormArrayElement';
import { CustomTextInput } from '../../../../components/CustomTextInput';
import CustomButton from '../../../../components/buttons/Button';
import { FormSectionWrapper } from '../../../../components/FormSectionWrapper';
import { FormArrayWrapper } from '../../../../components/FormArrayWrapper';



function MaterialForm({ idx, item, formikProps, eliminar, editable }: { idx: number, item: Material, formikProps: FormikProps<IMaintenance>, eliminar: () => void, editable: boolean }): ReactElement {


  const { values, handleChange, handleBlur, setFieldValue } = formikProps;

  const [warningModalOpen, setWarningModalOpen] = useState(false);

  return (
    <>
      <WarningModal
        isOpen={warningModalOpen}
        close={() => setWarningModalOpen(false)}
        onConfirm={eliminar}
        mainText='Tem a certeza que pretende eliminar este item?'
        description='Esta acção é irreversível'
      />

      <FormArrayElement
        hideHeader
        editable={editable}
        collapsedView={<div className='rounded-b-lg flex w-full p-2'>
          <div className='mb-1'><div className='font-bold'>Descrição:</div> {item.component}</div>
          <div className=''><div className='font-bold'>Quantidade:</div> {item.quantity}</div>
        </div>}
        form={<div className='w-full'>
          <div className={`my-2 w-full`}>
            {/* <div className="font-bold text-sm mb-1 ml-3">Componente {idx + 1}</div> */}
            <CustomTextInput
              editable={editable}
              onChangeText={(text: string) => setFieldValue(`content.usedMaterials.${idx}.component`, text)}
              onBlur={() => handleBlur(`content.usedMaterials.${idx}.component`)}
              value={item.component}
              placeholder="Descrição do componente"

            />
          </div>
          <div className='flex flex-row w-full items-center justify-between '>
            <div className={`my-2 w-6/12`}>
              <CustomTextInput
                editable={editable}
                onChangeText={(text: string) => {
                  const numericValue = text.replace(/[^\d.,]/g, '').replace(',', '.');
                  setFieldValue(`content.usedMaterials.${idx}.quantity`, numericValue);
                }}
                onBlur={() => handleBlur(`content.usedMaterials.${idx}.quantity`)}
                value={item.quantity}
                placeholder="Qtd."

                keyboardType="numeric"
              />
            </div>


          </div>
          {editable ?

            <div className='items-end justify-end'>
              <CustomButton
                onClick={() => setWarningModalOpen(true)}
              >Eliminar</CustomButton>
            </div>
            : null}
        </div>}
        idx={idx}
        name='Item'
      />
    </>




  )


}




export function MaterialListForm({ formikProps, arrayHelper, editable }: { formikProps: FormikProps<any>, arrayHelper: any, editable: boolean }) {

  const { values } = formikProps;

  const adicionarInterveniente = () => {
    arrayHelper.push({
      component: '',
      quantity: ''
    });
  }

  return (
    <FormSectionWrapper
      label='Lista de Material'
    >
      <FormArrayWrapper<IMaintenance, Material>
        itemName='Item'
        values={values.content.usedMaterials}
        editable={editable}
        formikProps={formikProps}
        arrayHelpers={arrayHelper}
        adicionarItem={adicionarInterveniente}
        formComponent={(props) => <MaterialForm {...props} />}
      />
    </FormSectionWrapper>
  );
}


