


const EmailedIcon = ({color = "#8D8B8B"} : {color?: string}) => {



    return (
        <svg
            width={27}
            height={25}
            fill="none"
        >
            <path
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={3}
                d="m11.042 10.144 1.788 1.722 4.024-3.875M2.037 10.574l9.295 6.222c.828.552 1.243.829 1.69.936.396.095.809.095 1.204 0 .448-.107.863-.384 1.691-.936l9.295-6.222m-13.785-7.66L3.935 7.617c-.706.444-1.06.666-1.316.966a2.584 2.584 0 0 0-.501.907C2 9.867 2 10.284 2 11.118v8.24c0 1.447 0 2.17.282 2.723.247.486.642.88 1.129 1.128.552.282 1.276.282 2.722.282h14.984c1.447 0 2.17 0 2.723-.282a2.582 2.582 0 0 0 1.128-1.128c.282-.553.282-1.276.282-2.723v-8.24c0-.834 0-1.25-.118-1.627a2.583 2.583 0 0 0-.501-.907c-.256-.3-.61-.522-1.316-.966l-7.492-4.705c-.797-.5-1.196-.75-1.623-.848a2.584 2.584 0 0 0-1.15 0c-.427.097-.826.348-1.623.848Z"
            />
        </svg>
    )

}
export default EmailedIcon