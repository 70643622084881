import { useMutation, useQuery } from "@tanstack/react-query";
import { createContext, Dispatch, ReactElement, SetStateAction, useRef, useState } from "react";
import { IClient, IEmployee, IUser } from '../@types';
import { getCompanyEmployees } from "../services/team-profile.service";
import { registerEmployee } from "../services/employee";
import { SIGNUP_ERRORS } from "../errors/errorCodes";
import { getClientList } from "../services/client/getClientList";


export const ClientsContext = createContext<{
    companyClients?: IClient[],
    newClientDialogOpen: boolean,
    setNewClientDialogOpen: Dispatch<SetStateAction<boolean>>,
}>({
    companyClients: [],
    newClientDialogOpen: false,
    setNewClientDialogOpen: () => { },
});



export function ClientsContextProvider({ children }: { children: ReactElement }) {



    const [newClientDialogOpen, setNewClientDialogOpen] = useState(false);

    const { data: clients, isLoading } = useQuery<IClient[]>({
        queryKey: ['clients'],
        queryFn: getClientList,
    });

    
    return (
        <ClientsContext.Provider value={{
            companyClients: clients,
            newClientDialogOpen,
            setNewClientDialogOpen
        }}>
            {children}
        </ClientsContext.Provider>
    )

}