import { ReactElement, useMemo, useState } from "react";
import { IMaintenance } from "../../../../@types";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../contexts/AuthContext";
import { MaintenanceHistory } from "./MaintenanceHistory";
import ExpanderIcon from "../../../../assets/icons/Maintenance/ExpanderIcon";
import { MaintenanceStatus, Role } from "../../../../enums";
import { EditIcon } from "../../../../assets/icons";
import { UseMutateFunction } from "@tanstack/react-query";
import { CalendarIcon } from "../../../../assets/icons/CalendarIcon";
import { ObraScheduling } from "./ObraScheduling";



export function ListItemInfoWrapper({ label, value }: { label: string, value: string | ReactElement }) {

    const dark = false;

    return (
        <div>
            <div className="flex flex-row justify-between items-center my-1">
                <div
                    className={`${dark ? 'text-white' : 'text-black'} text-xs `}>
                    {label}
                </div>
                <div className="flex flex-row w-2/3 justify-end">
                    <div className={`${dark ? 'text-white' : 'text-black'} font-bold `}>
                        {value}
                    </div>
                </div>

            </div>
            <div style={{
                borderColor: "black",
                opacity: 0.3,

            }} className="flex w-full border-t-0.5 my-1"></div>
        </div>
    );
}


function ListHeader({ maintenance, downloadMaintenanceReport }: { maintenance: IMaintenance, downloadMaintenanceReport: () => void }) {

    const navigate = useNavigate();

    const { loggedUser } = useAuth();

    const serialCode = useMemo(() => {
        if (maintenance?.company?.seriesPrefix) {
            return `${maintenance?.company?.seriesPrefix}-${maintenance.serialnumber}`;
        }
        return `${maintenance.serialnumber}`;
    }, [maintenance, maintenance?.company?.seriesPrefix]);



    return (
        <div

            className="p-2 px-3  flex flex-row justify-between rounded-t-[12px] bg-primary">
            <div className="font-bold text-white">{serialCode}</div>
            <div className="flex flex-row justify-end">
                {/* <div
                    onClick={() => null
                        // navigate('MaintenanceForm', { id: maintenance.id })
                    }>
                    {maintenance?.status === MaintenanceStatus.CREATED ? (
                        <>
                            {loggedUser?.role === Role.EMPLOYEE ? (
                                <EditIcon />
                            ) : null}
                        </>
                    ) : (
                        <div>search</div>
                        // <Icon name="search" size={20} color={colors.secondary} />
                    )}
                </div> */}
                {maintenance.status !== MaintenanceStatus.CREATED ? (
                    <div
                        className="ml-4"
                        onClick={() => {
                            return null
                            // downloadMaintenanceReport(maintenance.id);
                        }}>
                        <div className="text-white font-bold">exportar</div>
                        {/* <Icon
                            name="cloud-download-alt"
                            size={20}
                            color={colors.secondary}
                        /> */}
                    </div>
                ) : null}
            </div>
        </div>
    )

}


export function MaintenanceListItem({ downloadMaintenanceReport, maintenance }: { maintenance: IMaintenance, downloadMaintenanceReport: () => void }) {



    const navigate = useNavigate();

    return (
        <div
            key={maintenance.id}
            className='flex flex-col w-full my-3 transition-all duration-300 bg-white shadow hover:shadow-lg cursor-pointer'
            style={{
                borderRadius: 12,
                position: 'relative'
            }}
            onClick={() => navigate(`/obras/${maintenance.id}`)}
        >

            <ListHeader maintenance={maintenance} downloadMaintenanceReport={downloadMaintenanceReport} />
            <div className="p-5">
                {maintenance.status === MaintenanceStatus.SCHEDULED ?
                    <ObraScheduling obra={maintenance}/>
                    : null}

                <div className="flex">

                    <div className="w-2/3">
                        <ListItemInfoWrapper
                            label={'Cliente'}
                            value={`${maintenance.client?.name ? maintenance?.client?.name : '-'} ${maintenance.clientLocation?.name
                                ? `(${maintenance.clientLocation?.name})`
                                : ''
                                }`}
                        />
                        <ListItemInfoWrapper
                            label={'Submetido por'}
                            value={`${maintenance.technician?.name}`}
                        />

                    </div>
                    <div className="w-1/3 ml-5">
                        <MaintenanceHistory maintenance={maintenance} expanded={true} />
                    </div>

                </div>
            </div>

        </div>
    );




}