import { useContext, useState } from 'react';
import { IMaintenance } from '../../../../../@types';
import { MaintenanceStatus } from '../../../../../enums';


import { Formik } from 'formik';

import { useMutation } from '@tanstack/react-query';
import { MaintenanceListContext } from '../../../../../contexts/MaintenanceListContext';
import { billMaintenance } from '../../../../../services/maintenances.service';
import { Dialog } from '@mui/material';
import { InputFieldWithLabel } from '../../../../../components/FieldWithLabel';
import { CustomTextInput } from '../../../../../components/CustomTextInput';
import { sanitizeDecimalInput } from '../../../../../utils/sanitizers';


export function Billing({
  maintenance,
  isOpen,
  close,
  refetchMaintenance,
}: {
  maintenance: IMaintenance;
  isOpen: boolean;
  close: () => void;
  refetchMaintenance: () => void;
}) {
  const { refetchMaintenanceList } = useContext(MaintenanceListContext);

  const editable = maintenance.status === MaintenanceStatus.SUBMITTED;


  const billingMutation = useMutation({
    mutationFn: billMaintenance,
    onSuccess: () => {
      close();
      refetchMaintenanceList();
      refetchMaintenance();

    },
  });

  return (
    <Dialog open={isOpen} title="Faturação">
      <div>
        <div

          className="h-10 w-full flex flex-row p-2 rounded-t-md items-center justify-between bg-primary shadow-md">
          <div className="text-white font-bold">Faturação</div>
          <div onClick={close}>Close</div>

        </div>
        <div

          className="p-2 border border-gray-300 rounded-b-md bg-white">
          <Formik
            initialValues={{
              billingNumber: '',
              billingValue: 0,
            }}
            onSubmit={(values: any) => {
              billingMutation.mutate({
                ...values,
                maintenanceId: maintenance.id,
              });
            }}>
            {formik => {
              const { values, setFieldValue } = formik;

              return (
                <>
                  <InputFieldWithLabel
                    editable={editable}
                    label="Número de faturação ">
                    <CustomTextInput
                      editable={editable}
                      onChangeText={(text: string) =>
                        setFieldValue('billingNumber', text)
                      }
                      name="billingNumber"
                      value={values.billingNumber}
                      placeholder="Introduza número"
                    />
                  </InputFieldWithLabel>
                  <div className="mt-3">
                    <InputFieldWithLabel
                      editable={editable}
                      label="Valor da fatura (€)">
                      <CustomTextInput
                        editable={editable}
                        onChangeText={(text: string) => {
                          const sanitizedText = sanitizeDecimalInput(text);
                          setFieldValue('billingValue', sanitizedText);
                        }}
                        keyboardType="numeric"
                        name="billingValue"
                        value={values.billingValue}
                        placeholder="Introduza valor"
                      />
                    </InputFieldWithLabel>
                  </div>
                  <div className="mt-12 z-50">
                    <div

                      onClick={() => formik.handleSubmit()}
                      className=" rounded-full w-full items-center p-3 mb-2 bg-primary">
                      <div className="text-white">Faturar</div>
                    </div>
                  </div>
                </>
              );
            }}
          </Formik>
        </div>
      </div>
    </Dialog>
  );
}
