import { useMutation } from "@tanstack/react-query";
import { StockActionType } from "../../enums";
import { performActionStock } from "../../../../../services/stock";
import { useStock } from "./useStock";

export const useStockActions = () => {
  const { refetchStock } = useStock();

  const performAction = useMutation({
    mutationFn: ({
      stockId,
      quantity,
      comment,
      action
    }: {
      stockId: number;
      quantity: number;
      comment: string;
      action: StockActionType
    }) => performActionStock(stockId, quantity, comment, action),
    onSuccess: () => {
      refetchStock();
      alert("Action successful!");
    },
    onError: (error) => {
      alert(`Error during stock action: ${error}`);
    },
  });

  

  return {
   performAction
  };
};
