import { axiosInstance } from '..';
import { IClient, NifApiResponse } from '../../@types';

export async function getClient(clientId: number): Promise<IClient> {
  try {
    return (await axiosInstance.get(`/client/${clientId}`)).data;
  } catch (error) {
    console.error('Error get client:', error);
    throw error;
  }
}


export async function getClientList(): Promise<IClient[]> {
  try {
    return (await axiosInstance.get('/client')).data;
  } catch (error) {
    console.error('Error get client list:', error);
    throw error;
  }
}


export async function getNifInfo(nif: string): Promise<NifApiResponse | IClient> {
  try {
    return (await axiosInstance.get(`/3rd-party/nif/${nif}`)).data;
  } catch (error) {
    console.error('Error get nif info:', error);
    throw error;
  }
}
