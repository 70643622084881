import React, { useState } from "react";
import { useTableState } from "../../../hooks/useTableState";
import useSearch from "./hooks/useSearch";
import { ITEMS_PER_PAGE } from "../Stocks/consts";
import RenderObras from "./components/RenderObras";
import Pagination from "./components/Pagination";
import RenderClients from "./components/RenderClients";
import RenderEmployees from "./components/RenderEmployees";

export enum ObraStatus {
  SCHEDULED = "SCHEDULED",
  STARTED = "STARTED",
  CREATED = "CREATED",
  SUBMITTED = "SUBMITTED",
  BILLED = "BILLED",
}

export const statusLabels: { [key: string]: string } = {
  [ObraStatus.SCHEDULED]: "Agendada",
  [ObraStatus.STARTED]: "Iniciada",
  [ObraStatus.CREATED]: "Criada",
  [ObraStatus.SUBMITTED]: "Submetida",
  [ObraStatus.BILLED]: "Faturada",
};

const SearchComponent: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [activeTab, setActiveTab] = useState<string>("obras");

  const [statusFilter, setStatusFilter] = useState<ObraStatus | undefined>(
    undefined
  );
  const { setCurrentPage, currentPage } = useTableState();
  const { obraResults, clientResults, employeeResults, totalPages, loading } =
    useSearch(searchTerm, currentPage, ITEMS_PER_PAGE, statusFilter);

  return (
    <div className="w-full p-4 bg-gray-50 min-h-screen">
      <h1 className="text-3xl font-bold text-center mb-6">Search</h1>
      <div className="flex mb-6 justify-center">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            setCurrentPage(1);
          }}
          placeholder="Search..."
          className="flex-grow p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 shadow"
        />
      </div>
      {activeTab === "obras" && (
        <>
          <div className="text-lg text-blue-800">Filtrar por:</div>
          <div className="flex items-center justify-start">
            <div className="mr-6">Estado da Obra:</div>
            <select
              value={statusFilter}
              onChange={(e) => {
                setStatusFilter(e.target.value as ObraStatus);
                setCurrentPage(1);
              }}
              className="p-2 border border-gray-300 rounded-md mr-4 min-w-[160px] text-gray-700"
            >
              <option value="">Todos</option>
              {Object.values(ObraStatus).map((status) => (
                <option key={status} value={status}>
                  {statusLabels[status]}
                </option>
              ))}
            </select>
          </div>
        </>
      )}
      {loading && <p className="text-gray-500 text-center">Loading...</p>}

      <div className="mt-4">
        <div className="flex justify-center space-x-2 mb-6">
          {["obras", "clients", "employees"].map((tab) => (
            <button
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`px-6 py-2 rounded-lg font-semibold border-b-2 transition duration-200 ${
                activeTab === tab
                  ? "border-blue-600 text-blue-600"
                  : "border-transparent text-gray-600 hover:border-blue-400"
              }`}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </button>
          ))}
        </div>

        {/* Render Results based on active tab */}
        {activeTab === "obras" && <RenderObras obras={obraResults} />}
        {activeTab === "clients" && <RenderClients clients={clientResults} />}
        {activeTab === "employees" && (
          <RenderEmployees employees={employeeResults} />
        )}
      </div>

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};

export default SearchComponent;
