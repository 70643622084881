import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { SubmitStepProps } from "../../../@types";
import CustomButton from "../../../components/buttons/Button";
import { ButtonSize, ButtonVariant } from "../../../components/buttons/types";

export default function SubmitStep({ onNext }: SubmitStepProps) {
  const { t } = useTranslation("onboarding");

  return (
    <>
      <Formik initialValues={{}} onSubmit={onNext}>
        {() => (
                    <div className="flex w-full mt-20">

            <div className="flex flex-col">
              <h1 className="text-xl font-bold leading-7 mb-1">
                ....
              </h1>
              <Form className="w-full  mt-6">
                <div>
                  <h2 className="text-base mt-2"> ....</h2>
                </div>
                <div>
                  {" "}
                  <h2 className="text-base mt-2"> ....</h2>
                </div>
                <div>
                  {" "}
                  <h2 className="text-base mt-2"> ....</h2>
                </div>
                <CustomButton
                  className="w-full mt-6 text-white"
                  type="submit"
                  variant={ButtonVariant.PRIMARY}
                  size={ButtonSize.MEDIUM}
                >
                  Submeter
                </CustomButton>
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
}
