import { Modal } from "@mui/material";
import { ReactElement } from "react";



export function ModalWrapper({open, onClose, children, width} : {open : boolean, onClose : ()=> void, children: ReactElement, width?: `${string}%`}) {


    return (
        <Modal
            open={open}
            onClose={onClose}
            className="p-5 flex flex-col justify-center items-center w-full h-full"
        >
            <div
                style={
                    {
                        width: width ? width : "60%"
                    }}
            className="p-5 bg-white rounded-md overflow-y-auto max-h-screen">
                {children}
            </div>
        </Modal>
    )


}