import React, { useContext, useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { PhotosContext } from '../../../../../contexts/PhotosContext';
import { getMaintenanceAnexo } from '../../../../../services/maintenances.service';


export function ExistingPhotoComponent({ photo }: { photo: any }) {

  const [imageUri, setImageUri] = useState<string | null>(null);

  const { deleteExistingPhoto, setPreviewImage } = useContext(PhotosContext);

  const {
    data: file,
    isLoading: isPhotosLoading,
    error,
  } = useQuery({
    queryKey: ['maintenanceAnexos', photo.id],
    queryFn: () => getMaintenanceAnexo(photo.id),

  });

  useEffect(() => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64data = reader.result as string;
      setImageUri(base64data);
    };
    reader.readAsDataURL(file);
  }, [file])

  if (isPhotosLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    console.error('Error fetching maintenance anexo:', error);
    return <div>Error fetching maintenance anexo</div>;
  }


  return (
    <div className=''>

      {imageUri && (

        <div key={photo.id} onClick={() => setPreviewImage({ ...photo, uri: imageUri })}>
          <img style={{
            flex: 0.3,
            width: window.innerWidth / 3.7,
            height: window.innerWidth / 3.7,
            margin: 2,
            borderRadius: 5
          }} src={imageUri} />
        </div>

      )}

    </div>
  );
};


export default ExistingPhotoComponent;
