import { Dialog } from "@mui/material";
import { useContext } from "react";
import { EmployeesContext } from "../../../contexts/EmployeesContext";
import { EmployeeForm } from "./Employee/Form";
import { ReactComponent as CloseIcon } from "../../../assets/close.svg";


export function NewEmployee() {

    const {
        newEmployeeDialogOpen,
        setNewEmployeeDialogOpen,
    } = useContext(EmployeesContext);

    
    return (
        <Dialog
            open={newEmployeeDialogOpen}
            onClose={() => setNewEmployeeDialogOpen(false)}
        >
            <div className="p-5">
                <div className="flex justify-between items-center pb-3 mb-3 ">
                    <div className="font-bold text-xl text-secondary">Dados do colaborador</div>
                    <CloseIcon className="cursor-pointer" onClick={() => setNewEmployeeDialogOpen(false)} />
                </div>
                <EmployeeForm />
            </div>
        </Dialog>

    )

}