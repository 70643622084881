import {
    Dispatch,
    SetStateAction,
  } from "react";
  import { useTranslation } from "react-i18next";
  import { ReactComponent as ArrowInactive } from "../../../../assets/myArea/rigth-arrow.svg";
  import { ReactComponent as ArrowActive } from "../../../../assets/myArea/rigth-arrow_active.svg";
  import { useNavigate } from "react-router-dom";

import { MenuSection, MenusObject } from "../MyArea";
  
  export function AssessmentsSubMenus() {


    return (
      <>
        <div className="p-3 pr-0 pt-0 flex">
          <div className="flex flex-col bg-Grey-light rounded-md w-[0.1em]"></div>
          <div className="flex flex-col w-full">
            
           
  
            <div className={`ml-2 w-full h-full flex-col pr-2 mb-3`}>
              
              
            </div>
          </div>
        </div>
      </>
    );
  }
  
  const TabContent = ({
    menu,
    isActive,
    onClick,
    setMobileActiveMenu,
  }: {
    menu: MenuSection;
    isActive: boolean;
    onClick: () => void;
    setMobileActiveMenu?: any;
  }) => {
    const { t } = useTranslation("myArea");
  
    const activeStyles = isActive
      ? "bg-Blue-metalic font-semibold"
      : "bg-Blue-ligth text-Grey-dark";
  
    return (
      <>
        <div
          onClick={onClick}
          className={`flex items-center justify-between mb-3 pt-2 pb-3 px-3 rounded-lg cursor-pointer ${activeStyles}`}
        >
          <div className="flex items-center">
            <div className="mr-4">
              {isActive ? menu.iconActive : menu.iconInactive}
            </div>
            {t(`tabs.${menu.name}`)}
          </div>
          <div>{isActive ? <ArrowActive /> : <ArrowInactive />}</div>
        </div>
  
        <div onClick={setMobileActiveMenu}>
          {menu.subMenuComponent && isActive ? menu.subMenuComponent : null}
        </div>
      </>
    );
  };
  
  export function Tabs({
    menus,
    activeMenu,
    setActiveMenu,
    setMobileActiveMenu,
  }: {
    menus: MenusObject;
    activeMenu: MenuSection | null;
    setActiveMenu: Dispatch<SetStateAction<MenuSection | null>>;
    setMobileActiveMenu?: (active: boolean) => void;
  }) {
    const { t } = useTranslation("myArea");
    const navigate = useNavigate();
    const isMobileView = window.innerWidth < 1024;
    return (
      <div className="md:p-4">
        {Object.entries(menus).map(([k, v]: [k: string, v: any]) => {
          return (
            <div className="mt-4">
              {v.length > 0 && (
                <div className="font-bold mb-3 text-Grey-dark">
                  {t(`tabs.${k}`)}
                </div>
              )}
              {v.map((vv: any) => {
                const isActive = activeMenu?.name === vv.name;
                return (
                  <TabContent
                    menu={vv}
                    isActive={isActive}
                    onClick={() => {
                      if (isActive) return;
                      if (!isMobileView) {
                        navigate(vv.route);
                        setActiveMenu(vv);
                      } else {
                        setActiveMenu(vv);
                        if (vv.subMenuComponent) {
                          // If there are submenus, keep the menu open
                          setMobileActiveMenu && setMobileActiveMenu(false);
                        } else {
                          // If no submenus, close the mobile menu
                          setMobileActiveMenu && setMobileActiveMenu(true);
                          navigate(vv.route);
                        }
                      }
                    }}
                    setMobileActiveMenu={setMobileActiveMenu}
                  />
                );
              })}
              {v.length > 0 && <hr className="mt-6" />}
            </div>
          );
        })}
        <div className="flex items-center p-2 pt-3 text-Grey-dark">
         
        </div>
      </div>
    );
  }
  