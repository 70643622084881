import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import {
  getStockAttachment,
  getStockById,
  updateStock,
} from "../../../../../services/stock";
import { useTableState } from "../../../../../hooks/useTableState";

export const ITEMS_PER_PAGE = 10;

export function useStock() {
  const queryClient = useQueryClient();
  const location = useLocation();
  const stockId = parseInt(location.pathname.split("/").pop() || "0", 10);

  const { toggleSortDirection, setCurrentPage, currentPage, sortDirection } =
    useTableState();

  const {
    data: stock,
    isLoading: isLoadingStock,
    refetch: refetchStock,
  } = useQuery<
    { stock: any; metrics: any; actions: any; totalCount: number },
    Error
  >({
    queryKey: ["stock", stockId, currentPage, sortDirection],
    queryFn: () =>
      getStockById(stockId, currentPage, ITEMS_PER_PAGE, sortDirection),
    enabled: stockId > 0,
  });

  const updateStockMutation = useMutation({
    mutationFn: ({
      id,
      stockData,
      files,
    }: {
      id: number;
      stockData: any;
      files?: File[];
    }) => updateStock(id, stockData, files),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["stocks"] });
      refetchStock()
    },
    onError: (error: any) => {
      console.error("Error updating stock:", error);
    },
  });

  const downloadAttachment = async (attachmentId: number) => {
    try {
      const response = await getStockAttachment(attachmentId);
      const url = window.URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "attachment");
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Error downloading the attachment:", error);
    }
  };

  return {
    stock,
    totalActionsCount: stock?.totalCount || 0,
    isLoadingStock,
    downloadAttachment,
    refetchStock,
    currentPage,
    sortDirection,
    toggleSortDirection,
    setCurrentPage,
    updateStockMutation,
  };
}
