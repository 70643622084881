import { useNavigate } from "react-router-dom";
import { IEmployee } from "../../../../@types";
import { EditIcon } from "../../../../assets/icons/EditIcon";
import { ProfilePicturePlaceholder } from "../../../../assets/icons/ProfilePicturePlaceholder";





function CardHeader({ employee, editable = true }: { employee: IEmployee, editable?: boolean }) {


    const navigate = useNavigate();

    return (
        <div className="flex flex-col items-center relative bg-primary border border-0.5 border-primary rounded-t-lg">
            <div className="flex flex-row items-center justify-center w-full p-3">

                <div className="flex flex-row justify-end ">
                    <span className="ml-3  text-white">{employee.name}</span>
                </div>
                {editable ?
                    <div className="absolute top-3 right-3">
                        <div className="w-fit cursor-pointer" onClick={() => navigate(`/colaboradores/${employee.id}`)}>
                            <EditIcon />
                        </div>
                    </div>
                    : null}

            </div>
            <div className="mb-4">
                <ProfilePicturePlaceholder />
            </div>
        </div>
    )
}

export function EmployeeCard({ employee, editable = true }: { employee: IEmployee, editable?: boolean }) {



    return (

        <div className="flex flex-col my-2 rounded-lg shadow-md " >
            <CardHeader employee={employee} editable={editable}/>
            <div className="p-5">

                <div className="flex items-center justify-between">
                    <div className="w-1/3">Email</div>
                    <div className="w-2/3">{employee.email}</div>
                </div>


                {employee?.userDetails?.signature ?
                    <div className="flex flex-row justify-between items-center">
                        <span className={`text-black text-xs `}>Assinatura</span>
                        {/* <Image
                            style={{
                                padding: 0,
                                width: 50,
                                height: 30,
                                backgroundColor: 'white',
                                marginTop: 5,
                                marginBottom: 5,
                                resizeMode: 'contain',

                            }} source={{ uri: employee?.userDetails?.signature }} /> */}
                    </div>
                    : null}
            </div>
        </div>

    )


}