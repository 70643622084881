import React from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { CreateStock } from "../types";
import StockActionSection from "./components/StockActionSection";
import CustomButton from "../../../../components/buttons/Button";
import {
  ButtonSize,
  ButtonVariant,
} from "../../../../components/buttons/types";
import StockActionsHistory from "./components/StockActionHistory";
import { useStock } from "./hooks/useStock";
import { EntityWrapper } from "../../../../components/EntityWrapper";

const StockDetails: React.FC = () => {
  const { stock, isLoadingStock, updateStockMutation, downloadAttachment } =
    useStock();
  const navigate = useNavigate();
  const handleSubmit = async (values: CreateStock, files: File[]) => {
    updateStockMutation.mutate({
      stockData: values,
      id: stock?.stock?.id,
      files,
    });
  };

  const handleCancel = () => {
    navigate("/stock");
  };

  if (isLoadingStock) return <p>A carregar...</p>;
  if (!stock) return <p>Stock não encontrado.</p>;

  return (
    <EntityWrapper title={stock?.stock.name} parentRoute="stock">
      <>
        <div className="flex justify-end">
          {" "}
          <StockActionSection stockId={stock?.stock?.id} />
          <span className="mr-2"></span>
          <StockActionsHistory />
        </div>
        <Formik
          initialValues={
            {
              reference: stock?.stock?.reference,
              name: stock?.stock?.name,
              description: stock?.stock?.description || "",
              quantity: stock?.stock?.quantity,
              provider: stock?.stock?.provider || "",
              attachments: [],
            } as any
          }
          onSubmit={async (values, { setSubmitting }) => {
            const files = values.attachments || [];
            await handleSubmit(values, files);
            setSubmitting(false);
          }}
        >
          {({ setFieldValue, isSubmitting }) => (
            <Form>
              <div className="mb-8 flex">
                {stock?.metrics ? (
                  <div className="flex flex-wrap">
                    <span className="mr-4">
                      <strong>Total de Stock:</strong> {stock.metrics.totalStock}
                    </span>
                    <span className="mr-4">
                      <strong>Stock Disponível:</strong>{" "}
                      {stock.metrics.availableStock}
                    </span>
                    <span className="mr-4">
                      <strong>Reserva Pendente:</strong>{" "}
                      {stock.metrics.reservedPending}
                    </span>
                    <span className="mr-4">
                      <strong>Devoluções Pendentes:</strong>{" "}
                      {stock.metrics.pendingReturns}
                    </span>
                  </div>
                ) : (
                  <p>Sem métricas disponíveis.</p>
                )}
              </div>

              {/* Campos de formulário para editar os detalhes do stock */}
              <div className="mb-4">
                <label className="block text-sm font-medium">Referência</label>
                <Field
                  type="text"
                  name="reference"
                  className="mt-2 p-2 border w-full rounded"
                  placeholder="Referência do Stock"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium">Nome</label>
                <Field
                  type="text"
                  name="name"
                  className="mt-2 p-2 border w-full rounded"
                  placeholder="Nome do Stock"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium">Descrição</label>
                <Field
                  as="textarea"
                  name="description"
                  className="mt-2 p-2 border w-full rounded"
                  placeholder="Descrição"
                  rows={4}
                />
              </div>

              <div className="mb-8">
                <label className="block text-sm font-medium">Fornecedor</label>
                <Field
                  type="text"
                  name="provider"
                  className="mt-2 p-2 border w-full rounded"
                  placeholder="Nome do Fornecedor"
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium">Anexos</label>
                <input
                  type="file"
                  multiple
                  className="mt-2"
                  onChange={(e) => {
                    const files = Array.from(e.currentTarget.files || []);
                    setFieldValue("attachments", files);
                  }}
                />
              </div>
              <div className="mb-4">
                <h3 className="text-lg font-bold mb-2">Anexos Existentes</h3>
                {stock?.stock?.attachments &&
                  stock?.stock?.attachments.length > 0 ? (
                  <ul className="mx-6">
                    {stock?.stock?.attachments.map(
                      (attachment: any, index: any) => {
                        const fileName = attachment.path.split("/").pop();

                        return (
                          <li key={index} className="mb-1">
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                if (attachment.id !== undefined) {
                                  downloadAttachment(attachment.id);
                                } else {
                                  console.error("ID do anexo está indefinido");
                                }
                              }}
                              className="text-blue-600 hover:underline"
                            >
                              {fileName}
                            </button>
                          </li>
                        );
                      }
                    )}
                  </ul>
                ) : (
                  <p>Sem anexos disponíveis.</p>
                )}
              </div>

              <div className="flex justify-end space-x-2">
                <CustomButton
                  type="button"
                  className="mt-2"
                  variant={ButtonVariant.SECONDARY}
                  size={ButtonSize.SMALL}
                  onClick={handleCancel}
                >
                  Cancelar
                </CustomButton>

                <CustomButton
                  type="submit"
                  className="mt-2"
                  variant={ButtonVariant.PRIMARY}
                  size={ButtonSize.SMALL}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "A Atualizar..." : "Atualizar Stock"}
                </CustomButton>
              </div>
            </Form>
          )}
        </Formik>
      </>
    </EntityWrapper>
  );
};

export default StockDetails;
