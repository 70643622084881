


const RememberMeInIcon = () => {

    return (
        <svg
            width={35}
            height={32}
            fill="none"
        >
            <path
                stroke="#D6D03C"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={3}
                d="m4.375 8.02 2.917-2.916m23.333 2.917-2.917-2.917m-14.583 8.75 8.75 8.75m0-8.75-8.75 8.75m16.042-4.375c0 6.444-5.224 11.667-11.667 11.667S5.833 24.672 5.833 18.229 11.057 6.563 17.5 6.563s11.667 5.223 11.667 11.666Z"
            />
        </svg>

    )

}

export default RememberMeInIcon