import axiosInstance from "..";
import { StockActionType } from "../../pages/private/Stocks/enums";

export async function getStocks(
  currentPage = 1,
  itemsPerPage = 10,
  sortDirection?: string
) {
  return (
    await axiosInstance.get(`/stock`, {
      params: {
        currentPage,
        itemsPerPage,
        sortDirection,
      },
    })
  ).data;
}

export async function getStockById(
  id: number,
  currentPage = 1,
  itemsPerPage = 10,
  sortHistoryDirection?: string
) {
  return (
    await axiosInstance.get(`/stock/${id}`, {
      params: {
        currentPage,
        itemsPerPage,
        sortDirection: sortHistoryDirection,
      },
    })
  ).data;
}

export async function createStock(data: any, files?: File[]) {
  const formData = new FormData();
  formData.append("reference", data.reference);
  formData.append("name", data.name);
  formData.append("quantity", data.quantity);
  if (data.provider) {
    formData.append("provider", data.provider);
  }
  if (data.description) {
    formData.append("description", data.description);
  }
  if (files && files.length > 0) {
    files.forEach((file) => {
      formData.append("attachments", file);
    });
  }
  return await axiosInstance.post(`/stock`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function updateStock(id: number, data: any, files?: File[]) {
  const formData = new FormData();
  formData.append("reference", data.reference);
  formData.append("name", data.name);
  if (data.provider) {
    formData.append("provider", data.provider);
  }
  if (data.description) {
    formData.append("description", data.description);
  }

  if (files && files.length > 0) {
    files.forEach((file) => {
      formData.append("attachments", file);
    });
  }

  return await axiosInstance.patch(`/stock/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export async function getStockAttachment(attachmentId: number) {
  return await axiosInstance.get(`/stock/anexos/${attachmentId}`, {
    responseType: "blob",
  });
}

export async function performActionStock(
  stockId: number,
  quantity: number,
  comment: string,
  action: StockActionType
) {
  return (
    await axiosInstance.post(`/stock/${stockId}/actions/${action}`, {
      quantity,
      comment,
    })
  ).data;
}
