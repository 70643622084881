import { LatLngExpression } from "leaflet"
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet"
import { MarkerIcon } from "../../assets/icons/Marker"
import { LocationMarker } from "./LocationMarker.tsx"



export function Map({
    coords,
    popupLabel,
    height=320,
    width="100%",
    zoom= 15,
    scrollWheelZoom = false
} : {
    coords: LatLngExpression,
    popupLabel?: string,
    height?: number,
    width?: `${string}%`| number,
    zoom?: number,
    scrollWheelZoom?: boolean
}) {


    return (
        <MapContainer
            center={coords}
            style={{ height, width }}
            zoom={zoom}
            scrollWheelZoom={scrollWheelZoom}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <LocationMarker 
                coordinates={coords}
                editable={false}
                updateCoords={() => null}
            />
        </MapContainer>
    )


}