import { useOnPageMount } from "../../../hooks/useOnPageMount";
import { useContext } from "react";
import { EmployeeCard } from "./components/EmployeeCard";
import CustomButton from "../../../components/buttons/Button";
import { ButtonSize, ButtonVariant } from "../../../components/buttons/types";
import { AddIcon } from "../../../assets/icons/AddIcon";
import { PrivatePageWrapper } from "../../../components/PrivatePageWrapper";
import { NewEmployee } from "./NewEmployee";
import { EmployeesContext, EmployeesContextProvider } from "../../../contexts/EmployeesContext";


function EmployeesContent() {
  useOnPageMount();


  const {
    companyEmployees,
    setNewEmployeeDialogOpen
  } = useContext(EmployeesContext);


  return (
    <PrivatePageWrapper title="Colaboradores" headerButtons={<CustomButton
      variant={ButtonVariant.SECONDARY}
      size={ButtonSize.SMALL}
      onClick={() => setNewEmployeeDialogOpen(true)}
    >
      {/* <div className="flex items-center">
        <AddIcon /> */}
        <div >Adicionar colaborador</div>
      {/* </div> */}

    </CustomButton>}>
      <NewEmployee />
      <div className="grid grid-cols-3 gap-4">
        {companyEmployees?.map((employee) => <EmployeeCard employee={employee} />)}
      </div>

    </PrivatePageWrapper>
  );
}


export function Employees() {

  return (
    <EmployeesContextProvider>
      <EmployeesContent />
    </EmployeesContextProvider>
  )

}

