import { FormikProps } from "formik";

import { MaintenanceType } from "../../../../enums";

import { IMaintenance } from "../../../../@types";
import { FormSectionWrapper } from "../../../../components/FormSectionWrapper";
import { InputFieldWithLabel } from "../../../../components/FieldWithLabel";
import { CustomTextInput } from "../../../../components/CustomTextInput";
import { CustomDropdownPicker } from "../../../../components/CustomDropdownPicker";



export function MaintenanceIdentificationForm({ formikProps, editable }: { formikProps: FormikProps<IMaintenance>, editable: boolean }) {


    const { values, setFieldValue, handleBlur } = formikProps;

    return (
        <FormSectionWrapper label="Identificação da avaria / serviço">
            <InputFieldWithLabel editable={editable} direction='row' label="Garantia">
                {editable ?
                    <input
                    type="checkbox"
                        disabled={false}
                        checked={values.content.maintenanceIdentification?.warranty || false}
                        onChange={(newValue) => setFieldValue('content.maintenanceIdentification.warranty', newValue)}
                    /> : <div >{values.content.maintenanceIdentification?.warranty ? 'Sim' : 'Não'}</div>
                }
            </InputFieldWithLabel>
            <InputFieldWithLabel
                editable={editable}
                direction='row'
                label="Tipo de avaria">
                <CustomDropdownPicker<MaintenanceType>
                    title={"Tipo de avaria"}
                    editable={editable}
                    searcheable={false}
                    value={values.content.maintenanceIdentification?.type || ''}
                    setValue={(value: any) => setFieldValue('content.maintenanceIdentification.type', value)}
                    options={Object.values(MaintenanceType)}
                />
            </InputFieldWithLabel>
            <InputFieldWithLabel
                editable={editable}
                label="Identificação">
                <CustomTextInput
                    editable={editable}
                    onChangeText={(text: string) => setFieldValue('content.maintenanceIdentification.description', text)}
                    onBlur={() => handleBlur('content.maintenanceIdentification.description')}
                    value={values.content.maintenanceIdentification?.description || ''}
                    placeholder="Identificação"
                />
            </InputFieldWithLabel>

        </FormSectionWrapper>
    )

}