import { useTranslation } from "react-i18next";
import { ReactComponent as Logo } from "../../assets/MDP.svg";

export function PublicFooter() {
  const { t } = useTranslation("common");

  return (
    <footer className="fixed inset-x-0 bottom-0 z-50 bg-Grey-navbar p-4 flex items-center justify-between h-12">
      <div className="flex flex-col md:flex-row ml-2">
        <a href="/terms-of-service" className="mr-6  md:mb-0">
          {t("footer.terms")}
        </a>
        <a href="/privacy-policy" className="mr-6 md:mb-0">
          {t("footer.privacy")}
        </a>
        <a href="/cookie-policy" className="mr-6 lg:mb-0">
          {t("footer.cookie")}
        </a>
        <a href="/support" className="mr-6">
          {t("footer.support")}
        </a>
        <a href="/imprint" className="mr-6 ">
          {t("footer.imprint")}
        </a>
        <a
          href="https://www.google.com"
          target="_blank"
          rel="noopener noreferrer"
          className="mr-6 "
        >
          {t("footer.about")}
        </a>
      </div>
      <a href="/" className="cursor-pointer">
        <Logo className="h-8" />
      </a>
    </footer>
  );
}
