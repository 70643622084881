import { useFormikContext } from "formik";
import DatePicker, { registerLocale } from "react-datepicker";
import pt from 'date-fns/locale/pt';
import { IClient, IMaintenance } from "../../../../../@types";
import { SchedulingStatus } from ".";
import { CalendarIcon } from "../../../../../assets/icons/CalendarIcon";

registerLocale('pt', pt as any);


export function Calendar() {

    const { setFieldValue, values } = useFormikContext<IMaintenance & { scheduledDateTime: Date, schedulingStatus: SchedulingStatus, timezone: string, client: IClient }>();


    return (
        <div className="mb-10 z-50">


            <div className="font-bold mb-3 border-b">Data da manutenção</div>
            <div className="flex items-center ">
                <div className="mr-6 ">
                    {values.scheduledDateTime?.toLocaleString('pt')}
                </div>
                <div className="relative w-2/3">
                    <div className="absolute top-0 left-0">
                        <CalendarIcon size={40} />
                    </div>
                    <DatePicker
                        className=" w-10 h-10 opacity-0 cursor-pointer"
                        popperPlacement="right-top"
                        showTimeSelect
                        locale={"pt"}
                        timeFormat="HH:mm"
                        timeIntervals={15} // Allows 15-minute intervals for time selection
                        dateFormat="MMMM d, yyyy, hh:mm"
                        timeCaption="Hora"
                        selected={values.scheduledDateTime}
                        onChange={(date) => setFieldValue('scheduledDateTime', date)}
                    />
                </div>

            </div>
        </div>
    )

}