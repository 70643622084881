import { useEffect, useState } from "react";
import { InternalAxiosRequestConfig } from "axios";
import { axiosInstance } from "../services";
import { IUser } from "../@types";
import { refreshTokenService } from "../services/auth.service";

export const useInterceptRequest = (user: IUser | null) => {
  const [requestInterceptor, setRequestInterceptor] = useState<any>(null);

  useEffect(() => {
    if (user) {
      const interceptorId = axiosInstance.interceptors.request.use(
        (config: InternalAxiosRequestConfig) => {
          const isRefreshRequest = config.url?.endsWith("/auth/refresh");
          if (!isRefreshRequest) {
            const token = localStorage.getItem("maintenaid-access-token");
            config.headers["Authorization"] = `Bearer ${token}`;
          }
          return config;
        },
        (error) => {
          console.log("Error adding token!");
          return Promise.reject(error);
        }
      );
      setRequestInterceptor(interceptorId);
    } else {
      // Check if requestInterceptor is set before ejecting
      if (requestInterceptor) {
        axiosInstance.interceptors.request.eject(requestInterceptor);
      }
    }

    // Clean up the interceptor when unmounting
    return () => {
      if (requestInterceptor) {
        axiosInstance.interceptors.request.eject(requestInterceptor);
      }
    };
  }, [user]);
};

export const useInterceptorResponse = (user: IUser | null, logoutUser: any, softLogoutUser: any) => {
  const [responseInterceptor, setResponseInterceptor] = useState<any>(null);

  useEffect(() => {

    if (responseInterceptor) {
      axiosInstance.interceptors.response.eject(responseInterceptor);
    }

    console.log("running useintercptor response effect")
    const interceptorId = axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        if (error.response?.status === 401 && user) {
          const errorMessage = error.response.data.message;
          // TODO CHANGE THIS!!!!
          if (errorMessage === "EXPIRED") {
            const refreshToken = localStorage.getItem(
              "maintenaid-refresh-token"
            );
            if (refreshToken) {
              try {
                const { accessToken, newRefreshToken } =
                  await refreshTokenService();
                localStorage.setItem("maintenaid-access-token", accessToken);
                localStorage.setItem(
                  "maintenaid-refresh-token",
                  newRefreshToken
                );
                error.config.headers.Authorization = `Bearer ${accessToken}`;

                return axiosInstance(error.config);
              } catch (refreshError) {
                console.error("Error refreshing access token:", refreshError);
                softLogoutUser();
              }
            } else {
              softLogoutUser();
            }
          } else {
            softLogoutUser();
          }
        }

        return Promise.reject(error);
      }
    );
    setResponseInterceptor(interceptorId);

    return () => {
      if (responseInterceptor) {
        axiosInstance.interceptors.response.eject(responseInterceptor);
      }
    };
  }, [user]);
};
