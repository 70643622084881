import React from "react";
import { Link } from "react-router-dom";
import DetailsList from "./DetailsList"; 

const RenderEmployees: React.FC<{ employees: any[] }> = ({ employees }) => (
  <div className="grid grid-cols-1 gap-4">
    {employees.length > 0 ? (
      employees.map((employee) => (
        <Link
          key={employee.id}
          to={`/colaboradores/${employee.id}`}
          className="block p-4 border border-gray-300 rounded-lg shadow-md bg-white hover:bg-gray-100 transition"
        >
          <h2 className="text-xl font-bold">Employee ID: {employee.id}</h2>
          <p className="text-gray-700">Name: {employee.name}</p>
          <p className="text-gray-700">Role: {employee.role}</p>
          {employee.matches.length > 0 && <DetailsList matches={employee.matches} />}
        </Link>
      ))
    ) : (
      <p className="text-gray-500 text-center">No Employees found.</p>
    )}
  </div>
);

export default RenderEmployees;
