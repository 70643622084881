import { Dialog, Modal } from "@mui/material";
import { ClientForm } from "./Client/Form/ClientForm";
import { useContext } from "react";
import { ReactComponent as CloseIcon } from "../../../assets/close.svg";
import { ClientsContext } from "../../../contexts/ClientsContext";



export function NewClient() {


    const {newClientDialogOpen, setNewClientDialogOpen} = useContext(ClientsContext);

    return (
        <Modal
            open={newClientDialogOpen}
            onClose={() => setNewClientDialogOpen(false)}
            className="m-5 flex flex-col justify-center items-center"
        >
            <div className="p-5 bg-white w-2/3 rounded-md overflow-y-auto">
                <div className="flex justify-between items-center pb-3 mb-3 ">
                    <div className="font-bold text-xl text-secondary">Dados do cliente</div>
                    <CloseIcon className="cursor-pointer" onClick={() => setNewClientDialogOpen(false)} />
                </div>
                <ClientForm />
            </div>
        </Modal>

    )



}