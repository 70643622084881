import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useContext, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { updateLocale } from "../../services/users.service";
import { LANGUAGES } from "../../i18n";
import { useMutation } from "@tanstack/react-query";


function LanguageDropdownContent() {

    const { i18n } = useTranslation();
    const { user } = useContext(AuthContext);;

    const updateLocaleMutation = useMutation({
        mutationKey: ['localeUpdate'],
        mutationFn: updateLocale
    });

    const getFormattedLang = (lang: string) => {
        if (lang === LANGUAGES.en) {
            return "en";
        } else if (lang === LANGUAGES.pt) {
            return "pt";
        } else {
            return "en";
        }
    }


    const changeLanguageHandler = (lang: string) => {
        i18n.changeLanguage(lang);
        localStorage.setItem('language', lang);
        if (user?.cookieSettings?.preferencesCookiesAccepted) {
            updateLocaleMutation.mutate({ locale: getFormattedLang(lang) as ('pt' | 'en') });
        }
    }

    return (
        <div className="absolute top-5 left-0 bg-white border border-zinc-500 rounded">{
            Object.entries(LANGUAGES).map(([k, v]) => {
                return <div className="px-2 hover:bg-zinc-200" onClick={() => changeLanguageHandler(k)}>{v}</div>
            })
        }</div>
    )

}



export function LanguageDropDown() {

    const { i18n } = useTranslation();

    const [dropdownOpened, setDropdownOpened] = useState<boolean>(false);

    return (
        <div className="relative">
            <button className="flex h-full items-center" onClick={() => setDropdownOpened(!dropdownOpened)}>
                <div>{i18n.language}</div>
                {dropdownOpened ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                {dropdownOpened ? <LanguageDropdownContent /> : null}
            </button>
        </div>
    )

}