import { axiosInstance } from ".";



export async function getAddressFromCoordinates(latitude: number, longitude: number) {
  return (await axiosInstance.get(`/3rd-party/location/gps?latitude=${latitude}&longitude=${longitude}`)).data;
}


export async function getCoordinatesFromCP(cp4?: string, cp3?: string) {
  return (await axiosInstance.get(`/3rd-party/location/cp/${cp4}?cp3=${cp3}`)).data;
}