export enum ToolActionType {
  REGISTER = "register", // Tool added to the system (initial registration)
  RESERVATION = "reserve", // Tool reserved for a specific job or purpose
  RESERVATION_FULFILLED = "reservation_fulfilled", // Reservation fulfilled, tool is ready for pick-up
  WITHDRAW = "withdraw", // Tool taken out of storage for use (check-out)
  RETURN_PENDING = "return_pending", // Tool return initiated but awaiting confirmation (pending inspection or approval)
  RETURN_CONFIRMED = "return_confirmed", // Tool return confirmed and approved (checked back into inventory)
  DISPOSE = "dispose", // Tool permanently removed from inventory (broken, lost, or retired)
  TRANSFER = "transfer", // Tool transferred to another location or department
  MAINTENANCE = "maintenance", // Tool sent for maintenance or repair
  DEPOSIT = "deposit", // Tool deposited back into storage
}

export const toolActionTypeMap: { [key in ToolActionType]: string } = {
  [ToolActionType.REGISTER]: "Registo", // Tool added to the system
  [ToolActionType.RESERVATION]: "Reserva", // Tool reserved for a job or purpose
  [ToolActionType.RESERVATION_FULFILLED]: "Reserva Concluída", // Reservation fulfilled, tool ready for use
  [ToolActionType.WITHDRAW]: "Levantamento", // Tool taken out of storage for use
  [ToolActionType.RETURN_PENDING]: "Devolução Pendente", // Tool return initiated, awaiting approval
  [ToolActionType.RETURN_CONFIRMED]: "Devolução Confirmada", // Tool return confirmed and checked back into inventory
  [ToolActionType.DISPOSE]: "Eliminada", // Tool permanently removed from inventory
  [ToolActionType.TRANSFER]: "Transferência", // Tool transferred to another location or department
  [ToolActionType.MAINTENANCE]: "Manutenção", // Tool sent for maintenance or repair
  [ToolActionType.DEPOSIT]: "Depósito", // Tool deposited back into storage
};


