import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createStock, getStocks } from "../../../../services/stock";
import { CreateStock, Stock } from "../types";
import { ITEMS_PER_PAGE } from "../consts";
import { useTableState } from "../../../../hooks/useTableState";

export function useStockList() {

  const {toggleSortDirection, setCurrentPage, currentPage, sortDirection} = useTableState()
  const queryClient = useQueryClient();

  const {
    data: stocks,
    isLoading: isLoadingStocks,
    refetch: refetchStocks,
  } = useQuery<{ stocks: Stock[]; totalCount: number }, Error>({
    queryKey: ["stocks", currentPage, sortDirection],
    queryFn: () => getStocks(currentPage, ITEMS_PER_PAGE, sortDirection),
  });

  const createStockMutation = useMutation({
    mutationFn: ({ data, files }: { data: CreateStock; files?: File[] }) =>
      createStock(data, files),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["stocks"] });
    },
    onError: (error: any) => {
      console.error("Error creating stock:", error);
    },
  });
  
  return {
    stocks: stocks?.stocks,
    totalCount: stocks?.totalCount || 0,
    isLoadingStocks,
    refetchStocks,
    currentPage,
    sortDirection,
    toggleSortDirection,
    setCurrentPage,
    createStockMutation,
  };
}
