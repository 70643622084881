import { IRoute } from "./@types";
import { Clients } from "./pages/private/Clients";
import { Client } from "./pages/private/Clients/Client";
import Dashboard from "./pages/private/Dashboard";
import { Employees } from "./pages/private/Employees";
import { Employee } from "./pages/private/Employees/Employee";
import { Obras } from "./pages/private/Maintenances";
import { MaintenanceForm } from "./pages/private/Maintenances/Form";
import { MyArea } from "./pages/private/MyArea/MyArea";
import SearchComponent from "./pages/private/Search/Search";
import StockPage from "./pages/private/Stocks";
import StockDetails from "./pages/private/Stocks/Stock";
import ToolPage from "./pages/private/Tools";
import ToolDetails from "./pages/private/Tools/Tool";


export const routes : IRoute[] = [
    {
        path: '/',
        element: <Dashboard/>,
        nameCode: 'home'
    },
    // {
    //     path: '/management',
    //     element: CompanyManagement,
    //     nameCode: 'management'
    // },
    {
        path: '/obras',
        element: <Obras/>,
        nameCode: 'obras'
    },
    {
        path: '/obras/:id',
        element: <MaintenanceForm/>,
        nameCode: 'obras'
    },
    {
        path: '/colaboradores',
        element: <Employees/>,
        nameCode: 'colaboradores'
    },
    {
        path: '/colaboradores/:id',
        element: <Employee/>,
        nameCode: 'colaborador'
    },
    {
        path: '/clientes',
        element: <Clients/>,
        nameCode: 'clientes'
    },
    {
        path: '/clientes/:id',
        element: <Client/>,
        nameCode: 'cliente'
    },
    {
        path: '/profile',
        element: <MyArea/>,
        nameCode: 'profile'
    },
    {
        path: '/profile/*',
        element: <MyArea/>,
        nameCode: 'profile',
        hidden: true
    },
    {
        path: '/search',
        element: <SearchComponent/>,
        nameCode: 'search'
    },
    {
        path: '/stock',
        element: <StockPage/>,
        nameCode: 'stock'
    },
    {
        path: '/stock/:id',
        element: <StockDetails/>,
        nameCode: 'stock',
        hidden: true
    },
    {
        path: '/tool',
        element: <ToolPage/>,
        nameCode: 'tool'
    },
    {
        path: '/tool/:id',
        element: <ToolDetails/>,
        nameCode: 'tool',
        hidden: true
    },
    // {
    //     path: '/terms-of-service',
    //     element: TermsOfService,
    //     nameCode: 'terms-of-service',
    //     hidden: true
    // },
    // {
    //     path: '/privacy-policy',
    //     element: PrivacyPolicy,
    //     nameCode: 'privacy-policy',
    //     hidden: true
    // },
    // {
    //     path: '/cookie-policy',
    //     element: CookiePolicy,
    //     nameCode: 'cookie-policy',
    //     hidden: true
    // },
    // {
    //     path: '/about',
    //     element:<About,
    //     nameCode: 'about',
    //     hidden: true
    // },
    // {
    //     path: '/support',
    //     element: Support,
    //     nameCode: 'support',
    //     hidden: true
    // },
    // {
    //     path: '/imprint',
    //     element: Imprint,
    //     nameCode: 'imprint',
    //     hidden: true
    // },
    // {
    //     path: '/forbidden',
    //     element: Forbidden,
    //     nameCode: 'forbidden',
    //     hidden: true
    // },
    // {
    //     path: '/notfound',
    //     element: NotFound,
    //     nameCode: 'notfound',
    //     hidden: true
    // },
]  
