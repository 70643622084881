import { PrivatePageWrapper } from "../../../components/PrivatePageWrapper";
import { useAuth } from "../../../contexts/AuthContext";
import { useOnPageMount } from "../../../hooks/useOnPageMount";


export default function Dashboard() {
  
  useOnPageMount();
  const {loggedUser} = useAuth();

  
  return (
    <PrivatePageWrapper title="Dashboard">
        <div>Bem vindo {loggedUser?.name}</div>    
    </PrivatePageWrapper>
  );
}
