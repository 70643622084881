import React from "react";
import { Modal } from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { CreateTool } from "../types";
import CustomButton from "../../../../components/buttons/Button";
import {
  ButtonSize,
  ButtonVariant,
} from "../../../../components/buttons/types";

const AddToolSchema = Yup.object().shape({
  reference: Yup.string().required("Referência é obrigatória"),
  name: Yup.string().required("Nome é obrigatório"),
  description: Yup.string(),
  provider: Yup.string(),
});

interface AddToolModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: CreateTool, files: File[]) => void;
}

const AddToolModal: React.FC<AddToolModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  const handleSubmit = (values: any, { setSubmitting }: any) => {
    const attachments = values.attachments || [];
    onSubmit(values, attachments);
    setSubmitting(false);
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className="p-6 bg-white rounded-lg shadow-lg w-full max-w-lg mx-auto mt-20">
        <h2 className="text-xl font-bold mb-4">Adicionar Nova Ferramenta</h2>
        <Formik
          initialValues={
            {
              reference: "",
              name: "",
              description: "",
              provider: "",
              attachments: [],
            } as CreateTool
          }
          validationSchema={AddToolSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, setFieldValue, handleChange }) => (
            <Form>
              <div className="mb-4">
                <label className="block text-sm font-medium">Referência*</label>
                <Field
                  type="text"
                  name="reference"
                  className="mt-2 p-2 border w-full rounded"
                  placeholder="Referência da Ferramenta"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium">Nome*</label>
                <Field
                  type="text"
                  name="name"
                  className="mt-2 p-2 border w-full rounded"
                  placeholder="Nome da Ferramenta"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium">Descrição</label>
                <Field
                  as="textarea"
                  name="description"
                  className="mt-2 p-2 border w-full rounded"
                  placeholder="Descrição"
                  rows={4}
                />
              </div>
              
              <div className="mb-4">
                <label className="block text-sm font-medium">Fornecedor</label>
                <Field
                  type="text"
                  name="provider"
                  className="mt-2 p-2 border w-full rounded"
                  placeholder="Nome do Fornecedor"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium">Anexos</label>
                <input
                  type="file"
                  multiple
                  className="mt-2"
                  onChange={(e) => {
                    const files = Array.from(e.currentTarget.files || []);
                    setFieldValue("attachments", files);
                  }}
                />
              </div>
              <div className="flex justify-end space-x-2">
                <CustomButton
                  className="mt-2"
                  variant={ButtonVariant.SECONDARY}
                  size={ButtonSize.SMALL}
                  onClick={onClose}
                >
                  Cancelar
                </CustomButton>
                <CustomButton
                  className="mt-2"
                  type="submit"
                  variant={ButtonVariant.PRIMARY}
                  size={ButtonSize.SMALL}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "A Submeter..." : "Adicionar Ferramenta"}
                </CustomButton>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default AddToolModal;
