


export function NotificationsIcon({color = "white"} : {color?: string}) {




    return (
        <svg width="25" height="27" viewBox="0 0 28 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.2093 5.0303C16.5381 5.0303 18.7714 5.79653 20.4181 7.16044C22.0647 8.52433 22.9898 10.3742 22.9898 12.303C22.9898 15.2865 23.7135 17.5206 24.6521 19.1635C25.8511 21.2626 26.4506 22.3121 26.4173 22.5518C26.3784 22.8317 26.3288 22.9086 26.082 23.072C25.8704 23.2121 24.8182 23.2121 22.7137 23.2121H5.70483C3.60031 23.2121 2.54807 23.2121 2.33654 23.072C2.08979 22.9086 2.04021 22.8317 2.00132 22.5518C1.96797 22.3121 2.5675 21.2626 3.76657 19.1635C4.70506 17.5206 5.42878 15.2865 5.42878 12.303C5.42878 10.3742 6.35387 8.52433 8.00052 7.16044C9.64719 5.79653 11.8805 5.0303 14.2093 5.0303ZM14.2093 5.0303V2M9.90683 27.7576C11.0551 28.702 12.5636 29.2752 14.2158 29.2752C15.8678 29.2752 17.3763 28.702 18.5247 27.7576" stroke={color} stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )



}