import { Marker, Popup, useMap } from "react-leaflet";
import L, { LatLngExpression } from "leaflet";
import LocationMarkerIcon from '../../../assets/location-marker.svg';

export function LocationMarker({
    coordinates,
    updateCoords,
    editable,
    popupLabel
}: {
    coordinates: LatLngExpression;
    updateCoords:(newCoords: {lat: number, lng: number}) => void,
    editable: boolean,
    popupLabel?: string
}) {


    const homeMarker = L.icon({
        iconUrl: LocationMarkerIcon,
        iconSize: [40, 40],
        iconAnchor: [18, 40],
        popupAnchor: [0, -34]
    });

    
    const onDragEnd = (e : any) => {
        if(!editable) return;
        console.log("Dragged: ", e.target.getLatLng());
        updateCoords(e.target.getLatLng());
      };


    return (
        <>
            <Marker
                autoPan={false}
                draggable={editable}
                eventHandlers={{
                    mouseover: (event) => event.target.openPopup(),
                    mouseout: (event) => event.target.closePopup(),
                    dragend: onDragEnd
                }}
                position={coordinates}
                icon={homeMarker}
            >
                {/* <Popup className="">
                    <div className="relative text-white">
                        Hello!!
                    </div>
                </Popup> */}
            </Marker>
        </>

    );
}
