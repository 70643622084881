import { Formik } from "formik";
import { IClient, IEmployee, IMaintenance, Location } from "../../../../../@types";

import { getClientList } from "../../../../../services/client/getClientList";
import { useMutation, useQuery } from "@tanstack/react-query";
import { ClientPicker } from "./ClientPicker";
import { ClientLocations } from "./ClientLocations";
import CustomButton from "../../../../../components/buttons/Button";
import { ButtonSize, ButtonVariant } from "../../../../../components/buttons/types";
import { getCompanyEmployees } from "../../../../../services/team-profile.service";
import { TechinicianPicker, TechnicianOptions } from "./TechnicianPicker";
import { Calendar } from "./Calendar";
import { scheduleObra } from "../../../../../services/obra";
import { useContext } from "react";
import { MaintenanceListContext } from "../../../../../contexts/MaintenanceListContext";

export enum SchedulingStatus {
    PENDING = 'PENDING',
    CONFIRMED = 'CONFIRMED',
    CANCELLED = 'CANCELLED',
}


export function ScheduleObraForm({ obra, onClose }: { obra?: IMaintenance, onClose: ()=> void }) {


    const {
        refetchMaintenanceList
    } = useContext(MaintenanceListContext);

    const { data: clients } = useQuery<IClient[]>({
        queryKey: ['clients'],
        queryFn: getClientList,
    });

    const { data: employees } = useQuery<IEmployee[]>({
        queryKey: ['employees'],
        queryFn: getCompanyEmployees
    })

    const scheduleObraMutation = useMutation({
        mutationFn: scheduleObra,
        onSuccess: () => {
            refetchMaintenanceList();
            onClose();
        }
    })


    return (
        <Formik
            initialValues={{
                scheduledDateTime: new Date(),
                schedulingStatus: SchedulingStatus.CONFIRMED,
                timezone: 'UTC',
                client: obra?.client || 0,
                clientLocation: obra?.clientLocation
                    ? obra.clientLocation
                    : ({} as Location),
                involvedTechnicians: obra?.involvedTechnicians
                    ? obra.involvedTechnicians
                    : [],
            } as IMaintenance & { scheduledDateTime: Date, schedulingStatus: SchedulingStatus, timezone: string, client: IClient }}
            onSubmit={(values) => {
                scheduleObraMutation.mutate(values);
            }}
        >
            {(formik) => {
                const { values, setFieldValue } = formik;
                return (
                    <div className="flex flex-col w-full">
                        <Calendar />
                        <div className="mb-10">
                            <div className="font-bold border-b mb-5">Especificação de cliente</div>
                            <ClientPicker clients={clients || []} />
                            {values?.client ?
                                <div className="mt-2">
                                    <div>Nome: {values.client?.name}</div>
                                    <div className="mt-5">
                                        <ClientLocations />
                                    </div>
                                </div>
                                : null}
                        </div>


                        <TechinicianPicker employees={employees || []} />

                        <div className="flex w-full justify-end mt-8">
                            <CustomButton
                                onClick={() => formik.submitForm()}
                                type={"submit"}
                                variant={ButtonVariant.SECONDARY}
                                size={ButtonSize.SMALL}
                            >
                                <div>Agendar</div>
                            </CustomButton>
                        </div>
                    </div>

                )
            }}



        </Formik>
    )

}