import { useQuery } from "@tanstack/react-query";
import { fetchSearchResults } from "../../../../services/search.service";
import { ITEMS_PER_PAGE } from "../../Stocks/consts";
import { ObraStatus } from "../Search";

const useSearch = (searchTerm: string, currentPage: number, limit: number, status?: ObraStatus) => {

  const { data: search, isLoading } = useQuery<
    { obras: any; clients: any; employees: any; totalCount: number },
    Error
  >({
    queryKey: ["search", searchTerm, currentPage, status],
    queryFn: () => fetchSearchResults(searchTerm, currentPage, ITEMS_PER_PAGE, status),
  });

  const obraResults = search?.obras || [];
  const clientResults = search?.clients || [];
  const employeeResults = search?.employees || [];
  const totalPages = Math.ceil((search?.totalCount || 1) / limit);

  return {
    obraResults,
    clientResults,
    employeeResults,
    totalPages,
    loading: isLoading,
  };
};

export default useSearch;
