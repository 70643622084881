


export function LogoIcon({ size = 87 }: { size?: number }) {


    return (
        <svg width={size} height={size * 1.11} viewBox="0 0 87 97" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M47 22.3848C59.0532 24.1723 68.3013 34.5627 68.3013 47.1131C68.3013 51.2816 67.281 55.2119 65.4766 58.6678L83.7447 73.9965L84.0837 73.8028C85.6413 72.9125 86.6025 71.2559 86.6025 69.4618V25.7643C86.6025 23.9702 85.6413 22.3137 84.0837 21.4234L47 0.227179V22.3848ZM77.2671 77.699L61.3865 64.3736C56.834 69.1422 50.4146 72.1131 43.3013 72.1131C35.7166 72.1131 28.9208 68.7355 24.336 63.4023L8.12346 77.0062L40.8201 95.6949C42.3575 96.5736 44.245 96.5736 45.7824 95.6949L77.2671 77.699ZM18.3013 47.1131C18.3013 50.8014 19.1 54.3031 20.5339 57.4548L1.72337 73.2387C0.640622 72.2995 0 70.9265 0 69.4618V25.7643C0 23.9702 0.961218 22.3137 2.51882 21.4234L40 0V22.3291C27.7532 23.9447 18.3013 34.4251 18.3013 47.1131Z" fill="#FF6600" />
        </svg>

    )

}