import { FieldArray, Formik } from 'formik';
import { useMaintenanceForm } from './useMaintenanceForm';
import {
  IMaintenance,
  Location,
  Machine,
  MaintenanceIdentification,
  Material,
} from '../../../../@types';
import { ClientForm } from './ClientForm';

import { MaintenanceStatus } from '../../../../enums';
import { MachineForm } from './MachineForm';
import { MaterialListForm } from './MaterialListForm';
import { MaintenanceIdentificationForm } from './MaintenanceIdentificationForm';
import { useContext, useMemo, useRef, useState } from 'react';
import { TechnicianManagementForm } from './InvolvedTechniciansForm';
import { ClientValidationForm } from './ClientValidation';

import { Billing } from './Billing/BillingForm';
import { BillingInfo } from './Billing';
import { Photos } from './Photos';

import { ActionMenu, ClientInfoMissingWarningModal } from './components';
import * as yup from 'yup';
import { RememberIn } from './RememberIn';
import { useAuth } from '../../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { CustomTextInput } from '../../../../components/CustomTextInput';
import CustomButton from '../../../../components/buttons/Button';
import { ButtonSize, ButtonVariant } from '../../../../components/buttons/types';
import { FormErrors } from '../../../../utils/constants';
import { FormSectionWrapper } from '../../../../components/FormSectionWrapper';
import { EntityWrapper } from '../../../../components/EntityWrapper';



const clientValidationSchema = yup.object().shape({
  // Field 'items' must be an array with length > 0
  locations: yup.array()
    .of(
      yup.object().shape({
        name: yup.string().required(FormErrors.REQUIRED_FIELD_ERROR),
        cp4: yup.string().length(4, 'Código postal inválido').required(FormErrors.REQUIRED_FIELD_ERROR),
        cp3: yup.string().length(3, 'Código postal inválido').required(FormErrors.REQUIRED_FIELD_ERROR),
        address: yup.string().required(FormErrors.REQUIRED_FIELD_ERROR),
      })
    )
    .min(1, 'Deve especificar pelo menos uma localização')
    .required(FormErrors.REQUIRED_FIELD_ERROR),

  // Field 'identifier' must be a string with length exactly 9
  nif: yup.string()
    .length(9, 'NIF deve conter 9 caractéres')
    .required(FormErrors.REQUIRED_FIELD_ERROR),

  // Other fields can be validated here
  name: yup.string().required(FormErrors.REQUIRED_FIELD_ERROR),
  email: yup.string().email('Endereço de email inválido')

});

const validationSchema = yup.object().shape({
  client: clientValidationSchema
});


export function MaintenanceForm() {
  const { loggedUser } = useAuth();
  const scrollRef = useRef<any>();


  // const { formData, clearFormDataCache, saveFormDataToCache } = useFormCache();

  const {
    isLoading,
    maintenance,
    saveMaintenance,
    submitMaintenance,
    validating,
    setValidating,
    submitting,
    billingOpen,
    setBillingOpen,
    refetchMaintenance,
    photosOpen,
    setPhotosOpen,
    rememberInOpen,
    setRememberInOpen,
    rememberMaintenanceIn,
    markMaintenanceSent
  } = useMaintenanceForm();

  const [signScreenOpen, setSignScreenOpen] = useState(false);
  const navigate = useNavigate();
  const [actionsOpen, setActionsOpen] = useState(false);

  const [noClientWarningModalOpen, setNoClientWarningModalOpen] = useState(false);

  const serialCode = useMemo(() => {
    if (maintenance?.company?.seriesPrefix) {
      return `${maintenance?.company?.seriesPrefix}-${maintenance.serialnumber}`;
    }
    return `${maintenance?.serialnumber}`;
  }, [maintenance, maintenance?.company?.seriesPrefix]);

  if (isLoading) {
    return <div>Loading</div>
  }

  return (
    <>
      {submitting ? (
        <div >Loading...</div>
      ) : (
        <EntityWrapper title={serialCode} parentRoute='obras'>
          <Formik
            initialValues={
              {
                id: maintenance ? maintenance.id : null,
                content: {
                  usedMaterials: maintenance?.content
                    ? maintenance.content?.usedMaterials
                    : ([] as Material[]),
                  responsiblePersonName: maintenance?.content
                    ? maintenance.content?.responsiblePersonName
                    : '',
                  maintenanceIdentification: maintenance?.content
                    ? maintenance.content?.maintenanceIdentification
                    : ({} as MaintenanceIdentification),
                  anomaliesAndCauses: maintenance?.content
                    ? maintenance.content?.anomaliesAndCauses
                    : '',
                  machine: maintenance?.content
                    ? maintenance.content?.machine
                    : ({} as Machine),
                  description: maintenance?.content
                    ? maintenance.content?.description
                    : '',
                  clientInput: maintenance?.content
                    ? maintenance.content?.clientInput
                    : { observations: '', clientSignature: '' },
                  observationTech: maintenance?.content
                    ? maintenance.content?.observationTech
                    : '',
                },
                client: maintenance?.client ? maintenance.client : null,
                clientLocation: maintenance?.clientLocation
                  ? maintenance.clientLocation
                  : ({} as Location),
                involvedTechnicians: maintenance?.involvedTechnicians
                  ? maintenance.involvedTechnicians
                  : [
                    {
                      technician: loggedUser,
                      order: 1,
                      workingDays: [
                        {
                          date: new Date(),
                          startTime: Date.now(),
                          endTime: Date.now(),
                          otherExpenses: '',
                          offTime: 0,
                          distance: 0,
                        },
                      ],
                    },
                  ],
              } as IMaintenance
            }
            validateOnChange={false}
            validationSchema={validationSchema}
            onSubmit={values => console.log('')}>
            {formik => {
              const { setFieldValue, handleBlur, values, isValid, setValues } =
                formik;
              const normalFieldsEditable =
                !validating && maintenance?.status === MaintenanceStatus.CREATED;
              const validationFieldsEditable =
                validating && maintenance?.status === MaintenanceStatus.CREATED;



              return (
                <div className="flex flex-col flex-1 w-full">

                  <div className='p-2 px-3 border-b-[0.2px] border-gray-300'>

                    <div className=" flex flex-row w-full items-center justify-between ">


                      <div
                        onClick={() => setActionsOpen(!actionsOpen)}>
                        <div>Options</div>

                      </div>
                    </div>



                  </div>




                  {billingOpen && maintenance ? (
                    <Billing
                      refetchMaintenance={refetchMaintenance}
                      maintenance={maintenance}
                      isOpen={billingOpen}
                      close={() => setBillingOpen(false)}
                    />
                  ) : null}
                  {photosOpen && maintenance ? (
                    <Photos
                      maintenance={maintenance}
                      refetchMaintenance={refetchMaintenance}
                      isOpen={photosOpen}
                      close={() => setPhotosOpen(false)}
                    />
                  ) : null}

                  {
                    rememberInOpen && maintenance ?
                      <RememberIn
                        rememberMaintenanceIn={rememberMaintenanceIn}
                        isOpen={rememberInOpen}
                        close={() => setRememberInOpen(false)}
                        maintenance={maintenance}
                      />
                      : null
                  }

                  {/* <BottomDrawer
                    visible={actionsOpen}
                    close={() => setActionsOpen(false)}
                  >
                    <ActionMenu
                      normalFieldsEditable={normalFieldsEditable}
                      validationFieldsEditable={validationFieldsEditable}
                      saveMaintenance={saveMaintenance}
                      setValidating={setValidating}
                      setActionsOpen={setActionsOpen}
                      scrollRef={scrollRef}
                      values={values}
                      maintenance={maintenance}
                      openBilling={() => setBillingOpen(true)}
                      openPhotos={() => setPhotosOpen(true)}
                      openRememberIn={() => setRememberInOpen(true)}
                      markMaintenanceSent={markMaintenanceSent}
                    />
                  </BottomDrawer> */}


                  <div
                    ref={scrollRef}
                    className="flex  flex-1 flex-col h-full ">
                    <div className="flex flex-1 flex-col justify-between p-3">
                      {maintenance ?
                        <BillingInfo openBilling={() => setBillingOpen(true)} maintenance={maintenance} />
                        : null}



                      <div>
                        <ClientForm
                          formikProps={formik}
                          editable={normalFieldsEditable}
                        />

                        <MachineForm
                          formikProps={formik}
                          editable={normalFieldsEditable}
                        />

                        <MaintenanceIdentificationForm
                          formikProps={formik}
                          editable={normalFieldsEditable}
                        />
                        <FormSectionWrapper label="Anomalias / Causas Observadas">
                          <CustomTextInput
                            editable={normalFieldsEditable}
                            multiline={true}
                            numberOfLines={4}
                            onChangeText={(text: string) =>
                              setFieldValue('content.anomaliesAndCauses', text)
                            }
                            onBlur={() =>
                              handleBlur('content.anomaliesAndCauses')
                            }
                            value={values.content.anomaliesAndCauses || ''}
                            placeholder="Introduza uma descrição das anomalias e/ou causas observadas..."

                          />
                        </FormSectionWrapper>

                        <FormSectionWrapper label="Descrição / Serviço">
                          <CustomTextInput
                            editable={normalFieldsEditable}
                            onChangeText={(text: string) =>
                              setFieldValue('content.description', text)
                            }
                            onBlur={() => handleBlur('content.description')}
                            value={values.content.description || ''}
                            placeholder="Introduza uma descrição do serviço..."

                          />
                        </FormSectionWrapper>

                        <FieldArray
                          name="content.usedMaterials"
                          render={arrayHelpers => {
                            return (
                              <MaterialListForm
                                editable={normalFieldsEditable}
                                formikProps={formik}
                                arrayHelper={arrayHelpers}
                              />
                            );
                          }}
                        />

                        <FieldArray
                          name="involvedTechnicians"
                          render={arrayHelpers => {
                            return (
                              <TechnicianManagementForm
                                editable={normalFieldsEditable}
                                validating={validating}
                                formikProps={formik}
                                arrayHelper={arrayHelpers}
                              />
                            );
                          }}
                        />

                        <FormSectionWrapper label="Observações Técnico">
                          <CustomTextInput
                            editable={normalFieldsEditable}
                            multiline={true}
                            numberOfLines={4}
                            onChangeText={(text: string) =>
                              setFieldValue('content.observationTech', text)
                            }
                            onBlur={() => handleBlur('content.observationTech')}
                            value={values.content.observationTech || ''}
                            placeholder="Introduza observações..."

                          />
                        </FormSectionWrapper>

                        {validationFieldsEditable ||
                          maintenance?.status !== MaintenanceStatus.CREATED ? (
                          <ClientValidationForm
                            formikProps={formik}
                            editable={validationFieldsEditable}
                            openSignatureScreen={() => {
                              setSignScreenOpen(true);
                            }}
                          />
                        ) : null}
                      </div>
                      <div>
                        {validationFieldsEditable ? (
                          <CustomButton
                            variant={ButtonVariant.SECONDARY}
                            size={ButtonSize.SMALL}
                            disabled={
                              !values.content.clientInput.clientSignature
                            }
                            onClick={() => {
                              submitMaintenance(values);
                            }}
                          >Submeter</CustomButton>
                        ) : null}
                        <ClientInfoMissingWarningModal
                          close={() => setNoClientWarningModalOpen(false)}
                          isOpen={noClientWarningModalOpen}
                          confirm={() => {
                            setNoClientWarningModalOpen(false);
                            scrollRef.current?.scrollTo({
                              y: 0,
                              animated: true,
                            });
                          }}
                          client={values.client}
                        />
                        {normalFieldsEditable ? (
                          <CustomButton
                            onClick={() => {
                              if (!values.client || !Object.keys(values.clientLocation).length) {
                                setNoClientWarningModalOpen(true);
                                return;
                              }
                              console.log("Client location: ", values.clientLocation);
                              setValidating(true);
                              scrollRef.current?.scrollTo({
                                y: 0,
                                animated: true,
                              });
                            }}
                          >
                            Validação do cliente
                          </CustomButton>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              );
            }}
          </Formik>
        </EntityWrapper>
      )}
    </>
  );
}
