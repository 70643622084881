

export function PrivatePageWrapper({ title, headerButtons, children }: { title: string, headerButtons?: JSX.Element, children: JSX.Element | JSX.Element[] }) {


    return (
        <div className="flex flex-col flex-1 p-5 ovefrlow-hidden">
            <div className="flex items-center justify-between mb-6">
                <h2 className="text-xl font-bold text-black">{title}</h2>
                {headerButtons}
            </div>
            {children}
        </div>
    )

}