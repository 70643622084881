import { useState } from "react";
import { ReactComponent as Close } from "../../../assets/close.svg";
import { useTranslation } from "react-i18next";
import { PopupWrapper } from "../../../components/PopUpWrapper";
import CustomButton from "../../../components/buttons/Button";
import { ButtonSize, ButtonVariant } from "../../../components/buttons/types";
import { MyAreaTitle } from "./components/MyAreaTitle";
import { LanguageDropDown } from "../../../components/navbar/LanguageDropdown";


export function Settings() {
  const { t } = useTranslation("myArea");


  const [popupOpen, setPopupOpen] = useState(false);

//   const handleSubmit = async (payload: UserDetails) => {
//     try {
//       // handle success
//       await submitNewUserDetails.mutate({ userDetails: payload });
//     } catch (error) {
//       console.log("ERROR: ", error);
//     }
//   };


//   const submitNewUserDetails = useMutation(
//     ["updateUserDetails"],
//     updateUserDetails,
//     {
//       onSuccess: (data) => setUser(data),
//     }
//   );

  return (
    <div>
      <PopupWrapper isOpen={popupOpen}>
        <div className="bg-white bg-opacity-100 w-1/4 p-5 rounded-lg">
          <div className="flex mb-4 justify-between items-center text-lg font-bold">
            <div className="w-10/12">{t("settings.delete-confirmation")}</div>
            <div className="flex justify-center w-2/12 ">
              <Close
                className="cursor-pointer"
                onClick={() => setPopupOpen(false)}
              />
            </div>
          </div>
          <div className="text-sm text-Grey-dark mb-4">
            {t("settings.delete-warning")}
          </div>
          <div className="flex justify-between">
            <CustomButton
              className="w-full mr-2"
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.MEDIUM}
              onClick={() => setPopupOpen(false)}
            >
              {t("settings.cancel")}
            </CustomButton>
          </div>
        </div>
      </PopupWrapper>
      <div className="hidden lg:flex">
        <MyAreaTitle title="Settings" />
      </div>

      <div className="mt-9">
        <h2 className="text-Grey-dark font-bold mb-2">
          {t("settings.language")}
        </h2>
        <LanguageDropDown />
      </div>
    </div>
  );
}
