import { FormikProps } from "formik";
import { IMaintenance, InvolvedTechnician, WorkingDay } from "../../../../../@types";
import { useState } from "react";
import { WarningModal } from "../../../../../components/WarningModal";
import { FormArrayElement } from "../../../../../components/FormArrayElement";
import { InputFieldWithLabel } from "../../../../../components/FieldWithLabel";
import { CustomTextInput } from "../../../../../components/CustomTextInput";
import CustomButton from "../../../../../components/buttons/Button";
import { FormArrayWrapper } from "../../../../../components/FormArrayWrapper";
import { sanitizeMinutesInput } from "../../../../../utils/sanitizers";



function WorkingDayForm({
    item,
    editable,
    idx,
    technicianIdx,
    formikProps,
    eliminar,
    validating
}: {
    item: WorkingDay;
    idx: number;
    technicianIdx: number,
    formikProps: FormikProps<IMaintenance>,
    eliminar: () => void;
    editable: boolean;
    validating: boolean
}) {

    const { values, setFieldValue } = formikProps;

    const [warningModalOpen, setWarningModalOpen] = useState(false);

    const [displayTime, setDisplayTime] = useState<'date' | 'startTime' | 'endTime' | null>(null);

    const updateDatesForTimeFields = (key: "startTime" | "endTime", newDate?: Date) => {
        if (!newDate) return;
        const timeToUpdate = new Date(item[key]);
        timeToUpdate.setFullYear(newDate.getFullYear());
        timeToUpdate.setMonth(newDate.getMonth());
        console.log("Day is: ", newDate.getDate())
        timeToUpdate.setDate(newDate.getDate());

        setFieldValue(
            `involvedTechnicians.${technicianIdx}.workingDays.${idx}.${key}`,
            timeToUpdate.getTime(),
        );
    }


    const handleDateChange = (event: any, selectedDate?: Date) => {
        setDisplayTime(null);
        if (displayTime === 'date') {
            setFieldValue(
                `involvedTechnicians.${technicianIdx}.workingDays.${idx}.date`,
                selectedDate?.getTime(),
            );
            updateDatesForTimeFields('startTime', selectedDate);
            updateDatesForTimeFields('endTime', selectedDate);
            return;
        }

        setFieldValue(
            `involvedTechnicians.${technicianIdx}.workingDays.${idx}.${displayTime}`,
            selectedDate?.getTime(),
        );

    }

    return (
        <>
            <WarningModal
                isOpen={warningModalOpen}
                close={() => setWarningModalOpen(false)}
                onConfirm={eliminar}
                mainText='Tem a certeza que pretende eliminar este dia de trabalho?'
                description='Esta acção é irreversível'
            />
            <FormArrayElement
                editable={editable}
                idx={idx}
                hideHeader
                name="Dia de trabalho"
                collapsedView={<>

                    <div key={idx} className='border-t-0.5'>
                        <div className="flex flex-row w-full justify-between items-center my-1">
                            <div className="font-bold">{`Dia ${idx + 1}`}</div>
                            <div>
                                {new Date(item.date).toLocaleString('pt').split(" ")[0]}
                            </div>
                        </div>
                        <div className="flex flex-row w-full justify-between items-center my-1">
                            <div className="font-bold">Hora de entrada:</div>
                            <div>
                                {new Date(item.startTime).toLocaleTimeString('pt')}
                            </div>
                        </div>
                        <div className="flex flex-row w-full justify-between items-center my-1">
                            <div className="font-bold">Hora de saída:</div>
                            <div>
                                {new Date(item.endTime).toLocaleTimeString('pt')}
                            </div>
                        </div>
                        <div className="flex flex-row w-full justify-between items-center my-1">
                            <div className="font-bold">Tempo ausente:</div>
                            <div>
                                {item.offTime} min.
                            </div>
                        </div>
                        <div className="flex flex-row w-full justify-between items-center my-1">
                            <div className="font-bold">Tempo total de trabalho:</div>
                            <div>
                                {((new Date(item.endTime).getTime() -  new Date(item.startTime).getTime() - ((item.offTime || 0) * 60 * 1000)) / (1000 * 60 * 60)).toFixed(2)} h
                            </div>
                        </div>
                        <div className="flex flex-row w-full justify-between items-center my-1">
                            <div className="font-bold">Deslocação:</div>
                            <div>
                                {item.distance}
                            </div>
                        </div>
                        <div className="flex  w-full  my-1">
                            <div className="font-bold">Outras despesas:</div>

                            <div>
                                {item.otherExpenses.description} {!validating ? `(${item.otherExpenses.value} €)` : ''}
                            </div>
                        </div>
                    </div>

                </>}
                form={<>
                    {
                        displayTime ? (
                            <div>Date time picker</div>
                         
                        ) : null}
                    <div className="flex flex-row w-full items-center justify-between">
                        <div>Dia:</div>
                        <div
                            onClick={() => setDisplayTime('date')}
                            className="flex flex-row mb-2">
                            <div className="mr-2">
                                {new Date(item.date).toLocaleString(
                                    'pt',
                                ).split(" ")[0]}
                            </div>
                            <div>Calendar Icon</div>
                            
                        </div>
                    </div>
                    <div className="flex flex-row w-full items-center justify-between">
                        <div>Hora de entrada:</div>
                        <div
                            onClick={() => setDisplayTime('startTime')}
                            className="flex flex-row mb-2">
                            <div className="mr-2">
                                {new Date(item.startTime).toLocaleTimeString(
                                    'pt',
                                )}
                            </div>
                            <div>Clock Icon</div>
                            
                        </div>
                    </div>
                    <div className="flex flex-row w-full items-center justify-between">
                        <div>Hora de saída:</div>
                        <div
                            onClick={() => setDisplayTime('endTime')}
                            className="flex flex-row mb-2">
                            <div className="mr-2">
                                {new Date(item.endTime).toLocaleTimeString(
                                    'pt',
                                )}
                            </div>
                            <div>Clock Icon</div>
                            
                        </div>
                    </div>
                    <InputFieldWithLabel
                        label='Tempo ausente'
                        editable={editable}
                        direction="row"
                    >
                        <div className="flex flex-row justify-end mr-2">
                            <div className="w-fit">
                                <CustomTextInput
                                    value={item.offTime}
                                    onChangeText={(text: string) => {
                                        const sanitizedText = sanitizeMinutesInput(text); // Use the utility function to sanitize input
                                        setFieldValue(`involvedTechnicians.${technicianIdx}.workingDays.${idx}.offTime`, sanitizedText);
                                      }}
                                    keyboardType="numeric"
                                    maxLength={4}
                                    placeholder="Tempo ausente (min.)"
                                    editable={editable}
                                />
                            </div>
                        </div>
                    </InputFieldWithLabel>
                    <InputFieldWithLabel
                        label='Distância'
                        editable={editable}
                        direction='row'
                    >
                        <div className="flex flex-row justify-end">
                            <div className="w-fit">
                                <CustomTextInput
                                    value={item.distance}
                                    onChangeText={(text: string) => {
                                        const sanitizedText = text.replace(/\D/g, '');
                                        setFieldValue(`involvedTechnicians.${technicianIdx}.workingDays.${idx}.distance`, sanitizedText)
                                    }}
                                    keyboardType="numeric"
                                    maxLength={9}
                                    placeholder="Distância (km)"
                                    editable={editable}
                                />
                            </div>
                        </div>
                    </InputFieldWithLabel>

                    <InputFieldWithLabel
                        label='Outras despesas'
                        editable={editable}
                    >
                        <div className="flex flex-row items-center justify-between">
                            <div className="w-7/12">
                                <CustomTextInput
                                    onChangeText={(text: string) =>
                                        setFieldValue(`involvedTechnicians.${technicianIdx}.workingDays.${idx}.otherExpenses.description`, text)
                                    }
                                    value={item.otherExpenses.description || ''}
                                    placeholder="Descrição"

                                />
                            </div>
                            <div className={"w-4/12"}>
                                <CustomTextInput
                                    // 
                                    onChangeText={(text: string) => {
                                        const sanitizedText = text.replace(/\D/g, '');
                                        setFieldValue(`involvedTechnicians.${technicianIdx}.workingDays.${idx}.otherExpenses.value`, sanitizedText)
                                    }

                                    }
                                    editable={editable}
                                    keyboardType="numeric"
                                    value={item.otherExpenses.value || ''}
                                    placeholder="Valor (€)"
                                />
                            </div>
                        </div>
                    </InputFieldWithLabel>
                    <div className="flex flex-row w-full justify-end mt-3">
                        {values.involvedTechnicians[technicianIdx].workingDays.length > 1 && editable ? <CustomButton
                            onClick={() => setWarningModalOpen(true)}
                        >Eliminar</CustomButton> : null}
                    </div>

                </>}
            >


            </FormArrayElement>
        </>
    )

}


export function WorkingDaysForm({
    formikProps,
    arrayHelper,
    editable,
    technicianIndex,
    validating
}: {
    formikProps: FormikProps<IMaintenance>;
    arrayHelper: any;
    editable: boolean;
    technicianIndex: number,
    validating: boolean;
}) {

    const { values } = formikProps;


    const addWorkingDay = () => {
        arrayHelper.push({
            distance: 0,
            startTime: new Date().getTime(),
            endTime: new Date().getTime(),
            date: new Date(),
            otherExpenses: '',
        });
    };

    const removeWorkingDay = (idx: number) => {
        arrayHelper.remove(idx);
    };


    return (
        <>
            <FormArrayWrapper<IMaintenance, WorkingDay>
                itemName="Dia de trabalho"
                values={values?.involvedTechnicians[technicianIndex].workingDays}
                editable={editable}
                formikProps={formikProps}
                arrayHelpers={arrayHelper}
                adicionarItem={addWorkingDay}
                sortFunction={(a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()}
                formComponent={(props) => <WorkingDayForm
                    {...props}
                    technicianIdx={technicianIndex}
                    validating={validating}
                />}

            />
        </>
    )


}
