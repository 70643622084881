import { AxiosResponse } from "axios";
import { axiosInstance } from ".";
import { ILoginResponse, IResetPasswordData, ISignupData } from "../@types";
import { getLocaleFromLanguage } from "../utils";

interface RefreshTokenResponse {
  accessToken: string;
  refreshToken: string;
}

export async function getLoggedUser() {

  const token = localStorage.getItem('maintenaid-access-token');
  return (await axiosInstance.get(`/auth/current-user`, { headers: { Authorization: `Bearer ${token}` } })).data;

}


export async function signup(
    userInfo: ISignupData
  ): Promise<{ message: string }> {
    const payload = {
      email: userInfo.email,
      password: userInfo.password,
    };
    return await axiosInstance.post(
      `/auth/signup?locale=${getLocaleFromLanguage()}`,
      payload
    );
  }

  export async function logout() {
    return await axiosInstance.post(`/auth/logout`, {});
  }

  export async function refreshTokenService(): Promise<RefreshTokenResponse | any> {
    const refreshToken = localStorage.getItem("refreshToken")
    console.log(refreshToken)
    if (!refreshToken) return
    return (await axiosInstance.post(`/auth/refresh`, {refreshToken})).data;
  }

  export async function signin(userInfo: {
    email: string;
    password: string;
    keepMeLogged?: boolean;
  }): Promise<AxiosResponse<ILoginResponse>> {

    console.log("Signing in with: ", userInfo);

    return await axiosInstance.post(
      `/auth/signin`,
      {},
      { auth: { username: userInfo.email, password: userInfo.password } }
    );
  }
  
  export async function resetPasswordRequest({ email }: { email: string }) {
    return await axiosInstance.post(
      `/auth/request-password-reset?locale=${getLocaleFromLanguage()}`,
      { email }
    );
  }

  export async function resetPassword({
    token,
    password,
    confirmPassword,
  }: IResetPasswordData) {
    return await axiosInstance.put(`/auth/password-reset`, {
      password,
      token,
      confirmPassword,
    });
  }