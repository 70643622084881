

import { useContext, useEffect, useState } from 'react';
import { FieldArray, FormikProps } from 'formik';
import { IEmployee, IMaintenance, InvolvedTechnician } from '../../../../../@types';
import { CustomDropdownPicker } from '../../../../../components/CustomDropdownPicker';
import { useQuery } from '@tanstack/react-query';
import { WorkingDaysForm } from './WorkingDaysForm';
import { useAuth } from '../../../../../contexts/AuthContext';
import { WarningModal } from '../../../../../components/WarningModal';
import { FormArrayElement } from '../../../../../components/FormArrayElement';
import { getCompanyEmployees } from '../../../../../services/team-profile.service';
import { FormSectionWrapper } from '../../../../../components/FormSectionWrapper';
import CustomButton from '../../../../../components/buttons/Button';
import { FormArrayWrapper } from '../../../../../components/FormArrayWrapper';



function InvolvedTechnicianForm({
  item,
  editable,
  idx,
  formikProps,
  technicians,
  eliminar,
  validating
}: {
  item: InvolvedTechnician;
  idx: number;
  formikProps: FormikProps<IMaintenance>,
  technicians?: IEmployee[];
  eliminar: () => void;
  editable: boolean;
  validating: boolean;
}) {
  const { loggedUser } = useAuth();
  const { values, setFieldValue } = formikProps;

  const [warningModalOpen, setWarningModalOpen] = useState(false);

  const isMainTechnician = item.technician?.id === loggedUser?.id;

  return (
    <>
      <WarningModal
        isOpen={warningModalOpen}
        close={() => setWarningModalOpen(false)}
        onConfirm={eliminar}
        mainText='Tem a certeza que pretende eliminar este dia de trabalho?'
        description='Esta acção é irreversível'
      />
      <FormArrayElement
        hideHeader
        editable={editable}
        form={
          <div className="flex justify-between">
            <div className="flex flex-row justify-between items-center mb-2">
              <div className="font-bold">Nome: </div>
              {isMainTechnician ? (
                <div> {item.technician?.name}</div>
              ) : (
                <div className="w-2/3">
                  <CustomDropdownPicker<IEmployee | null>
                    editable={editable}
                    value={values.involvedTechnicians[idx].technician}
                    setValue={(value: any) =>
                      setFieldValue(
                        `involvedTechnicians.${idx}.technician`,
                        value,
                      )
                    }
                    options={
                      technicians || []
                    }
                    labelFunction={(option: IEmployee | null) => {
                      if (!option || Object.keys(option).length === 0) return "";

                      return `${option.name} (${option.email})`;
                    }}
                  />
                </div>
              )}
            </div>
            <>
              <FieldArray
                name={`involvedTechnicians.${idx}.workingDays`}
                render={arrayHelpers => {
                  return (
                    <WorkingDaysForm
                      editable={editable}
                      formikProps={formikProps}
                      arrayHelper={arrayHelpers}
                      technicianIndex={idx}
                      validating={validating}
                    />
                  );
                }}
              />

            </>

            {isMainTechnician ? null : (
              <div className="items-end justify-end">
                <CustomButton onClick={() => setWarningModalOpen(true)}></CustomButton>
              </div>
            )}
          </div>
        }
        collapsedView={
          <div>
            <div className="flex flex-row w-full justify-between items-center my-1">
              <div className="font-bold">Nome:</div>
              <div>{item.technician?.name}</div>
            </div>
            <FieldArray
              name={`involvedTechnicians.${idx}.workingDays`}
              render={arrayHelpers => {
                return (
                  <WorkingDaysForm
                    editable={editable}
                    formikProps={formikProps}
                    arrayHelper={arrayHelpers}
                    technicianIndex={idx}
                    validating={validating}
                  />
                );
              }}
            />

          </div>
        }
        idx={idx}
        name="Técnico">
        {/* <div className="w-full h-0 border-0.5 border-gray-400 mt-2"></div> */}
      </FormArrayElement>
    </>

  );
}

export function TechnicianManagementForm({
  formikProps,
  arrayHelper,
  editable,
  validating
}: {
  formikProps: FormikProps<IMaintenance>;
  arrayHelper: any;
  editable: boolean;
  validating: boolean;
}) {
  const { loggedUser } = useAuth();


  const { values, setFieldValue } = formikProps;
  const [availableTechnicians, setAvailableTechnicians] = useState<IEmployee[]>([]);


  const { data: employees } = useQuery({
    queryKey: ['technicians', values.involvedTechnicians],
    queryFn: getCompanyEmployees,

  });

  useEffect(() => {
    const ft = employees?.filter(
      tech =>
        !values.involvedTechnicians
          .map(it => it.technician?.id)
          .includes(tech.id),
    );
    setAvailableTechnicians(ft || []);
  }, [employees])

  const addTechnician = () => {
    arrayHelper.push({
      technician: {},
      order: values.involvedTechnicians.length + 1,
      workingDays: values.involvedTechnicians[0].workingDays,
    } as InvolvedTechnician);
  };

  const removeTechnician = (idx: number) => {
    arrayHelper.remove(idx);
  };

  return (
    <FormSectionWrapper label="Gestão de tempo, recursos e deslocações em serviço">
      <div className="p-2">
        <FormArrayWrapper<IMaintenance, InvolvedTechnician>
          itemName="Técnico"
          values={values.involvedTechnicians}
          editable={editable}
          formikProps={formikProps}
          arrayHelpers={arrayHelper}
          adicionarItem={addTechnician}
          disableAddButton={!availableTechnicians?.length}
          sortFunction={(a, b) => a.order - b.order}
          formComponent={(props: {
            item: InvolvedTechnician;
            idx: number;
            formikProps: FormikProps<IMaintenance>,
            technicians?: IEmployee[];
            eliminar: () => void;
            editable: boolean;
          }) => (
            <InvolvedTechnicianForm
              {...props}
              technicians={availableTechnicians}
              validating={validating}
            />
          )}
        />

      </div>
    </FormSectionWrapper>
  );
}
