import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup"; // Importar Yup para validação
import {
  ButtonSize,
  ButtonVariant,
} from "../../../../../components/buttons/types";
import CustomButton from "../../../../../components/buttons/Button";
import { ToolActionType } from "../../enums";
import { useToolActions } from "../hooks/useToolActions";

// Esquema de validação usando o Yup
const validationSchema = Yup.object({
  comment: Yup.string(),
});

interface ToolActionSectionProps {
  toolId: number;
}

const ToolActionSection: React.FC<ToolActionSectionProps> = ({ toolId }) => {
  const { performAction } = useToolActions();
  const [showActions, setShowActions] = useState(false);

  return (
    <>
      {/* Botão para alternar o modal */}
      <div className="mb-4">
        <CustomButton
          type="button"
          className="mt-2"
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.SMALL}
          onClick={() => setShowActions(!showActions)}
        >
          {"Mostrar Ações"}
        </CustomButton>
      </div>

      {/* Modal */}
      {showActions && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg min-w-5xl max-w-6xl w-full">
            {/* Botão de Fechar */}

            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-bold">Ações da Ferramenta</h3>
              <button
                className="text-gray-500 hover:text-gray-700"
                onClick={() => setShowActions(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            {/* Formulário de Ações de Ferramenta */}

            <Formik
              initialValues={{ comment: "" }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                console.log("Valores submetidos", values);
              }}
            >
              {({ values, handleChange, handleBlur }) => (
                <Form className="flex flex-col space-y-4">
                  <div>
                    <label className="block text-sm font-medium">
                      Comentário
                    </label>
                    <Field
                      type="text"
                      name="comment"
                      className="mt-2 p-2 border w-full rounded"
                      placeholder="Comentário opcional"
                    />
                    <ErrorMessage
                      name="comment"
                      component="div"
                      className="text-red-600 text-sm mt-1"
                    />
                  </div>

                  {/* Botões de Ação */}

                  <div className="flex space-x-2">
                    <CustomButton
                      type="button"
                      className="mt-2"
                      variant={ButtonVariant.PRIMARY}
                      size={ButtonSize.SMALL}
                      onClick={() => {
                        performAction.mutate({
                          toolId,
                          comment: values.comment,
                          action: ToolActionType.WITHDRAW,
                        });
                        setShowActions(false);
                      }}
                    >
                      Levantar Ferramenta
                    </CustomButton>

                    <CustomButton
                      type="button"
                      className="mt-2"
                      variant={ButtonVariant.PRIMARY}
                      size={ButtonSize.SMALL}
                      onClick={() => {
                        performAction.mutate({
                          toolId,
                          comment: values.comment,
                          action: ToolActionType.DEPOSIT,
                        });
                        setShowActions(false);
                      }}
                    >
                      Depositar Ferramenta
                    </CustomButton>

                    <CustomButton
                      type="button"
                      className="mt-2"
                      variant={ButtonVariant.PRIMARY}
                      size={ButtonSize.SMALL}
                      onClick={() => {
                        performAction.mutate({
                          toolId,
                          comment: values.comment,
                          action: ToolActionType.RESERVATION,
                        });
                        setShowActions(false);
                      }}
                    >
                      Reservar Ferramenta
                    </CustomButton>

                    <CustomButton
                      type="button"
                      className="mt-2"
                      variant={ButtonVariant.PRIMARY}
                      size={ButtonSize.SMALL}
                      onClick={() => {
                        performAction.mutate({
                          toolId,

                          comment: values.comment,
                          action: ToolActionType.RESERVATION_FULFILLED,
                        });
                        setShowActions(false);
                      }}
                    >
                      Cumprir Reserva
                    </CustomButton>

                    <CustomButton
                      type="button"
                      className="mt-2"
                      variant={ButtonVariant.PRIMARY}
                      size={ButtonSize.SMALL}
                      onClick={() => {
                        performAction.mutate({
                          toolId,
                          comment: values.comment,
                          action: ToolActionType.RETURN_PENDING,
                        });
                        setShowActions(false);
                      }}
                    >
                      Devolução Pendente
                    </CustomButton>

                    <CustomButton
                      type="button"
                      className="mt-2"
                      variant={ButtonVariant.PRIMARY}
                      size={ButtonSize.SMALL}
                      onClick={() => {
                        performAction.mutate({
                          toolId,
                          comment: values.comment,
                          action: ToolActionType.RETURN_CONFIRMED,
                        });
                        setShowActions(false);
                      }}
                    >
                      Devolução Confirmada
                    </CustomButton>

                    <CustomButton
                      type="button"
                      className="mt-2"
                      variant={ButtonVariant.PRIMARY}
                      size={ButtonSize.SMALL}
                      onClick={() => {
                        performAction.mutate({
                          toolId,
                          comment: values.comment,
                          action: ToolActionType.DISPOSE,
                        });
                        setShowActions(false);
                      }}
                    >
                      Eliminar
                    </CustomButton>

                    <CustomButton
                      type="button"
                      className="mt-2"
                      variant={ButtonVariant.PRIMARY}
                      size={ButtonSize.SMALL}
                      onClick={() => {
                        performAction.mutate({
                          toolId,
                          comment: values.comment,
                          action: ToolActionType.TRANSFER,
                        });
                        setShowActions(false);
                      }}
                    >
                      Transferir
                    </CustomButton>

                    <CustomButton
                      type="button"
                      className="mt-2"
                      variant={ButtonVariant.PRIMARY}
                      size={ButtonSize.SMALL}
                      onClick={() => {
                        performAction.mutate({
                          toolId,
                          comment: values.comment,
                          action: ToolActionType.MAINTENANCE,
                        });
                        setShowActions(false);
                      }}
                    >
                      Manutenção
                    </CustomButton>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </>
  );
};

export default ToolActionSection;
