import { Modal } from "@mui/material";
import { useContext } from "react";
import { MaintenanceListContext } from "../../../../contexts/MaintenanceListContext";
import { ModalWrapper } from "../../../../components/ModalWrapper";
import { ScheduleObraForm } from "./Form";
import { ReactComponent as CloseIcon } from "../../../../assets/close.svg";



export function ScheduleObra() {

    const {
        schedulingNewObra,
        setSchedulingNewObra,
    } = useContext(MaintenanceListContext);


    return (
        <ModalWrapper
            open={schedulingNewObra}
            onClose={() => setSchedulingNewObra(false)}
        >
            <div className="p-5">
                <div className="flex justify-between items-center pb-3 mb-3 ">
                    <div className="font-bold text-xl text-secondary">Agendamento de obra</div>
                    <CloseIcon className="cursor-pointer" onClick={() => setSchedulingNewObra(false)} />
                    
                </div>

                <ScheduleObraForm onClose={() => setSchedulingNewObra(false)} />

            </div>
        </ModalWrapper>

    )

}