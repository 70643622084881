import { ReactElement } from "react";
import { PrivatePageWrapper } from "../PrivatePageWrapper";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


export function EntityWrapper({ title, parentRoute, children }: { title: string, parentRoute: string, children: ReactElement }) {

    const navigate = useNavigate();


    return (
        <>
            <div className="font-bold cursor-pointer flex items-center" onClick={() => navigate(`/${parentRoute}`)}>
                <ArrowBackIcon />
                <div className="ml-1">Voltar</div>
            </div>
            <PrivatePageWrapper title={title}>
                {children}
            </PrivatePageWrapper>

        </>

    )




}