import { Field, Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup"; // For form validation
import { useTranslation } from "react-i18next";
import CustomButton from "../../../components/buttons/Button";
import { ButtonSize, ButtonVariant } from "../../../components/buttons/types";
import { StepContactsProps } from "../../../@types";

// List of countries with codes
const countryOptions = [
  { name: "Portugal", code: "+351", format: "9 digits" },
  { name: "United States", code: "+1", format: "10 digits" },
  { name: "United Kingdom", code: "+44", format: "10 digits" },
  // Add more countries here if needed
];

// Validation schema

export default function StepExtras({ onNext, onSkip }: any) {
  const { t } = useTranslation("onboarding");

  return (
    <Formik
      initialValues={{
        seriesPrefix: "",
        nextNumber: "",
      }}
      onSubmit={(values) => {
        onNext({
          ...values,
        });
      }}
    >
      {({ values, handleChange, setFieldValue, errors, touched, isValid }) => {
        return (
          <div className="flex w-full mt-20">
            <div className="flex flex-col">
              <h1 className="text-xl font-bold leading-7 mb-1">Extras</h1>
              <h2 className="text-base mt-2">
                {" "}
                Relativamente à numeração das futuras folhas de obra, é
                importante para nós saber se pretende utilizar um prefixo e qual
                o próximo número de folha de obra. Exemplos: (Prefixo):(Número)
                Nº: BP: 350 (adicionar imagem exemplo)
              </h2>

              <Form className="w-full mt-4">
                <div>
                  <label className="text-base mb-1 block">Prefixo série</label>
                  <Field
                    type="text"
                    placeholder="Prefixo série"
                    className={`border focus:ring-default focus:border-default rounded-lg px-4 py-3.5 text-base w-full border-gray-300 ${
                      touched.seriesPrefix && errors.seriesPrefix
                        ? "border-red-800"
                        : ""
                    }`}
                    id="seriesPrefix"
                    name="seriesPrefix"
                    value={values?.seriesPrefix}
                    onChange={handleChange}
                  />
                  {touched.seriesPrefix && errors.seriesPrefix ? (
                    <div className="text-red-800 text-sm">
                      {errors.seriesPrefix}
                    </div>
                  ) : null}
                </div>

                <div>
                  <label className="text-base mb-1 block">
                    Próximo número
                  </label>
                  <Field
                    type="text"
                    placeholder="Próximo número"
                    className={`border focus:ring-default focus:border-default rounded-lg px-4 py-3.5 text-base w-full border-gray-300 ${
                      touched.nextNumber && errors.nextNumber
                        ? "border-red-800"
                        : ""
                    }`}
                    id="nextNumber"
                    name="nextNumber"
                    value={values?.nextNumber}
                    onChange={handleChange}
                    
                  />
                  {touched.nextNumber && errors.nextNumber ? (
                    <div className="text-red-800 text-sm">
                      {errors.nextNumber}
                    </div>
                  ) : null}
                </div>

                {/* Submit Button */}
                <div className="w-full flex justify-between mt-6">
                  <CustomButton
                    className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white 

                         bg-indigo-600 hover:bg-indigo-700"
                    type="submit"
                    variant={ButtonVariant.PRIMARY}
                    size={ButtonSize.MEDIUM}
                  >
                    Próximo
                  </CustomButton>
                </div>
              </Form>
            </div>
          </div>
        );
      }}
    </Formik>
  );
}
