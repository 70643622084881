import { ReactElement, useEffect, useState } from "react";


// { form: ReactElement, collapsedView: ReactElement, idx: number, name: string, editable: boolean, hideHeader?: boolean }

export function FormArrayElement({ form, collapsedView, idx, name, editable, hideHeader = false }: 
 any
) {

    const [collapsed, setCollapsed] = useState(false);


    useEffect(() => {
        setCollapsed(!editable)
    }, [editable])


    return (
        <div
            
            className='flex flex-col w-full items-start rounded-b-lg bg-white mb-3'>
            {hideHeader ?
                null :
                <div className={`p-2  flex flex-row w-full justify-between items-center rounded-t-lg bg-primary`}>
                    <div className='text-white font-bold'>{name} {idx + 1}</div>
                </div>
            }

            <div className="p-3 border-r-0.5 border-b-0.5 border-l-0.5 rounded-b-lg  border-gray-400 flex w-full">
                {collapsed ? collapsedView : form}
            </div>
        </div>
    )

}