import { ReactNode, useContext, useEffect, useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useAuth } from "../../contexts/AuthContext";
import { AccountIcon, LogoutIcon, UserPhotoPlaceholder } from "../../assets/icons";
import { useNavigate } from "react-router-dom";
import { Close } from "@mui/icons-material";
import { useOutsideAlerter } from "../../hooks/useOutsideClickAlerter";
import { ProfilePicturePlaceholder } from "../../assets/icons/ProfilePicturePlaceholder";
import { NotificationsIcon } from "../../assets/icons/NotificationsIcon";

const TopbarIconWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <div className="cursor-pointer mr-3 rounded-md p-1 hover:bg-blue-main  hover:shadow-md">
      {children}
    </div>
  );
};
function useDebounce(value: string, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);


  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}



export function MenuSearch() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const ref = useRef<HTMLDivElement>(null);

  useOutsideAlerter(ref, () => setMenuOpen(false));



  const handleClearSearch = () => {
    setSearchTerm("");
    setSearchResults([]);
  };

  return (
    <div className="flex flex-col items-center w-full">
      <div className="relative w-full">
        <div className="flex w-full rounded-lg bg-grey-background pl-10 p-2">
          <input
            placeholder="Search menu"
            className="bg-grey-background border-none placeholder-gray-600 w-full"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button
            onClick={handleClearSearch}
            className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-600"
            style={{ outline: 'none' }}>
            <Close />
          </button>
          <div className="absolute left-2 top-1.5">
            <SearchIcon />
          </div>
        </div>
        {searchResults.length > 0 ? (
          <div className="absolute w-full mt-1 bg-white bg-opacity-80 shadow-lg max-h-60 overflow-auto rounded-lg z-10">
            {searchResults.map((entity, index) => (
              <div key={index} className="p-4 border-b last:border-b-0">
                <h4 className="text-lg font-semibold">{entity.entity}</h4>
                <ul>
                  {entity.results.map((result: any, idx: number) => (
                    <li key={idx} className="text-sm text-gray-600">ID: {result.id}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        ) : searchTerm !== "" && (
          <div className="absolute w-full mt-1 bg-white bg-opacity-80 shadow-lg p-4 rounded-lg z-10 text-gray-600">
            Sem resultados
          </div>
        )}
      </div>
    </div>
  );


}



export function Topbar() {
  const { loggedUser, setUser, logoutUser } = useAuth();
  const navigate = useNavigate();

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div className="flex w-full justify-end items-center p-10 py-2 text-sm bg-Grey-light z-10">
      {/* <div className="flex items-center w-1/2 justify-around ml-16">
        <MenuSearch />
      </div> */}

      <div className="flex items-center">
        <div>
          <TopbarIconWrapper>
            <NotificationsIcon color="black"/>
            
          </TopbarIconWrapper>
        </div>


        <div
          className={`flex w-40 items-center p-2 rounded-lg hover:bg-gray-100 relative cursor-pointer`}
          onMouseEnter={() => setMenuOpen(true)}
          onMouseLeave={() => setMenuOpen(false)}
        >
          {/* <ProfilePicturePlaceholder /> */}
          {/* <AccountIcon/> */}
          <div className="ml-2">
            <div className="font-bold">{loggedUser?.name}</div>
            {/* <div className="text-gray-400">{loggedUser?.role}</div> */}
          </div>
          {menuOpen ?
            <div className="absolute flex flex-col w-full left-0 top-9 border rounded-lg overflow-hidden z-50">
              <div className="bg-white flex flex-col w-full p-2">
                <div className="flex items-center p-2" onClick={() => navigate('/perfil')}>
                  <AccountIcon />
                  <div className="ml-2">Perfil</div>
                </div>
                <hr />
                <div className="flex items-center p-2" onClick={() => logoutUser()}>
                  <LogoutIcon />
                  <div className="ml-2">Sair</div>
                </div>
              </div>
            </div>
            : null}
        </div>

      </div>
    </div>
  );
}
