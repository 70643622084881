
import { useEffect, useState } from "react";


export type BaseInputType = { id: number } | string | null;

export function DropdownButton({ isOpen, toggle }: { isOpen: boolean, toggle: (isOpen: boolean) => void }) {


    return (
        <div
            onClick={() => toggle(!isOpen)}
            className={`${!isOpen ? '' : 'rotate-180'
                } transition-all duration-300 flex flex-1 w-full p-1`}>
            {/* <MaterialIcons
                name="keyboard-arrow-down"
                size={30}
                color="grey"
            /> */}
        </div>
    )

}


function Option<T extends BaseInputType>({
    setValue,
    item,
    label,
    close,
    isLastOption
}: {
    setValue: (value: T) => void,
    label: string,
    item: T,
    close: () => void,
    isLastOption: boolean
}) {


    return (
        <div
            className={`w-full rounded-xl ${!isLastOption ? 'border-b-0.5' : ''}`}>
            <div
                onClick={() => {
                    setValue(item);
                    close()
                }}
            >
                <div className="p-4 py-6 " >{label}</div>
            </div>
        </div>
    )

}

function useFilteredOptions<T>({ options, searchTerm, labelFunction }: { options: T[], searchTerm: string, labelFunction?: (v: T) => string }) {

    const [filteredOptions, setFilteredOptions] = useState<T[]>([]);

    useEffect(() => {
        setFilteredOptions(searchTerm?.length
            ? options.filter(option => {

                if (!labelFunction && typeof option === "string") return option?.toLowerCase().includes(searchTerm.toLowerCase());
                else if (labelFunction && option) return labelFunction(option).toLowerCase().includes(searchTerm.toLowerCase());
                return

            })
            : options)

    }, [searchTerm])


    return {
        filteredOptions
    }

}


export function DropdownOptions<T extends BaseInputType>({
    isOpen,
    setValue,
    options,
    close,
    labelFunction,
    searcheable,
    title
}: {
    isOpen: boolean,
    setValue: (value: T) => void,
    options: T[],
    close: () => void,
    labelFunction?: (option: T) => string,
    searcheable?: boolean
    title?: string;
}) {

    // const { dark, colors } = useCustomTheme();

    const [searchTerm, setSearchTerm] = useState('');

    const { filteredOptions } = useFilteredOptions({
        options,
        searchTerm,
        labelFunction
    })


    return (
        null
        // <ModalWrapper
        //     title={title || ""}
        //     isOpen={isOpen}
        //     close={close}
        // >
        //     <div
        //         style={{
        //             backgroundColor: colors.background,
        //             elevation: 4
        //         }}
        //         className="flex w-full max-h-[80%] p-3 rounded-lg">
        //         <div className="flex flex-row justify-between items-center mb-2">
        //             <div className="font-bold ">{title}</div>
        //             <div onPress={(e) => {
        //                 close()
        //             }}>
        //                 <MaterialIcons name="close" size={30} />
        //             </div>
        //         </div>
        //         <>
        //             {searcheable ?
        //                 <TextInput
        //                     style={{
        //                         elevation: 4
        //                     }}
        //                     className={`w-full my-2 px-3 bg-white ${dark ? 'text-white' : 'text-black'} rounded-full`}
        //                     value={searchTerm}
        //                     placeholder={searchTerm || 'Pesquise aqui'}
        //                     placeholderTextColor={dark ? '#ccc' : '#666'}
        //                     onChangeText={(text: string) => {
        //                         setSearchTerm(text);
        //                     }}
        //                 />
        //                 : null}
        //         </>


        //         <FlatList
        //             data={filteredOptions}
        //             extraData={searchTerm}
        //             keyExtractor={(item, idx) => typeof item === "string" ? item : item?.id?.toString() || ""} 
        //             className="rounded-md border border-gray-300"
        //             renderItem={({ item, index }) => <Option
        //                 setValue={setValue}
        //                 item={item}
        //                 label={(() => {
        //                     if (labelFunction) {
        //                         return labelFunction(item);
        //                     } else if (typeof item === "string") {
        //                         return item;
        //                     } else {
        //                         return item?.toString() || ""
        //                     }
        //                 })()}
        //                 close={close}
        //                 isLastOption={index === filteredOptions?.length - 1}
        //             />}
        //         />

        //     </div>
        // </ModalWrapper>
    )

}