import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  ArrowRight,
  CollapseIcon,
  Overview,
} from "../../assets/icons";
import { TeamIcon } from "../../assets/icons/TeamIcon";
import FactoryIcon from "@mui/icons-material/Factory";
import { ObrasIcon } from "../../assets/icons/ObrasIcon";
import InventoryIcon from "@mui/icons-material/Inventory";
import HandymanIcon from '@mui/icons-material/Handyman';
import SearchIcon from '@mui/icons-material/Search';

import { LogoIcon } from "../../assets/icons/LogoIcon";
import { LogoText } from "../../assets/icons/LogoText";
const RouteSelector = ({
  isActive,
  Icon,
  onClick,
  text,
  isCollapsed,
}: {
  isActive: boolean;
  Icon: ({ color, size }: { color?: string; size?: number }) => JSX.Element;
  onClick: () => void;
  text: string;
  isCollapsed: boolean;
}) => {
  return (
    <div
      onClick={onClick}
      className={`flex w-full cursor-pointer my-3 ${!isCollapsed ? "justify-between" : "justify-center"
        } items-center p-3 rounded-lg ${isActive ? "bg-primary shadow-md text-white" : ""
        }`}
    >
      <div className="flex items-center">
        <div>
          <Icon size={30} color={isActive ? "white" : "black"} />
        </div>
        {!isCollapsed ? <span className="ml-3">{text}</span> : null}
      </div>
      {!isCollapsed ? (
        <div>
          <ArrowRight color={isActive ? "white" : "black"} />
        </div>
      ) : null}
    </div>
  );
};

export function SideBar() {
  const navigate = useNavigate();
  const location = useLocation();

  const currentPath = location.pathname;

  const [isCollapsed, setIsCollapsed] = useState(false);

  console.log("Current path: ", currentPath);

  return (
    <div
      className={`flex ${isCollapsed ? "w-[5%]" : "w-[15%]"
        } px-3 pb-8 flex-col h-full bg-grey-background justify-between transition-all duration-300 bg-Grey-light`}
    >
      <div className="flex flex-col h-full w-full  items-center">
        <div className="mt-1">
          {isCollapsed ? <div className="p-2"><LogoIcon size={36}/></div> : <div className="flex items-center p-2">
            <div className="mr-2">
            <LogoIcon size={36}/>
            </div>
            <LogoText size={140}/>
          </div>}
        </div>

        <div className="w-full mt-4 text-sm">
          {/* <span className="text-gray-400 ">Menu</span> */}
          <div className="mt-2">
            <RouteSelector
              onClick={() => navigate("/")}
              isActive={currentPath === "/"}
              Icon={Overview}
              text={"Dashboard"}
              isCollapsed={isCollapsed}
            />
            <RouteSelector
              onClick={() => navigate("/obras")}
              isActive={currentPath.includes("/obras")}
              Icon={ObrasIcon}
              text={"Obras"}
              isCollapsed={isCollapsed}
            />
            <RouteSelector
              onClick={() => navigate("/colaboradores")}
              isActive={currentPath.includes("/colaboradores")}
              Icon={TeamIcon}
              text={"Colaboradores"}
              isCollapsed={isCollapsed}
            />
            <RouteSelector
              onClick={() => navigate("/clientes")}
              isActive={currentPath.includes("/clientes")}
              Icon={() => (
                <div>
                  <FactoryIcon />
                </div>
              )}
              text={"Clientes"}
              isCollapsed={isCollapsed}
            />
            <RouteSelector
              onClick={() => navigate("/stock")}
              isActive={currentPath.includes("/stock")}
              Icon={() => (
                <div>
                  <InventoryIcon />
                </div>
              )}
              text={"Stock"}
              isCollapsed={isCollapsed}
            />
            <RouteSelector
              onClick={() => navigate("/tool")}
              isActive={currentPath.includes("/tool")}
              Icon={() => (
                <div>
                  <HandymanIcon />
                </div>
              )}
              text={"Ferramentas"}
              isCollapsed={isCollapsed}
            />
             <RouteSelector
              onClick={() => navigate("/search")}
              isActive={currentPath.includes("/search")}
              Icon={() => (
                <div>
                  <SearchIcon />
                </div>
              )}
              text={"Search"}
              isCollapsed={isCollapsed}
            />
          </div>
        </div>
      </div>
      <div
        onClick={() => setIsCollapsed(!isCollapsed)}
        className={`flex w-full h-fit pt-1 cursor-pointer justify-end`}
      >
        <div
          className={`${isCollapsed ? "rotate-180" : ""
            } transition-all duration-300 cursor-pointer`}
        >
          <CollapseIcon size={15} color="black" />
        </div>
      </div>
    </div>
  );
}
