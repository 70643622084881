import { axiosInstance } from ".";
import { IUser } from "../@types";

export async function getSignedUser(): Promise<IUser> {
  const token = localStorage.getItem("accessToken");
  const response = await axiosInstance.get(`/users/signed-in`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data?.user;
}

export async function updateLocale(data: { locale: "pt" | "en" }) {
  return (await axiosInstance.patch(`/users/locale`, data)).data;
}
