import axios from "axios";
import { refreshTokenService } from "./auth.service";

// Axios CancelToken to cancel requests if needed
let cancelTokenSource = axios.CancelToken.source();

// Create a custom axios instance
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Add a request interceptor to cancel in-flight requests if needed
axiosInstance.interceptors.request.use((config) => {
  if (cancelTokenSource) {
    config.cancelToken = cancelTokenSource.token;
  }
  return config;
});

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    // Check if the request was canceled
    if (axios.isCancel(error)) {
      console.log("Request canceled", error.message);
      return Promise.reject(error);
    }

    // Check if the error is a 401 Unauthorized error
    if (error.response && error.response.status === 401) {
      // Check if the request is a retry attempt
      if (error.config.__isRetryRequest) {
        return Promise.reject(error); // Avoid infinite retry loop
      }

      // Mark the request as a retry attempt
      error.config.__isRetryRequest = true;

      try {
        // Call the refresh token service with the refresh token
        const newToken = await refreshTokenService();

        // Save the new tokens to local storage
        localStorage.setItem("accessToken", newToken.accessToken);
        localStorage.setItem("refreshToken", newToken.refreshToken);

        // Set the new token in the axios instance headers
        axiosInstance.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${newToken.accessToken}`;

        // Update the Authorization header of the original request
        error.config.headers[
          "Authorization"
        ] = `Bearer ${newToken.accessToken}`;

        // Retry the original request with the new token
        return axiosInstance(error.config);
      } catch (refreshError) {
        return Promise.reject(refreshError); // Handle token refresh errors
      }
    }

    return Promise.reject(error); // Reject any other errors
  }
);

export default axiosInstance;
