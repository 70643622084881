import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useContext, useState } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { MyAreaTitle } from "./components/MyAreaTitle";
import { updateUserDetails } from "../../../services/user-details";
import { CustomFieldWithError } from "../../../components/forms/CustomFieldWithError";
import CustomButton from "../../../components/buttons/Button";
import { ButtonSize, ButtonVariant } from "../../../components/buttons/types";
import { useMutation } from "@tanstack/react-query";

export function PersonalInformation() {
  const { user, setUser } = useContext(AuthContext);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const { t } = useTranslation("myArea");

  const updateUserDetailsMutation = useMutation({
    mutationFn: updateUserDetails,
    onSuccess: (response) => {
      setUser(response);
    },
    onError: (error: any) => {
      console.log("Error: ", error);
    },
  });

  return (
    <div className="mb-5">
      <div className="hidden lg:flex">
        <MyAreaTitle title={t("profile.title")} />
      </div>

      <div className="flex flex-col lg:flex-row w-full">
        <div className="w-full lg:w-3/4 lg:px-6">
          <Formik
            initialValues={{
              companyName: user?.userDetails?.companyName || "",
              email: user?.email || "",
            }}
            validationSchema={yup.object({
              username: yup
                .string()
                .required("Username is required")
                .matches(/^[\w.-]+$/, `${t("no-space-username-warning")}`),
              // ... other validation schemas ...
            })}
            onSubmit={(values) => {
              const userDetails = {
                companyName: values.companyName || null,
              };
              updateUserDetailsMutation.mutate(
                {
                  userDetails,
                },
                {
                  onSuccess: (response) => {
                    setUser(response);
                    setIsFormDirty(false); // Reset the form's dirty state
                  },
                  onError: (error) => {
                    console.log("Error: ", error);
                  },
                }
              );
            }}
          >
            {({ errors, touched }) => {
              return (
                <Form
                  className="mt-4 md:grid md:grid-cols-2 md:gap-3 lg:grid-cols-none"
                  onChange={() => setIsFormDirty(true)}
                >
                  <div className="w-full lg:w-full">
                    <label htmlFor="companyName">
                      {t("profile.companyName")}
                    </label>
                    <div>
                      <CustomFieldWithError
                        className="w-full h-10 mt-1 border-black rounded-lg"
                        type="companyName"
                        id="companyName"
                        name="companyName"
                        error={errors.companyName || null}
                        touched={!!touched.companyName}
                      />
                    </div>
                  </div>

                  <div className="w-full mt-4 md:mt-0 lg:mt-4 lg:w-full">
                    <label htmlFor="email">{t("profile.email")}</label>
                    <div>
                      <CustomFieldWithError
                        disabled={true}
                        className="w-full h-10 mt-1 border-black rounded-lg"
                        type="email"
                        id="email"
                        name="email"
                        error={errors.email || null}
                        touched={!!touched.email}
                      />
                    </div>
                  </div>

                  <CustomButton
                    type="submit"
                    className={`mt-6 w-full ${
                      isFormDirty ? "" : "bg-gray-200"
                    }`}
                    variant={ButtonVariant.PRIMARY}
                    size={ButtonSize.MEDIUM}
                    disabled={!isFormDirty}
                  >
                    {t("profile.save")}
                  </CustomButton>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
}
