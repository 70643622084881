import { Form, Formik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { signup } from "../../services/auth.service";
import { NavigationBar } from "../../components/auth/NavigationBar";
import { CustomFieldWithError } from "../../components/forms/CustomFieldWithError";
import { PasswordField } from "../../components/forms/PasswordField";
import CustomButton from "../../components/buttons/Button";
import { ButtonSize, ButtonVariant } from "../../components/buttons/types";
import { SIGNUP_ERRORS } from "../../errors/errorCodes";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useMutation } from "@tanstack/react-query";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

export default function Signup({
  goToSignIn,
  setRegisteredUser,
  goToVerificationEmailSent,
}: {
  goToSignIn: () => void;
  goToVerificationEmailSent: () => void;
  setRegisteredUser: (user: any) => void;
}) {
  const [emailRegisteredError, setEmailRegisteredError] =
    useState<boolean>(false);
  const [passwordNotStrongEnoughError, setPasswordNotStrongEnoughError] =
    useState<boolean>(false);

  const { t } = useTranslation("authentication");

  const emailErrorMessage = emailRegisteredError
    ? "Email já se encontra registado."
    : null;
  const passwordNotStrongEnoughMessage = passwordNotStrongEnoughError
    ? "Palavra-passe não é suficientemente forte."
    : null;

  const signupMutation = useMutation({
    mutationFn: signup,
    onSuccess: () => {
      goToVerificationEmailSent();
    },
    onError: (error: any) => {
      if (error.response.data.message === SIGNUP_ERRORS.EMAIL_ALREADY_REGISTERED) {

        setEmailRegisteredError(true);
      }
      if (error.response.data.message === SIGNUP_ERRORS.PASSWORD_NOT_STRONG_ENOUGH) {
        setPasswordNotStrongEnoughError(true);
      }
      console.log(emailRegisteredError, passwordNotStrongEnoughError); // Should log true when errors occur

    },
  });


  return (
    <div className="flex flex-col  px-4 sm:px-6 lg:px-8">
      <main className="flex-1 bg-cover bg-center">
        <div className="flex justify-start items-start ">
          <div className="w-full max-w-md bg-white shadow-lg rounded-lg p-8">
            <NavigationBar goToLogin={goToSignIn} activeTab="Registar"/>
            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                setEmailRegisteredError(false);
                setPasswordNotStrongEnoughError(false);
                setRegisteredUser(values);
                signupMutation.mutate(values);
              }}
            >
              {({ errors, touched, isValid, values }) => {
                const noFieldsEmpty =
                  values.email.length > 0 && values.password.length > 0;
                const disabled = !isValid || !noFieldsEmpty;

                return (
                  <Form className="space-y-6">
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email*
                      </label>
                      <CustomFieldWithError
                        className="mt-1 w-full px-2 border rounded h-10 shadow-sm focus:ring-default focus:border-default"
                        type="email"
                        id="email"
                        name="email"
                        error={errors.email || emailErrorMessage}
                        touched={!!touched.email}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-gray-700"
                      >
                         Password personalizada*
                      </label>
                      <PasswordField
                        error={
                          errors.password || passwordNotStrongEnoughMessage
                        }
                        touched={!!touched.password}
                      />
                    </div>

                    <CustomButton
                      type="submit"
                      className={`w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                        disabled
                          ? "bg-gray-400"
                          : "bg-indigo-600 hover:bg-indigo-700"
                      }`}
                      disabled={disabled}
                      variant={ButtonVariant.PRIMARY}
                      size={ButtonSize.MEDIUM}
                    >
                      Registar
                    </CustomButton>
                  </Form>
                );
              }}
            </Formik>

            <div className="mt-4 text-sm text-center flex justify-center items-center space-x-2">
              <span>Já sou membro: </span>
              <button
                className="flex items-center text-default hover:text-600 transition-transform hover:translate-x-1"
                onClick={goToSignIn}
              >
                Iniciar sessão <ArrowForwardIcon className="ml-1" />
              </button>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
