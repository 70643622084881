import { FormikProps } from "formik";

import { IMaintenance } from "../../../../@types";
import { FormSectionWrapper } from "../../../../components/FormSectionWrapper";
import { InputFieldWithLabel } from "../../../../components/FieldWithLabel";
import { CustomTextInput } from "../../../../components/CustomTextInput";



export function MachineForm({ formikProps, editable }: { formikProps: FormikProps<IMaintenance>, editable: boolean }) {

    const { values, handleBlur, setFieldValue } = formikProps;

    return (
        <FormSectionWrapper label="Máquina">
            <InputFieldWithLabel
                editable={editable}
                label='Nome '
                direction="row"
            >
                <CustomTextInput
                    onChangeText={(text: string) => setFieldValue('content.machine.name', text)}
                    onBlur={() => handleBlur('content.machine.name')}
                    value={values.content?.machine?.name}
                    placeholder="Nome da máquina"
                   
                    editable={editable}
                />
            </InputFieldWithLabel >
            <InputFieldWithLabel
                editable={editable}
                label='Modelo'
                direction="row"
            >
                <CustomTextInput
                    onChangeText={(text: string) => setFieldValue('content.machine.model', text)}
                    onBlur={() => handleBlur('content.machine.model')}
                    value={values.content?.machine?.model}
                    placeholder="Modelo da máquina"
                   
                    editable={editable}
                />
            </InputFieldWithLabel >
            <InputFieldWithLabel
                label='Nº Série'
                direction="row"
                editable={editable}
                hideSeparator={true}
            >
                <CustomTextInput
                    onChangeText={(text: string) => setFieldValue('content.machine.serialNumber', text)}
                    onBlur={() => handleBlur('content.machine.serialNumber')}
                    value={values.content?.machine?.serialNumber}
                    placeholder="Nº série"
                   
                    editable={editable}
                />
            </InputFieldWithLabel >
        </FormSectionWrapper>
    )


}