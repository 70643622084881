import { useEffect, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { IClient } from "../../../../../@types";
import { getAddressFromCoordinates, getCoordinatesFromCP } from "../../../../../services/3rdParty.services";
import { getLocation } from "../../../../../services/gps/getLocation";




export function useClientLocationMap({ values, setFieldValue, idx }: { values: IClient, setFieldValue: any, idx: number }) {


    const [loadingCoords, setLoadingCoords] = useState(false);

    const [coords, setCoords] = useState<{ latitude: number, longitude: number } | null>(values?.locations[idx]?.coordinates || null);

    const mapRef = useRef<any>(null);

    const cp4 = values?.locations[idx]?.cp4;
    const cp3 = values?.locations[idx]?.cp3;

    const locationNotSavedNorManuallySetNorFetchedByDevice = !values?.locations[idx]?.id && !values?.locations[idx]?.coordinatesForcefullySet && !values?.locations[idx]?.fetchedByDeviceLocation;

    const {data} = useQuery({
        queryKey: ['clientLocation', cp4, cp3],
        queryFn: () => getCoordinatesFromCP(values?.locations[idx]?.cp4, values?.locations[idx]?.cp3),
        enabled: !!values?.locations[idx]?.cp4 && !!values?.locations[idx]?.cp3 && locationNotSavedNorManuallySetNorFetchedByDevice,
     
    });

    useEffect(()=>{
        setCoords({
            latitude: data?.centroide[0],
            longitude: data?.centroide[1]
        })
        mapRef.current?.animateCamera({
            center: {
                latitude: data?.centroide[0],
                longitude: data?.centroide[1],
            },
        })
    },[data])



    useEffect(() => {
        if (coords) {
            setLoadingCoords(false);
            mapRef.current?.animateCamera({
                center: coords,
            })
            setFieldValue(`locations.${idx}.coordinates`, coords)
            if (values?.locations[idx]?.fetchedByDeviceLocation && !values?.locations[idx]?.coordinatesForcefullySet) {
                getAddressFromCoordinates(coords.latitude, coords.longitude)
                    .then((data: any) => {
                        setFieldValue(`locations.${idx}.address`, data?.rua)
                        const cp = data?.CP.split('-');
                        setFieldValue(`locations.${idx}.cp4`, cp[0])
                        setFieldValue(`locations.${idx}.cp3`, cp[1])
                    })
            }
        }
    }, [coords?.latitude, coords?.longitude, values?.locations[idx]?.fetchedByDeviceLocation])


    const displayMapContainer = values?.locations[idx]?.coordinates || loadingCoords;

    const handleMarkerDrag = (e: any) => {
        setCoords(e.nativeEvent.coordinate);
        setFieldValue(`locations.${idx}.coordinatesForcefullySet`, true);
    }

    const automaticCoordsFetch = () => {
        setLoadingCoords(true);
        getLocation(setCoords);
        setFieldValue(`locations.${idx}.coordinatesForcefullySet`, true);
    }

    const resetLocationAddress = () => {
        setCoords(null);
        setFieldValue(`locations.${idx}.address`, '')
        setFieldValue(`locations.${idx}.cp4`, '')
        setFieldValue(`locations.${idx}.cp3`, '')
    }

    useEffect(() => {
        if (values?.locations[idx]?.fetchedByDeviceLocation && !values?.locations[idx]?.coordinatesForcefullySet) {
            automaticCoordsFetch();
        }
    }, [values?.locations[idx]?.fetchedByDeviceLocation])


    return {

        handleMarkerDrag,
        loadingCoords,
        coords,
        mapRef,
        displayMapContainer
    };


}