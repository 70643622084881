import axiosInstance from "..";
import { IMaintenance } from "../../@types";



export async function scheduleObra(scheduling: IMaintenance & { scheduledDateTime: Date, schedulingStatus: string, timezone: string }) {
    return await axiosInstance.post('/obras-scheduling', scheduling)
}

export async function getSchedulingForObra(obraId: number) {
    return (await axiosInstance.get(`/obras-scheduling/obra/${obraId}`)).data;
}