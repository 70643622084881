import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { StepLogoProps } from "../../../@types";
import CustomButton from "../../../components/buttons/Button";
import { ButtonSize, ButtonVariant } from "../../../components/buttons/types";
import { useState } from "react";

export default function StepLogo({ onNext, onSkip }: StepLogoProps) {
  const { t } = useTranslation("onboarding");
  const [logoPreview, setLogoPreview] = useState<string | null>(null);

  return (
    <Formik
      initialValues={{
        logo: "",
      }}
      onSubmit={(values) => {
        onNext(values);
      }}
    >
      {({ values, setFieldValue, isValid }) => {

        const noFieldsEmpty = values.logo
        const disabled = !isValid || !noFieldsEmpty;

        const handleLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          const file = e.target.files?.[0];
          if (file) {
            setFieldValue("logo", file);
            const reader = new FileReader();
            reader.onloadend = () => {
              setLogoPreview(reader.result as string);
            };
            reader.readAsDataURL(file);
          }
        };

        return (
          <div className="flex w-full mt-20">
            <div className="flex flex-col">
              <h1 className="text-xl font-bold leading-7 mb-1">
                {t("stepLogo.title")}
              </h1>
              <h2 className="text-base mt-2">{t("stepLogo.subtitle")}</h2>
              <Form className="w-full mt-4">
                <div className="flex flex-col w-full items-center">
                  {/* Logo Picker Section */}
                  <input
                    type="file"
                    accept="image/*"
                    id="logo"
                    name="logo"
                    onChange={handleLogoChange}
                    className="hidden "
                  />
                  <label
                    htmlFor="logo"
                    className="cursor-pointer border-2 border-dashed border-gray-300 rounded-lg p-4 text-center w-full sm:w-64 focus:ring-default focus:border-default"
                  >
                    {logoPreview ? (
                      <img
                        src={logoPreview}
                        alt="Logo Preview"
                        className="object-contain h-24 mx-auto"
                      />
                    ) : (
                      <span>{t("stepLogo.uploadPlaceholder")}</span>
                    )}
                  </label>
                  {logoPreview && (
                    <button
                      type="button"
                      className="text-sm text-red-800 mt-2"
                      onClick={() => {
                        setLogoPreview(null);
                        setFieldValue("logo", "");
                      }}
                    >
                      {t("stepLogo.remove")}
                    </button>
                  )}
                </div>
                <div className="w-full flex justify-between mt-6">
                  <CustomButton
                     className={`w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                      disabled
                        ? "bg-gray-400"
                        : "bg-indigo-600 hover:bg-indigo-700"
                    }`}
                    disabled={disabled}
                    type="submit"
                    variant={ButtonVariant.PRIMARY}
                    size={ButtonSize.MEDIUM}
                  >
                    {t("stepLogo.next")}
                  </CustomButton>
                </div>
              </Form>
            </div>
          </div>
        );
      }}
    </Formik>
  );
}
