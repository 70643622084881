import { useTranslation } from "react-i18next";
import { AuthContext } from "../../contexts/AuthContext";
import { useContext } from "react";

export default function TermsOfService() {
    const { t } = useTranslation("termsOfService");
    const { user } = useContext(AuthContext);;

    return (
        <div className="w-full h-full flex justify-center px-6 sm:px-9 md:px-0 mt-5 md:mt-9">
            <div className={`w-full ${user && "md:w-1/2" }`}>
                <h1 className="text-default text-lg font-semibold mb-4">{t("page-title")}</h1>
                <div>Amazing Terms</div>
            </div>
        </div>
    )
}