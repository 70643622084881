import { useContext } from "react"
import { MaintenanceStatus, Role } from "../../../../../enums";
import { IEmployee, IMaintenance } from "../../../../../@types";
import { useAuth } from "../../../../../contexts/AuthContext";
import { FormSectionWrapper } from "../../../../../components/FormSectionWrapper";
import CustomButton from "../../../../../components/buttons/Button";



export function BillingInfo({ maintenance, openBilling }: { maintenance: IMaintenance, openBilling: () => void }) {

    const { loggedUser } = useAuth();

    const canBill = (loggedUser as IEmployee).canBill;

    return (
        <FormSectionWrapper label="Faturação">
            <>
                {maintenance.status === MaintenanceStatus.SUBMITTED ?
                    <div className="p-3">
                        <div className='flex flex-row items-center mb-5'>

                            <div>Warning</div>
                            <div className='ml-2 font-bold'>Esta obra não se encontra faturada</div>
                        </div>
                        {canBill ?
                            <CustomButton
                                onClick={() => {
                                    openBilling()
                                }}

                            >Faturar</CustomButton> : null}

                    </div>

                    : null}
                {
                    maintenance.status === MaintenanceStatus.BILLED ?
                        <div className='flex p-2'>
                            <div><div className="font-bold">Número de faturação:</div> {maintenance.billingNumber}</div>
                            <div className="mt-2"><div className="font-bold">Valor da faturação:</div> {maintenance.billingValue} €</div>
                        </div>
                        : null
                }
                {
                    maintenance.status === MaintenanceStatus.CREATED || !maintenance.status ?
                        <div className='flex p-2'>
                            <div>Submeta a folha de obra para permitir a faturação da mesma</div>
                        </div> : null
                }
            </>
        </FormSectionWrapper>
    )

}