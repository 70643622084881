import React from "react";
import { Link } from "react-router-dom";
import DetailsList from "./DetailsList";
import { statusLabels } from "../Search";

const RenderObras: React.FC<{ obras: any[] }> = ({ obras }) => (
  <div className="grid grid-cols-1 gap-4">
    {obras.length > 0 ? (
      obras.map((obra) => (
        <Link
          key={obra.obra.id}
          to={`/obras/${obra.obra.id}`}
          className="block p-4 border border-gray-300 rounded-lg shadow-md bg-white hover:bg-gray-100 transition"
        >
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-bold">Obra ID: {obra.obra.id}</h2>
            <span className={`px-2 py-1 text-xs font-semibold rounded ${obra.obra.status === "Active" ? "bg-green-100 text-green-600" : "bg-yellow-100 text-yellow-600"}`}>
              {statusLabels[obra.obra.status] || obra.obra.status}
            </span>
          </div>
          <p className="text-gray-700">Billing Number: {obra.obra.billingNumber}</p>
          {obra.matches.length > 0 && <DetailsList matches={obra.matches} />}
        </Link>
      ))
    ) : (
      <p className="text-gray-500 text-center">No Obras found.</p>
    )}
  </div>
);

export default RenderObras;
