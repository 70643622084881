import { ReactNode } from "react";

export default function InfoPage({ title, message, button }: { title: string, message: string, button?: ReactNode }) {


    return (
        <div className="mt-8 w-full h-full px-6">
            <div className="flex flex-col mb-6">
                <h1 className="mb-5 text-lg font-bold">{title}</h1>
                <h3>{message}</h3>
            </div>

            {button}
        </div>
    )

}