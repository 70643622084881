import axiosInstance from ".";
import { IEmployee } from "../@types";
import { CreateProfileDto } from "../@types/profileTypes";

export const fetchProfileRawData = async () => {
    const { data } = await axiosInstance.get('/profiles/raw');
    return data;
  };
  
  export const createProfile = async (profileData: CreateProfileDto) => {
    const { data } = await axiosInstance.post('/profiles/create', profileData);
    return data;
  };

  // export const fetchAllProfiles = async () => {
  //   const response = await axiosInstance.get(`/profiles`);
  //   return response.data; 
  // };

  export async function getEmployee(employeeId: number) : Promise<IEmployee> {
    return (await axiosInstance.get<IEmployee>(`employee/${employeeId}`)).data;
  }

  export async function getCompanyEmployees(): Promise<IEmployee[]> {
    return (await axiosInstance.get<IEmployee[]>('employee')).data;
}