import { FormikProps } from "formik"

import { IMaintenance } from "../../../../../@types";
import { FormSectionWrapper } from "../../../../../components/FormSectionWrapper";
import { InputFieldWithLabel } from "../../../../../components/FieldWithLabel";
import { CustomTextInput } from "../../../../../components/CustomTextInput";



export function ClientValidationForm({ formikProps, editable, openSignatureScreen }: { formikProps: FormikProps<IMaintenance>, editable: boolean, openSignatureScreen: () => void }) {

    const { values, setFieldValue, handleBlur } = formikProps;


    return (
        <FormSectionWrapper label="Validação Cliente">
            <>
                <InputFieldWithLabel editable={editable} label="Observações">
                    <CustomTextInput
                        editable={editable}
                        multiline={true}
                        numberOfLines={4}

                        onChangeText={(text: string) => setFieldValue('content.clientInput.observations', text)}
                        onBlur={() => handleBlur('content.clientInput.observations')}
                        value={values.content.clientInput.observations || ''}
                        placeholder="Introduza aqui as suas observações relativamente ao serviço"

                    />
                </InputFieldWithLabel>

                <InputFieldWithLabel editable={editable} label="Assinatura">
                    <div
                        onClick={() => {
                            editable ?
                                openSignatureScreen() :
                                console.log("Not eidtable");
                        }}
                        className="flex flex-col w-full h-40 rounded-md justify-center items-center p-3 border border-dashed border-black"
                        >
                        {values.content.clientInput.clientSignature ? <img
                            style={{
                                padding: 10,
                                width: '100%',
                                height: 150,
                                backgroundColor: 'white',
                                marginTop: 5,
                                marginBottom: 5,
                                // resizeMode: 'contain',

                            }} src={values.content.clientInput.clientSignature} /> :

                            <div>Clique para assinar a folha de obra</div>
                        }
                    </div>
                </InputFieldWithLabel>
                {values.content.clientInput.clientSignature && editable ?
                    <div className='my-2 flex flex-row justify-end'>
                        <div
                           
                            className="mt-3 w-10 h-10 rounded-full  flex flex-row items-center justify-center bg-primary shadow-md" onClick={() => openSignatureScreen()} >
                            
                            <div>Edit</div>
                        </div>
                    </div>
                    : null}
            </>

        </FormSectionWrapper>
    )


}