import { useState } from "react";
import CustomButton from "../../../components/buttons/Button";
import { ButtonSize, ButtonVariant } from "../../../components/buttons/types";
import { useToolList } from "./hooks/useToolList";
import { CreateTool } from "./types";
import ToolList from "./components/ToolList";
import AddToolModal from "./components/AddToolModal";
import { PrivatePageWrapper } from "../../../components/PrivatePageWrapper";

export default function ToolPage() {
  const { createToolMutation } = useToolList();
  const [isModalOpen, setModalOpen] = useState(false);

  const handleAddTool = async (values: CreateTool, files: File[]) => {
    await createToolMutation.mutate({ data: values, files });
    setModalOpen(false);
  };

  return (
    <PrivatePageWrapper title="Gestão de Ferramentas" headerButtons={<CustomButton
      variant={ButtonVariant.SECONDARY}
      size={ButtonSize.SMALL}
      onClick={() => setModalOpen(true)}
    >
      Registar Ferramenta
    </CustomButton>}>
      <ToolList />
      <AddToolModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        onSubmit={handleAddTool}
      />
    </PrivatePageWrapper>
  );
}
