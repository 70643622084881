/* React imports */
import { ReactElement, createContext, useContext, useState } from "react";

/* Local imports */

import { goToWindow } from "../utils";
import { getLoggedUser, logout } from "../services/auth.service";
import { useMutation } from "@tanstack/react-query";
import { IUser } from "../@types";

export const AuthContext = createContext<{ user: IUser | null; setUser: any }>({
  user: null,
  setUser: null,
});

export const useAuth = () => {
  const { user, setUser } = useContext(AuthContext);

  const logoutMutation = useMutation({
    mutationFn: logout,
    onSuccess: () => {
      const refreshToken = localStorage.getItem("maintenaid-refresh-token");
      setUser(null);
      goToWindow("/");
      if (refreshToken) localStorage.removeItem("maintenaid-refresh-token");
      localStorage.removeItem("maintenaid-access-token");
    },
    onError: (error : Error) => {
      console.log("error", error.message);
    },
  });

  const logoutUser = () => {
    logoutMutation.mutate();
  };

  const softLogoutUser = () => {
    const refreshToken = localStorage.getItem("maintenaid-refresh-token");
    setUser(null);
    goToWindow("/");
    if (refreshToken) localStorage.removeItem("maintenaid-refresh-token");
    localStorage.removeItem("maintenaid-access-token");
  };

  return {
    loggedUser: user,
    setUser,
    logoutUser,
    softLogoutUser,
  };
};


export function AuthContextProvider({ children }: { children: ReactElement }) {

  const [user, setUser] = useState<IUser | null>(null);

  const token: string | null = localStorage.getItem('maintenaid-access-token');
  console.log("NO user...")
  console.log("Token; ", token);
  if (token && !user) {
    
    getLoggedUser()
      .then(setUser)
  }


  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  )

}