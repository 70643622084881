


export function EditIcon({ size = 26, color = "white" }: { size?: number, color?: string }) {

    return (
        <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.3747 4.87487L20.9102 8.4104M1.75 24.2496L1.80931 23.8343C2.0192 22.3652 2.12415 21.6306 2.36286 20.9447C2.57469 20.3361 2.86405 19.7573 3.22382 19.2228C3.62926 18.6203 4.15399 18.0956 5.20343 17.0462L19.7634 2.48614C20.7397 1.50983 22.3226 1.50983 23.299 2.48614C24.2753 3.46245 24.2753 5.04537 23.299 6.02168L8.4718 20.8488C7.51974 21.8009 7.04371 22.2769 6.5015 22.6554C6.02022 22.9914 5.50116 23.2698 4.95498 23.4848C4.33964 23.7271 3.67969 23.8602 2.35991 24.1264L1.75 24.2496Z"
                stroke={color}
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>

    )
}