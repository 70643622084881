import { useMemo, useState } from 'react';

import { BaseInputType, DropdownButton, DropdownOptions } from './components';


export function CustomDropdownPicker<T extends BaseInputType>({
  value,
  setValue,
  options,
  placeholder = 'Seleccione',
  title,
  searcheable = true,
  editable,
  labelFunction
}: {
  value: T;
  setValue: (value: T | null) => void;
  options: T[];
  placeholder?: string;
  title?: string;
  searcheable?: boolean;
  editable: boolean;
  labelFunction?: (option: T) => string
}) {

  // const { dark } = useCustomTheme();
  const [open, setOpen] = useState(false);


  const  valueToDisplay = useMemo(() => {
    let vToD;
    if (labelFunction) {
      if (value) {
        vToD = labelFunction(value)
      } else {
        vToD = undefined;
      }
    } else if (typeof value === "string") {
      vToD = value;
    }

    return vToD;
  }, [value]);


  return (
    <>
      {editable ? (
        <div
          className={`flex flex-row w-full rounded-full bg-white`}
          style={{
            position: 'relative',
            zIndex: 900,
          }}>
          <div
            onClick={() => setOpen(!open)}
            className="flex flex-row w-full justify-between px-2 pl-4 h-10 items-center">
            <div>
              {valueToDisplay || placeholder}
            </div>
            <DropdownButton isOpen={open} toggle={setOpen} />
          </div>
          <DropdownOptions
            isOpen={open}
            setValue={setValue}
            options={options}
            close={() => setOpen(false)}
            labelFunction={labelFunction}
            searcheable={searcheable}
            title={title}
          />
        </div>
      ) : (
        <div>{valueToDisplay}</div>
      )}
    </>
  );
}
