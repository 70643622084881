import { FormikProps } from "formik";
import { ReactElement, useState } from "react";


export interface FormInputFullProps<FormType> extends FormInputProps<FormType> { };

export interface FormInputProps<FormType> {
    idx: number,
    item: any,
    formikProps: FormikProps<FormType>,
    eliminar: () => void,
    editable: boolean;
    validating?: boolean;
}

export function FormArrayWrapper<FormType, ItemType>({
    values,
    editable,
    formComponent,
    formikProps,
    arrayHelpers,
    adicionarItem,
    disableAddButton,
    itemName,
    sortFunction
}: {
    values: ItemType[],
    editable: boolean,
    formikProps: FormikProps<FormType>,
    arrayHelpers: any,
    adicionarItem: () => void,
    formComponent: (props: FormInputFullProps<FormType>) => ReactElement<FormInputFullProps<FormType>>,
    disableAddButton?: boolean,
    itemName: string,
    sortFunction?: (a: ItemType, b: ItemType) => number
}) {


    const [itemToDisplay, setItemToDisplay] = useState<number>(0);

    const eliminarItem = (idx: number) => {
        arrayHelpers.remove(idx)
    }

    const sortedValues = sortFunction ? values?.sort(sortFunction) : values;


    return (
        <>
            <div  >
                <div className='flex flex-row items-center'>
                    {sortedValues?.length > 0 ? sortedValues?.map((component: ItemType, idx: number) => {

                        const isActive = itemToDisplay === idx
                        return (
                            <div key={idx} onClick={() => setItemToDisplay(idx)}>
                                <div
                                    
                                    className={`p-2 px-3 rounded-t-lg border-0.5 ${isActive ? 'border-b-0 bg-white' : 'bg-primary'} border-gray-400`}>
                                    <div className={`font-bold text-sm ${(isActive) ? 'text-gray-600' : 'text-white'}`}>{itemName} {idx + 1}</div>
                                </div>
                            </div>
                        )

                    }) : null}
                    {editable && !disableAddButton ?
                        <div onClick={() => {
                            adicionarItem()
                            setItemToDisplay(values.length)
                        }}>
                            <div className='flex flex-row justify-center p-1 px-3 w-14 rounded-t-lg bg-primary'>
                                <div className={`text-xl font-bold text-white`}>+</div>
                            </div>
                        </div>
                        : null}

                </div>

            </div>
            <div>
                {sortedValues?.length > 0 ? sortedValues?.map((component: ItemType, idx: number) => (
                    <div key={idx}>
                        {itemToDisplay === idx ?
                            <>{formComponent({
                                idx,
                                item: component,
                                formikProps,
                                eliminar: () => {
                                    if (idx === sortedValues.length - 1) {
                                        setItemToDisplay(idx - 1)
                                    }
                                    eliminarItem(idx);
                                },
                                editable
                            })}</>
                            : null}
                    </div>
                )) : <div>
                    <div>Nenhum item registado</div>
                </div>}
            </div>
        </>
    )


}