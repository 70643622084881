export enum StockActionType {
  INBOUND = "inbound", // Stock is added to the inventory (e.g., from supplier)
  OUTBOUND = "outbound", // Stock is removed (e.g., sold or consumed)
  RETURN_PENDING = "return_pending", // Stock is returned but needs confirmation
  RETURN_CONFIRMED = "return_confirmed", // Stock is returned and confirmed
  //ADJUSTMENT = "ADJUSTMENT", // Manual adjustment or correction of stock
  RESERVATION_PENDING = "reservation_pending", // Stock reserved but not shipped
  RESERVATION_FULFILLED = "reservation_fulfilled", // Reserved stock shipped
}

export const stockActionTypeMap: { [key in StockActionType]: string } = {
  [StockActionType.INBOUND]: "Entrada", // Stock adicionado ao inventário
  [StockActionType.OUTBOUND]: "Saída", // Stock removido
  [StockActionType.RETURN_PENDING]: "Devolução Pendente", // Stock devolvido mas precisa de confirmação
  [StockActionType.RETURN_CONFIRMED]: "Devolução Confirmada", // Stock devolvido e confirmado
  [StockActionType.RESERVATION_PENDING]: "Reserva Pendente", // Stock reservado mas não enviado
  [StockActionType.RESERVATION_FULFILLED]: "Reserva Concluída", // Stock reservado enviado
};
