import { useEffect } from "react";
import InfoPage from "./InfoPage";
import { useTranslation } from "react-i18next";
import { softLogin } from "./utils/SoftLogin";
import CustomButton from "../../components/buttons/Button";


export function EmailVerified() {

    const {t} = useTranslation("authentication")

    useEffect(() => {
        setTimeout(() => {
            softLogin()
        }, 5000)
    }, [])


    return (
        <InfoPage
            title={t("email-verification.successfull-title")}
            message={t("email-verification.successfull-message")}
            button={
                <CustomButton className="bg-default w-full p-2 mt-3 "  onClick={() => softLogin()}>
                    {t("email-verification.button")}
                </CustomButton>
            }
        />
    )

}