import { useEffect, useState } from "react";
import { ISignupData } from "../../@types";
import { useTranslation } from "react-i18next";
import Signup from "./Signup";
import { goToWindow } from "../../utils";
import { PublicNavbar } from "../../components/navbar/PublicNavbar";
import { PublicFooter } from "../../components/footer/PublicFooter";
import InfoPage from "./InfoPage";
import Signin from "./Signin";
import ResetPasswordRequest from "./ResetPasswordRequest";
import PasswordReset from "./PasswordReset";
import { EmailVerified } from "./EmailVerified";
import { EmailVerificationSent } from "./EmailVerificationSent";
import About from "./About";
import TermsOfService from "./TermsOfService";
import PrivacyPolicy from "./PrivacyPolicy";
import CookiePolicy from "./CookiePolicy";
import Support from "./Support";
import Imprint from "./Imprint";
import backgroundImage from "../../assets/AuthBackground.jpeg";
import { LogoIconFull } from "../../assets/icons/LogoIconFull";


export enum PUBLIC_PAGES {
  SIGNIN = "signin",
  SIGNUP = "signup",
  VERIFICATION_SUCCESSFULL = "verification-successful",
  VERIFICATION_EMAIL_SENT = "verification-email-sent",
  RESET_PASSWORD_EMAIL_SENT = "reset-password-email-sent",
  PASSWORD_RESET_REQUEST = "password-reset-requested",
  PASSWORD_RESET = "password-reset",
  TERMS_OF_SERVICE = "terms-of-service",
  PRIVACY_POLICY = "privacy-policy",
  ABOUT = "about",
  SUPPORT = "support",
  IMPRINT = "imprint",
  COOKIE_POLICY = "cookie-policy",
}

export const getPageLocation = (page: PUBLIC_PAGES | null) => {
  const bootstarppedURL = window.location.href.split("//")[1];

  const location = bootstarppedURL.split("/")[1];

  if (
    location.includes(PUBLIC_PAGES.VERIFICATION_SUCCESSFULL) &&
    page == null
  ) {
    return PUBLIC_PAGES.VERIFICATION_SUCCESSFULL;
  } else if (location.includes(PUBLIC_PAGES.SIGNUP) && page == null) {
    return PUBLIC_PAGES.SIGNUP;
  } else if (location.includes(PUBLIC_PAGES.PASSWORD_RESET) && page == null) {
    return PUBLIC_PAGES.PASSWORD_RESET;
  } else if (location.includes(PUBLIC_PAGES.TERMS_OF_SERVICE) && page == null) {
    return PUBLIC_PAGES.TERMS_OF_SERVICE;
  } else if (location.includes(PUBLIC_PAGES.PRIVACY_POLICY) && page == null) {
    return PUBLIC_PAGES.PRIVACY_POLICY;
  } else if (location.includes(PUBLIC_PAGES.COOKIE_POLICY) && page == null) {
    return PUBLIC_PAGES.COOKIE_POLICY;
  } else if (location.includes(PUBLIC_PAGES.ABOUT) && page == null) {
    return PUBLIC_PAGES.ABOUT;
  } else if (location.includes(PUBLIC_PAGES.SUPPORT) && page == null) {
    return PUBLIC_PAGES.SUPPORT;
  } else if (location.includes(PUBLIC_PAGES.IMPRINT) && page == null) {
    return PUBLIC_PAGES.IMPRINT;
  } else if (!page) {
    return PUBLIC_PAGES.SIGNIN;
  }

  return null;
};

export default function Public() {
  const [page, setPage] = useState<PUBLIC_PAGES | null>(null);
  const [registeredUser, setRegisteredUser] = useState<ISignupData | null>(
    null
  );
  const { t } = useTranslation("authentication");

  useEffect(() => {
    const newLocation = getPageLocation(page);
    if (newLocation && newLocation !== page) {
      setPage(newLocation);
    }
  }, [page]);

  const renderPageByState = () => {
    if (page === PUBLIC_PAGES.SIGNIN)
      return (
        <Signin
          goToSignup={() => setPage(PUBLIC_PAGES.SIGNUP)}
          goToResetPassword={() => setPage(PUBLIC_PAGES.PASSWORD_RESET_REQUEST)}
        />
      );
    if (page === PUBLIC_PAGES.SIGNUP)
      return (
        <Signup
          setRegisteredUser={setRegisteredUser}
          goToSignIn={() => {
            setPage(PUBLIC_PAGES.SIGNIN);
            goToWindow("/");
          }}
          goToVerificationEmailSent={() =>
            setPage(PUBLIC_PAGES.VERIFICATION_EMAIL_SENT)
          }
        />
      );
    if (page === PUBLIC_PAGES.PASSWORD_RESET_REQUEST)
      return (
        <ResetPasswordRequest
          goBack={() => setPage(PUBLIC_PAGES.SIGNIN)}
          onPasswordResetSuccessfulRequest={() =>
            setPage(PUBLIC_PAGES.RESET_PASSWORD_EMAIL_SENT)
          }
        />
      );
    if (page === PUBLIC_PAGES.PASSWORD_RESET)
      return (
        <PasswordReset
          onSuccessfulReset={() => {
            goToWindow("/");
            setPage(PUBLIC_PAGES.SIGNIN);
          }}
        />
      );

    if (page === PUBLIC_PAGES.VERIFICATION_SUCCESSFULL)
      return <EmailVerified />;
    if (page === PUBLIC_PAGES.VERIFICATION_EMAIL_SENT)
      return <EmailVerificationSent registeredUser={registeredUser} />;

    if (page === PUBLIC_PAGES.RESET_PASSWORD_EMAIL_SENT)
      return (
        <InfoPage
          title={t("password-reset.email-sent-title")}
          message={t("password-reset.email-sent-message")}
        />
      );

    if (page === PUBLIC_PAGES.ABOUT) return <About />;
    if (page === PUBLIC_PAGES.TERMS_OF_SERVICE) return <TermsOfService />;
    if (page === PUBLIC_PAGES.PRIVACY_POLICY) return <PrivacyPolicy />;
    if (page === PUBLIC_PAGES.COOKIE_POLICY) return <CookiePolicy />;
    if (page === PUBLIC_PAGES.SUPPORT) return <Support />;
    if (page === PUBLIC_PAGES.IMPRINT) return <Imprint />;
  };

  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
      className="flex w-full justify-around">

      <div className="w-1/2 flex items-center justify-center">
        <LogoIconFull size={300}/>
      </div>
      <div className="w-1/2 h-full flex items-center justify-center">

        {renderPageByState()}

      </div>
    </div>
  );




}
