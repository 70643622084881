import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { getEmployee } from "../../../../services/team-profile.service";
import { EmployeeForm } from "./Form";
import { EntityWrapper } from "../../../../components/EntityWrapper";


export function Employee() {

    const location = useLocation();
    const employeeId = parseInt(location.pathname.split('/').pop() || '0');

    const { data: employee, isLoading } = useQuery({
        enabled: !!employeeId,
        queryKey: ['employee', employeeId],
        queryFn: () => getEmployee(employeeId)
    })

    if (isLoading) return null;

    return (
        <EntityWrapper
            title={employee?.name || ""}
            parentRoute="colaboradores"
            >

            <EmployeeForm employee={employee} />
        </EntityWrapper>
    )

}