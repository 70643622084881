
import { IMaintenance } from "../../../../../@types";
import { Album } from "./Album";
import { useContext, useEffect } from "react";
import { PhotoPreview } from "./PhotoPreview";
import { PhotosContextProvider } from "../../../../../contexts/PhotosContext";
import { Dialog } from "@mui/material";


export function Photos({ isOpen, close, maintenance, refetchMaintenance }: { isOpen: boolean, close: () => void, maintenance: IMaintenance, refetchMaintenance: () => void }) {


    return (
        <PhotosContextProvider maintenance={maintenance} refetchMaintenance={refetchMaintenance}>
            <PhotosContent isOpen={isOpen} close={close} maintenance={maintenance} refetchMaintenance={refetchMaintenance} />
        </PhotosContextProvider>
    )

}



export function PhotosContent({ isOpen, close, maintenance, refetchMaintenance }: { isOpen: boolean, close: () => void, maintenance: IMaintenance, refetchMaintenance: () => void }) {





    const ModalHeader = () => {
        return (
            <div
                className="h-10 w-full flex flex-row p-2 rounded-t-md items-center justify-between bg-primary">
                <div className="text-white font-bold">Fotografias</div>
                <div onClick={close}>close</div>
            </div>
        )
    }


    return (

        <>

            <PhotoPreview />
            <Dialog
                
                open={isOpen}>
                <div
                    
                    className=" m-2 h-[98%] opacity-100 z-50 rounded-md border border-gray-400 border-0.5 bg-white">
                    <ModalHeader />

                    <div className="flex flex-1 p-3 justify-between ">

                        <div className="h-4/5">
                            <Album maintenance={maintenance} />
                        </div>
                    </div>

                </div>
            </Dialog>
        </>

    )

}

