import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../contexts/AuthContext";
import CustomButton from "../../components/buttons/Button";
import { ButtonSize, ButtonVariant } from "../../components/buttons/types";

export default function Support() {
  const { t } = useTranslation("support");
  const { user } = useContext(AuthContext);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = async () => {
    try {
      // const data = {
      //   firstName,
      //   lastName,
      //   email,
      //   message,
      // };
      // if(user) {
      //     await contactSupport(data)
      //     setSuccessMessage(t('success-message') || '')
      // } else {
      //     await contactSupportGuest(data)
      //     setSuccessMessage(t('success-message') || '')
      // }
    } catch (error) {
      console.log(error);
      setSuccessMessage(t("error-message") || "");
    }
  };

  return (
    <div className="w-full h-full flex justify-center px-6 sm:px-9 md:px-0 mt-5 md:mt-9">
      <div className={`w-full ${user && "md:w-1/2"}`}>
        <h1 className="text-default text-lg font-semibold mb-4">
          {t("page-title")}
        </h1>

        {successMessage ? (
          <p className="font-medium mb-1">{successMessage}</p>
        ) : (
          <>
            <p className="text-lg text-Grey-dark font-bold mb-3">
              {t("contact")}
            </p>

            <div className="mb-6">
              <p className="font-medium mb-1">{t("first-name")}</p>
              <input
                required
                className="border border-black rounded-lg w-full py-3 px-4"
                value={firstName}
                onChange={(e) => setFirstName(e.target?.value)}
              />
            </div>
            <div className="mb-6">
              <p className="font-medium mb-1 ">{t("last-name")}</p>
              <input
                className="border border-black rounded-lg w-full py-3 px-4"
                value={lastName}
                onChange={(e) => setLastName(e.target?.value)}
              />
            </div>
            <div className="mb-6">
              <p className="font-medium mb-1 ">{t("email")}</p>
              <input
                className="border border-black rounded-lg w-full py-3 px-4"
                value={email}
                onChange={(e) => setEmail(e.target?.value)}
              />
            </div>
            <div className="mb-6">
              <p className="font-medium mb-1 ">{t("message")}</p>
              <textarea
                className="w-full px-4 py-3 border border-black rounded-lg h-44 overflow-auto"
                value={message}
                onChange={(e) => setMessage(e?.target?.value)}
              />
            </div>

            <CustomButton
              type="submit"
              className="w-full mb-24"
              variant={ButtonVariant.PRIMARY}
              size={ButtonSize.MEDIUM}
              onClick={handleSubmit}
            >
              {t("save")}
            </CustomButton>
          </>
        )}
      </div>
    </div>
  );
}
