import {
    Dispatch,
    ReactElement,
    SetStateAction,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
  } from 'react';
import { MaintenanceListContext } from '../../../../contexts/MaintenanceListContext';
import { useAuth } from '../../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { MaintenanceListItem } from './MaintenanceListItem';

  
  
  
  
  
  export function MaintenanceList() {
  
    // const { downloading, downloadMaintenanceReport } = useMaintenanceDownloader();
  
    const { maintenances, page, isLoading } = useContext(MaintenanceListContext);
  
    const { loggedUser } = useAuth();
  
  
    const navigate = useNavigate();
  
    return (
      <div className="flex flex-1 ">
        <>
        
  
  
  
          {isLoading ? (
            <div className="flex flex-col flex-1 w-full items-center justify-center">
              <div>
                Loading...
              </div>
            </div>
          ) : (
     
              <div className="flex flex-col w-full">
                {maintenances?.map(item => (
                  <MaintenanceListItem key={item.id} maintenance={item} downloadMaintenanceReport={()=>null} />
                ))}
              </div>
  
        
          )}
  
        </>
      </div>
    );
  }
  
  
  