import { useState } from "react";
import StockList from "./components/StockList";
import AddStockModal from "./components/AddStockModal";
import { useStockList } from "./hooks/useStockList";
import { CreateStock } from "./types";
import CustomButton from "../../../components/buttons/Button";
import { ButtonSize, ButtonVariant } from "../../../components/buttons/types";
import { PrivatePageWrapper } from "../../../components/PrivatePageWrapper";

export default function StockPage() {
  const { createStockMutation } = useStockList();
  const [isModalOpen, setModalOpen] = useState(false);

  const handleAddStock = async (values: CreateStock, files: File[]) => {
    await createStockMutation.mutate({ data: values, files });
    setModalOpen(false);
  };

  return (
    <PrivatePageWrapper title="Gestão de Stock" headerButtons={<CustomButton
      variant={ButtonVariant.SECONDARY}
      size={ButtonSize.SMALL}
      onClick={() => setModalOpen(true)}
    >
      Registar Stock
    </CustomButton>}>


      <StockList />

      <AddStockModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        onSubmit={handleAddStock}
      />
    </PrivatePageWrapper>
  );
}
