import { useTranslation } from "react-i18next";

type NavigationBarProps = {
    goToLogin?: () => void;
    goToSignup?: () => void;
    activeTab: "Iniciar sessão" | "Registar"; // Pass the active tab as a prop
};

export function NavigationBar({ goToLogin, goToSignup, activeTab }: NavigationBarProps) {
    const { t } = useTranslation("authentication");

    const handleLoginClick = () => {
        if (goToLogin) goToLogin(); // Trigger navigation to login
    };

    const handleSignupClick = () => {
        if (goToSignup) goToSignup(); // Trigger navigation to signup
    };

    return (
        <div className="flex flex-col w-full mb-4">
            <div className="flex w-full justify-center">
                {/* Login Section */}
                <div className="flex-1 text-center">
                    <div
                        onClick={handleLoginClick}
                        className={`p-3 cursor-pointer transition-colors duration-150 ${
                            activeTab === "Iniciar sessão" ? "text-default font-bold" : "text-gray-800"
                        }`}
                    >
                        <span>Iniciar sessão</span>
                    </div>
                    <div
                        className={`h-[2px] transition-all duration-150 ${
                            activeTab === "Iniciar sessão" ? "bg-default w-1/2 mx-auto" : "bg-Grey-tint w-full"
                        }`}
                    />
                </div>

                {/* Signup Section */}
                <div className="flex-1 text-center">
                    <div
                        onClick={handleSignupClick}
                        className={`p-3 cursor-pointer transition-colors duration-150 ${
                            activeTab === "Registar" ? "text-default font-bold" : "text-gray-800"
                        }`}
                    >
                        <span>Registar</span>
                    </div>
                    <div
                        className={`h-[2px] transition-all duration-150 ${
                            activeTab === "Registar" ? "bg-default w-1/2 mx-auto" : "bg-Grey-tint w-full"
                        }`}
                    />
                </div>
            </div>
        </div>
    );
}
