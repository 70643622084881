import { Autocomplete, Dialog, TextField } from "@mui/material"
import { IClient, IEmployee, IMaintenance } from "../../../../../@types";
import { SchedulingStatus } from ".";
import { FieldArray, FieldArrayRenderProps, useFormikContext } from "formik";
import { useState } from "react";
import CustomButton from "../../../../../components/buttons/Button";
import { ButtonVariant } from "../../../../../components/buttons/types";


export function TechnicianOptions({ employees, arrayHelper, closeDialog }: { employees: Array<IEmployee>, arrayHelper: FieldArrayRenderProps, closeDialog: () => void }) {


    const [pickedTechnician, setPickedTechnician] = useState<IEmployee | undefined>();

    return (
        <div className="flex flex-col w-96 p-5">
            <div className="flex items-center justify-between mb-3">
                <div className="font-bold ">Seleccione o técnico a adicionar</div>
                <div onClick={() => closeDialog()}>close</div>
            </div>
            <Autocomplete
                options={employees}
                getOptionLabel={(option) => option.name}
                getOptionKey={(option) => option.id}
                renderInput={(params) => <TextField {...params} label="Técnico" />}
                onChange={(e, employee) => {
                    if (!employee) return;
                    setPickedTechnician(employee)
                }}
            />

            {
                pickedTechnician ?
                    <div className="mt-4">
                        <div className="font-bold mb-1">Informações técnico seleccionado</div>
                        <div>{pickedTechnician.name}</div>
                        <div>{pickedTechnician.email}</div>
                        <div className="flex w-full justify-end">
                            <CustomButton
                                className="mt-4"
                                variant={ButtonVariant.SECONDARY}
                                onClick={() => {
                                    arrayHelper.push({ technician: pickedTechnician });
                                    setPickedTechnician(undefined);
                                    closeDialog();
                                }}>
                                <div>Confirmar</div>
                            </CustomButton>
                        </div>
                    </div>
                    : null
            }
        </div>
    )


}


export function TechinicianPicker({ employees }: { employees: Array<IEmployee> }) {

    const { values } = useFormikContext<IMaintenance & { scheduledDateTime: Date, schedulingStatus: SchedulingStatus, timezone: string, client: IClient }>();

    const [technicianSelectorOpen, setTechnicianSelectorOpen] = useState(false);

    return (
        <FieldArray
            name={`involvedTechnicians`}
            render={arrayHelpers => {
                return (
                    <>
                        <div className="font-bold mt-6 mb-4">Técnicos associados</div>
                        <div className="grid grid-cols-2 gap-3">
                            {
                                values?.involvedTechnicians.map((it, idx) => {
                                    return (
                                        <div className="flex flex-col h-40 p-4 rounded-md border border-0.5 shadow-md">
                                            <div className="font-bold mb-3">{idx === 0 ? 'Técnico Responsável' : `Técnico auxiliar ${idx}`}</div>
                                            <div>Nome: {it?.technician?.name}</div>
                                            <div>Email: {it?.technician?.email}</div>

                                        </div>

                                    )
                                })
                            }
                            <div onClick={() => setTechnicianSelectorOpen(true)} className="shadow rounded-md p-3 border border-0.5 flex flex-col items-center justify-center h-36 ">
                                <div className="text-[80px] font-bold text-gray-400 leading-none opacity-50">+</div>
                                <div>Adicionar técnico</div>
                            </div>
                        </div>
                        <Dialog open={technicianSelectorOpen} onClose={() => setTechnicianSelectorOpen(false)}>
                            <TechnicianOptions
                                employees={employees.filter((employee) => !values.involvedTechnicians.map(it => it.technician?.id).includes(employee.id))}
                                arrayHelper={arrayHelpers}
                                closeDialog={() => setTechnicianSelectorOpen(false)}
                            />
                        </Dialog>
                    </>

                );
            }} />
    )

}