import i18n from "i18next";
import { initReactI18next } from "react-i18next";

export const LANGUAGES = {
  en: "en",
  pt: "pt",
};

i18n.use(initReactI18next).init({
  lng: LANGUAGES.en,
  fallbackLng: [LANGUAGES.en],
  resources: {
    en: {
      navbar: require("./static/en/navbar.json"),
      // Pages
      authentication: require("./static/en/pages/authentication.json"),
      about: require("./static/en/pages/about.json"),
      home: require("./static/en/pages/home.json"),
      onboarding: require("./static/en/pages/onboarding.json"),
      // Root folder
      common: require("./static/en/common.json"),

    },
    de: {
      navbar: require("./static/pt/navbar.json"),
      // Pages
      authentication: require("./static/pt/pages/authentication.json"),
      about: require("./static/pt/pages/about.json"),
      home: require("./static/pt/pages/home.json"),
      onboarding: require("./static/pt/pages/onboarding.json"),
      // Root folder
      common: require("./static/pt/common.json"),
  
    },
  },
  ns: ["common"],
  defaultNS: "common",
  fallbackNS: "common",
  debug: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
