
import { ReactElement } from "react";
import { IMaintenance } from "../../../../@types";
import { MaintenanceStatus } from "../../../../enums";
import SubmittedIcon from "../../../../assets/icons/Maintenance/SubmittedIcon";
import EmailedIcon from "../../../../assets/icons/Maintenance/EmailedIcon";
import BilledIcon from "../../../../assets/icons/Maintenance/BilledIcon";
import RememberMeInIcon from "../../../../assets/icons/Maintenance/RememberMeIcon";
import { CalendarIcon } from "../../../../assets/icons/CalendarIcon";



function HistoryLine({ icon, text, hasSeparator = true }: { icon: ReactElement, text: string, hasSeparator?: boolean }) {

    return (
        <div>
            <div className="flex flex-row items-center justify-between">
                {icon}
                <div>{text}</div>

            </div>
            {hasSeparator ?
                <div className="flex flex-row w-full justify-end p-1">
                    {/* <HistorySeparatorIcon /> */}
                </div>
                : null}

        </div>

    )

}



function MaintenanceHistoryDetailed({ maintenance }: { maintenance: IMaintenance }) {



    const dateToRemember = new Date(maintenance.rememberMeAt);
    const timeLeft = dateToRemember.getTime() - new Date().getTime();

    const timeLeftInDays = Math.ceil(timeLeft / (1000 * 60 * 60 * 24));


    return (
        <div>
            <HistoryLine
                icon={
                    <SubmittedIcon
                        color={maintenance.status !== MaintenanceStatus.CREATED && maintenance.status !== MaintenanceStatus.SCHEDULED && maintenance.status !== MaintenanceStatus.STARTED ? "orange" : undefined}
                    />
                }
                text={`submetido a ${maintenance.submitedAt
                    ? new Date(maintenance.submitedAt).toLocaleString('pt')
                    : '-'}`}
            />
            <HistoryLine
                icon={
                    <EmailedIcon
                        color={maintenance.sentToClient ? "orange" : undefined}
                    />
                }
                text={`${maintenance.sentToClient
                    ? 'Enviado ao cliente'
                    : 'Por enviar'}`}
            />
            <HistoryLine
                icon={
                    <BilledIcon
                        color={maintenance.status === MaintenanceStatus.BILLED ? "orange" : undefined}
                    />
                }
                text={`${maintenance.status === MaintenanceStatus.BILLED
                    ? 'Faturado'
                    : 'Por faturar'}`}
                hasSeparator={!!maintenance.rememberMeIn}
            />

            {maintenance.rememberMeIn ?
                <HistoryLine
                    icon={
                        <RememberMeInIcon />
                    }
                    text={`A lembrar em ${timeLeftInDays} dias`}
                    hasSeparator={false}
                />
                : null}




        </div>
    )


}



export function MaintenanceHistory({ maintenance, expanded }: { maintenance: IMaintenance, expanded: boolean }) {


    console.log("Maintenance: ", maintenance.status);
    return (
        <div >
            {expanded ? <MaintenanceHistoryDetailed maintenance={maintenance} /> :
                <div className="flex flex-row w-full justify-between items-center ">

                    <div className=" flex flex-row w-1/3 justify-between">
                        {maintenance.status === MaintenanceStatus.SCHEDULED ?
                            <CalendarIcon color="yellow" />
                            : null}
                        <SubmittedIcon
                            color={maintenance.status !== MaintenanceStatus.CREATED ? "orange" : undefined}
                        />
                        <div className="mb-0">
                            <EmailedIcon
                                color={maintenance.sentToClient ? "orange" : undefined}
                            />
                        </div>
                        <BilledIcon
                            color={maintenance.status === MaintenanceStatus.BILLED ? "orange" : undefined}
                        />

                    </div>

                    {maintenance.rememberMeIn ?
                        <RememberMeInIcon />
                        : null}

                </div>
            }

        </div>
    )


}

