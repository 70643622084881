import { Dialog } from "@mui/material";
import { useContext } from "react";
import CustomButton from "../../../../../components/buttons/Button";
import { PhotosContext } from "../../../../../contexts/PhotosContext";



export function PhotoPreview() {

    const {
        previewImage,
        setPreviewImage,
        deleteExistingPhoto
    } = useContext(PhotosContext);


    return (
        <Dialog
            open={!!previewImage}
        >
            {previewImage && (
                <>
                    <div
                        style={{
                            backgroundColor: 'rgba(0, 0, 0, 0.7)'
                        }}
                        className="flex flex-col flex-1 items-center justify-center relative">
                        <img src={previewImage.uri} style={styles.modalImage} />


                        <div className="flex flex-row w-full justify-between items-center px-6">
                            <CustomButton onClick={() => deleteExistingPhoto(previewImage.id || 0)} />


                            <div className='flex flex-row rounded-md p-1.5 items-center ' onClick={() => setPreviewImage(null)}>
                                <div className='text-white font-bold ml-1'>Fechar</div>
                            </div>
                        </div>

                    </div>
                </>
            )}
        </Dialog>
    )

}


const styles = {

    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        marginVertical: 16,
    },
    scrollView: {
        flex: 1,
        marginBottom: 16,
        borderWidth: 1,
        borderColor: 'gray',
        borderRadius: 8,
        padding: 5,
    },
    thumbnailContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 8,
        borderBottomWidth: 1,
        borderBottomColor: '#ddd',
        padding: 8,
    },
    thumbnail: {
        width: 100,
        height: 100,
        borderRadius: 8,
        marginRight: 8,
        padding: 5,
    },
    deleteButton: {
        position: 'absolute',
        top: 5,
        right: 5,
        backgroundColor: 'red',
        padding: 5,
        borderRadius: 8,
    },
    deleteText: {
        color: 'white',
        fontWeight: 'bold',
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
    },
    modalCloseButton: {

        padding: 10,
        backgroundColor: 'white',
        borderRadius: 8,
    },
    closeButtonText: {
        fontSize: 16,
        fontWeight: 'bold',
        color: 'black',
    },
    modalImage: {
        width: '90%',
        height: '90%',
        resizeMode: 'contain',
    },
};
