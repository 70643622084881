import { useMutation, useQuery } from "@tanstack/react-query";
import { createContext, Dispatch, ReactElement, SetStateAction, useRef, useState } from "react";
import { IEmployee, IUser } from '../@types';
import { getCompanyEmployees } from "../services/team-profile.service";
import { registerEmployee } from "../services/employee";
import { SIGNUP_ERRORS } from "../errors/errorCodes";


export const EmployeesContext = createContext<{
   
    companyEmployees?: IEmployee[],
   
    roleChanged: boolean,
    setRoleChanged: Dispatch<SetStateAction<boolean>>,
    warningModalOpen: boolean,
    setWarningModalOpen: Dispatch<SetStateAction<boolean>>,
    updateMutation: any,
    registerMutation: any,
    passwordNotStrongEnoughError: boolean,
    emailRegisteredError: boolean,
    newEmployeeDialogOpen: boolean,
    setNewEmployeeDialogOpen: Dispatch<SetStateAction<boolean>>,
}>({
   
    companyEmployees: [],
    roleChanged: false,
    setRoleChanged: () => { },
    warningModalOpen: false,
    setWarningModalOpen: () => { },
    updateMutation: null,
    registerMutation: null,
    passwordNotStrongEnoughError: false,
    emailRegisteredError: false,
    newEmployeeDialogOpen: false,
    setNewEmployeeDialogOpen: () => { },
});



export function EmployeesContextProvider({ children }: { children: ReactElement }) {

    const [roleChanged, setRoleChanged] = useState(false);
    const [warningModalOpen, setWarningModalOpen] = useState(false);
    const screenRef = useRef<any>(null);

    const [newEmployeeDialogOpen, setNewEmployeeDialogOpen] = useState(false);


    const { data: companyEmployees, refetch: refetchCompanyEmployees } = useQuery<IEmployee[]>({
            queryKey: ['employees'],
            queryFn: getCompanyEmployees,
        });




    const [emailRegisteredError, setEmailRegisteredError] =
        useState<boolean>(false);
    const [passwordNotStrongEnoughError, setPasswordNotStrongEnoughError] =
        useState<boolean>(false);




    const registerMutation = useMutation({
        mutationKey: ['registerEmployee'],
        mutationFn: registerEmployee,
        onSuccess: () => {
            refetchCompanyEmployees();
            setNewEmployeeDialogOpen(false);
        },
        onError: (error : Error) => {
            // TODO add specific errors
            if (error.message == SIGNUP_ERRORS.EMAIL_ALREADY_REGISTERED) {
                setEmailRegisteredError(true);
            } else if (
                error.message == SIGNUP_ERRORS.PASSWORD_NOT_STRONG_ENOUGH
            ) {
                setPasswordNotStrongEnoughError(true);
            } else {
                // Toast.show({
                //     type: 'error',
                //     text1: 'Erro ao registar colaborador',
                // });
            }
        },
    });

    // const updateMutation = useMutation(updateEmployee, {
    //     onSuccess: () => {
    //         refetchCompanyEmployees();
    //         refetchSubscriptionSlots();
    //         resetStuff
    //         Toast.show({
    //             type: 'success',
    //             text1: 'Colaborador atualizado com sucesso!',
    //         });
    //     },
    //     onError: ({ response }) => {
    //         console.log(response.data.message);
    //         if (response.data.message == SIGNUP_ERRORS.EMAIL_ALREADY_REGISTERED) {
    //             setEmailRegisteredError(true);
    //         }
    //         if (response.data.message == SIGNUP_ERRORS.PASSWORD_NOT_STRONG_ENOUGH) {
    //             setPasswordNotStrongEnoughError(true);
    //         }
    //     },
    // });


        return (
            <EmployeesContext.Provider value={{
                companyEmployees,
                roleChanged,
                setRoleChanged,
                warningModalOpen,
                setWarningModalOpen,
                updateMutation: null,
                registerMutation,
                passwordNotStrongEnoughError,
                emailRegisteredError,
                newEmployeeDialogOpen,
                setNewEmployeeDialogOpen,
            }}>
                {children}
            </EmployeesContext.Provider>
        )

}