import { useContext } from "react";
import { InputFieldContext } from "../FieldWithLabel";




export function CustomTextInput(props: any & { editable?: boolean, value: string, hasError?: boolean }) {

    const { editable, hasError } = useContext(InputFieldContext);


    const isFieldEditable = props.editable || editable;

    const displayError = props.hasError || hasError;

    return (
        <>
            {!isFieldEditable ?
                <div className="mb-1">{!!props.value ? props.value : '-'}</div> :
                <input
                    className={`w-full border border-gray-300 p-2 px-4 text-black bg-white shadow-sm  ${!props.multiline ? 'rounded-xl h-11' : 'rounded-lg'}  ${displayError ? 'border border-red-500' : ''}`}
                    placeholderTextColor={"gray"}
                    textAlignVertical={props.multiline ? 'top' : 'center'}
                    {...props}
                />
            }

        </>
    )



}