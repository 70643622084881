import { FieldArray, useFormikContext } from "formik";
import { IMaintenance, Location } from "../../../../../@types";
import { useState } from "react";

import { useClientLocationMap } from "./useClientLocationMap";
import { InputFieldWithLabel } from "../../../../../components/FieldWithLabel";
import { CustomTextInput } from "../../../../../components/CustomTextInput";
import CustomButton from "../../../../../components/buttons/Button";
import { WarningModal } from "../../../../../components/WarningModal";
import { FormArrayElement } from "../../../../../components/FormArrayElement";



function CodigoPostalInput({ location, idx }: { location: Location, idx: number }) {


    const { setFieldValue, touched, errors, setErrors } = useFormikContext<IMaintenance>();

    const hasError = () => {
        const cp4ETouched = touched?.client?.locations ? touched.client.locations[idx]?.cp4 : false;
        const cp4Error = errors?.client?.locations ? (errors.client.locations[idx] as any)?.cp4 : false;
        const cp3Touched = touched?.client?.locations ? touched.client.locations[idx]?.cp3 : false;
        const cp3Error = errors?.client?.locations ? (errors.client.locations[idx] as any)?.cp3 : false;
        return cp4ETouched && cp4Error || cp3Touched && cp3Error;
    }

    const getErrors = () => {
        const cp4Error = errors?.client?.locations ? (errors.client.locations[idx] as any)?.cp4 : null;
        const cp3Error = errors?.client?.locations ? (errors.client.locations[idx] as any)?.cp3 : null;
        return cp4Error || cp3Error;
    }



    return (
        <InputFieldWithLabel
            editable={true}
            direction="row"
            label="Código Postal">
            <div>
                <div className="flex-row w-full grid-cols-6 gap-1 items-center p-2">
                    <div className="w-5/12">
                        <CustomTextInput
                            value={location.cp4}
                            onChangeText={(text: string) => {
                                const sanitizedText = text.replace(/\D/g, '');
                                const updatedErrors = errors;
                                if (updatedErrors?.client?.locations && updatedErrors.client.locations[idx] && (updatedErrors.client.locations[idx] as any)?.cp4) {
                                    (updatedErrors.client.locations[idx] as any).cp4 = null;
                                }
                                setErrors({
                                    ...updatedErrors
                                })
                                setFieldValue(`client.locations.${idx}.cp4`, sanitizedText);
                            }}
                            hasError={hasError()}
                            keyboardType="numeric"
                            maxLength={4}
                            placeholder="CP4"
                            editable={true}
                        />
                    </div>
                    <div className="text-xl font-bold">-</div>
                    <div className="w-4/12">
                        <CustomTextInput
                            value={location.cp3}
                            onChangeText={(text: string) => {
                                const sanitizedText = text.replace(/\D/g, '');
                                const updatedErrors = errors;
                                if (updatedErrors?.client?.locations && updatedErrors.client.locations[idx] && (updatedErrors.client.locations[idx] as any).cp3) {
                                    (updatedErrors.client.locations[idx] as any).cp3 = null;
                                }
                                setErrors({
                                    ...updatedErrors
                                })
                                setFieldValue(`client.locations.${idx}.cp3`, sanitizedText);
                            }}
                            hasError={hasError()}
                            keyboardType="numeric"
                            maxLength={3}
                            placeholder="CP3"
                            editable={true}
                        />
                    </div>
                </div>
                {hasError() ? <div className="text-red-500 text-xs">{getErrors()}</div> : null}
            </div>
        </InputFieldWithLabel>
    )

}

function ClientLocation({
    idx,
    location,
    editable,
    removeLocation
}: {
    idx: number;
    location: Location;
    editable: boolean;
    removeLocation: () => void
}) {
    const { values, setFieldValue, errors, setErrors } = useFormikContext<IMaintenance>();


    const {
        loadingCoords,
        coords,
        mapRef,
        handleMarkerDrag,
        displayMapContainer,
    } = useClientLocationMap({ values, setFieldValue, idx });


    return (
        <FormArrayElement
            key={idx}
            editable={true}
            form={
                <div>
                    <InputFieldWithLabel
                        propKey={`client.locations.${idx}.name`}
                        editable={true}
                        direction="row"
                        label="Nome"
                    >
                        <CustomTextInput
                            value={location.name}
                            onChangeText={(text: string) => {
                                const updatedErrors = errors;
                                if (updatedErrors?.client?.locations && updatedErrors.client.locations[idx] && (updatedErrors.client.locations[idx] as any).name) {
                                    (updatedErrors.client.locations[idx] as any).name = null;
                                }
                                setErrors({
                                    ...updatedErrors
                                })
                                setFieldValue(`client.locations.${idx}.name`, text)
                            }}
                            placeholder="Nome da localização"
                        />
                    </InputFieldWithLabel>
                    <div className="flex flex-row items-center">
                        <input
                            type="checkbox"
                            disabled={false}
                            checked={values.client.locations[idx]?.fetchedByDeviceLocation || false}
                            onChange={newValue => setFieldValue(`client.locations.${idx}.fetchedByDeviceLocation`, newValue)}
                        />
                        <div className="ml-3">Usar localização atual</div>
                    </div>

                    {!values.client.locations[idx]?.fetchedByDeviceLocation ? <CodigoPostalInput location={location} idx={idx} /> : null}
                    {displayMapContainer ? (
                        <div className="mt-3 border-0.5 border-gray-400 rounded-lg overflow-hidden flex items-center justify-center h-[200px]">
                            {loadingCoords ? (
                                <div>
                                    
                                    <div >A obter localização...</div>
                                </div>
                            ) : null}
                            {loadingCoords ? null : (
                                <div>REPLACE WITH MAP!!!!!</div>
                                // <MapView
                                //     provider={PROVIDER_GOOGLE}
                                //     ref={mapRef}
                                //     style={{
                                //         width: '100%',
                                //         height: 200,
                                //     }}
                                //     initialRegion={
                                //         coords
                                //             ? {
                                //                 latitude: coords?.latitude,
                                //                 longitude: coords?.longitude,
                                //                 latitudeDelta: 0.018,
                                //                 longitudeDelta: 0.0255,
                                //             }
                                //             : undefined
                                //     }>
                                //     {coords ? (
                                //         <Marker
                                //             draggable={editable && !values.client.locations[idx]?.fetchedByDeviceLocation}
                                //             onDragEnd={handleMarkerDrag}
                                //             coordinate={coords}
                                //         />
                                //     ) : null}
                                // </MapView>
                            )}
                        </div>
                    ) : null}

                    {values.client.locations[idx]?.fetchedByDeviceLocation ? <CodigoPostalInput location={location} idx={idx} /> : null}

                    <InputFieldWithLabel editable={true} label="Rua" propKey={`client.locations.${idx}.address`}>
                        <CustomTextInput
                            value={location.address}
                            onChangeText={(text: string) => {
                                const updatedErrors = errors;
                                if (updatedErrors?.client?.locations && updatedErrors.client.locations[idx] && (updatedErrors.client.locations[idx] as any).address) {
                                    (updatedErrors.client.locations[idx] as any).address = null;
                                }
                                setErrors({
                                    ...updatedErrors
                                })
                                setFieldValue(`client.locations.${idx}.address`, text)
                            }}
                            placeholder="Morada da localização"
                            editable={true}
                        />
                    </InputFieldWithLabel>
                    <div className='flex flex-row justify-end w-full mt-5'>
                        <CustomButton onClick={removeLocation}>Eliminar</CustomButton>
                    </div>
                </div>
            }
            collapsedView={<div>{location.name}</div>}
            idx={idx}
            name={'Localização'}
        />
    );
}




function ClientLocationsForm({
    arrayHelper,
    editable,
}: {
    arrayHelper: any;
    editable: boolean;
}) {

    const { values, errors, touched } = useFormikContext<IMaintenance>();


    const displayError = errors.client?.locations && touched.client?.locations;

    const [locationToDelete, setLocationToDelete] = useState<number | null>(null);

    const addLocation = () => {
        arrayHelper.push({
            address: '',
            name: '',
            fetchedByDeviceLocation: true,
            coordinatesForcefullySet: false,
            coordinates: null,
            cp3: '',
            cp4: '',
        });
    };

    const removeLocation = () => {
        arrayHelper.remove(locationToDelete);
    };

    return (
        <>
            <WarningModal
                isOpen={typeof locationToDelete === "number"}
                close={() => setLocationToDelete(null)}
                onConfirm={removeLocation}
                mainText='Tem a certeza que pretende eliminar esta localização?'
                description='Esta acção é irreversível'
            />

            <div className="">
                <div className='flex flex-row justify-between items-center w-full my-5 '>
                    <div className="font-bold">Localizações cliente</div>
                    {/* <InfoCircle content={<ScrollView >

                        <div>A especificação de localizações de clientes funciona de 2 formas (por completar):</div>
                        <div className='p-1 font-bold'>1 - Localização atual</div>
                        <div className='p-1 px-6'>- O cursor não pode ser movido manualmente</div>
                        <div className='p-1 px-6'>- O código postal é preenchido automaticamente mas o valor pode não ser o correto (editável)</div>

                        <div className='p-1 font-bold'>2 - Não utilizar localização atual</div>
                        <div className='p-1 px-6'>- Sugerido preencher código postal em primeiro</div>
                        <div className='p-1 px-6'>- A localização referente ao mesmo será atualizada no mapa</div>
                        <div className='p-1 px-6'>- O cursor pode ser movido para especificar a localização do cliente com maior precisão</div>



                    </ScrollView>} /> */}
                </div>
                {values?.client?.locations?.length ? (
                    values?.client.locations?.map((location: Location, idx: number) => (
                        <ClientLocation
                            key={idx}
                            idx={idx}
                            location={location}
                            editable={editable}
                            removeLocation={() => setLocationToDelete(idx)}
                        />
                    ))
                ) : (
                    <>
                        {displayError ?
                            <div>
                                <div className="flex flex-row w-full items-center">
                                    
                                    <div>Warning</div>
                                    <div className="text-red-500 text-xs ml-2">Cliente não possui nenhuma localização associada</div>
                                </div>
                                {/* <div className="text-red-500 text-xs">Cliente não possui nenhuma localização associada</div> */}
                            </div>
                            :
                            <div className="text-xs">Cliente não possui nenhuma localização associada</div>
                        }

                    </>

                )}
                <div className="flex flex-row w-full justify-end">
                    
                    <CustomButton onClick={addLocation}>Adicionar Localização</CustomButton>
                </div>
            </div>
        </>
    );
}

export function Locations() {



    return (
        <>
            <FieldArray
                name="client.locations"
                render={arrayHelpers => {
                    return (
                        <ClientLocationsForm
                            arrayHelper={arrayHelpers}
                            editable={true}
                        />
                    );
                }}
            />
        </>
    )



}