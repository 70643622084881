
export const getLocation = async (setter: (coords: { latitude: number, longitude: number }) => void) => {


  navigator?.geolocation?.getCurrentPosition(
    position => {
      console.log(position);
      const { latitude, longitude } = position.coords;
      setter(position.coords);
    },
    error => {
      console.error(error);
    },
    { enableHighAccuracy: true, timeout: 15000, maximumAge: 10000 }
  );

};