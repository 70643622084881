import { useFormikContext } from "formik";
import { IClient, IMaintenance, Location } from "../../../../../@types";
import { SchedulingStatus } from ".";
import { LatLngExpression } from "leaflet";
import { Map } from "../../../../../components/Map";



export function ClientLocations() {

    const { values, setFieldValue } = useFormikContext<IMaintenance & { scheduledDateTime: Date, schedulingStatus: SchedulingStatus, timezone: string, client: IClient }>();

    return (
        <>
            <div className="font-bold mb-2">Localizações</div>
            <div className="grid grid-cols-2 gap-4">
                {values.client?.locations.map((location: Location) => {
                    const coords = [location.coordinates?.latitude, location.coordinates?.longitude] as LatLngExpression;
                    return (
                        <div key={location.id} className="p-2 rounded-md shadow-md border border-0.5">
                            <div className="flex items-center justify-between">
                                <div className="font-bold">{location.name}</div>
                                <input type="radio" name="clientLocation" value={location.id} onChange={(e) => setFieldValue('clientLocation', location)} />
                            </div>
                            <div className="mb-3">Morada: {location.address}</div>

                            <Map
                                coords={coords}
                                popupLabel={coords.toString()}
                            />
                        </div>
                    )
                }
                )}
            </div>
        </>
    )


}