import { useTranslation } from "react-i18next";
import { useContext, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import CustomButton from "../../components/buttons/Button";
import { ButtonSize, ButtonVariant } from "../../components/buttons/types";
import { CookieSettings } from "../legals";

export default function CookiePolicy() {
  const { t } = useTranslation("cookiePolicy");
  const { user } = useContext(AuthContext);
  const [displayCookieSettings, setDisplayCookieSettings] = useState(false);

  return (
    <div className="w-full h-full flex justify-center px-6 sm:px-9 md:px-0 mt-5 md:mt-9">
      <CookieSettings
        settingsOpen={displayCookieSettings}
        close={() => setDisplayCookieSettings(false)}
      />
      <div className={`w-full ${user && "md:w-1/2"}`}>
        <h1 className="text-default text-lg font-semibold mb-4">
          {t("page-title")}
        </h1>
        <div>Amazing cookies</div>
        <CustomButton
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.SMALL}
          onClick={() => setDisplayCookieSettings(true)}
        >
          {t("settings-button")}
        </CustomButton>
      </div>
    </div>
  );
}
