import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useContext, useState } from "react";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { CustomFieldWithError } from "../../components/forms/CustomFieldWithError";
import { SIGNIN_ERRORS } from "../../errors/errorCodes";
import { AuthContext } from "../../contexts/AuthContext";
import { signin } from "../../services/auth.service";
import { NavigationBar } from "../../components/auth/NavigationBar";
import { PasswordField } from "../../components/forms/PasswordField";
import CustomButton from "../../components/buttons/Button";
import { ButtonSize, ButtonVariant } from "../../components/buttons/types";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import backgroundImage from "../../assets/AuthBackground.jpeg";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Email is required")
    .email("Invalid email address"),
  password: Yup.string().required("Password is required"),
});

export default function Signin({
  goToSignup,
  goToResetPassword,
}: {
  goToSignup: () => void;
  goToResetPassword: () => void;
}) {
  const [invalidPasswordError, setInvalidPasswordError] = useState<
    string | null
  >(null);
  const [invalidEmailError, setInvalidEmailError] = useState<string | null>(
    null
  );

  const { setUser } = useContext(AuthContext);
  const { t } = useTranslation("authentication");

  const loginMutation = useMutation({
    mutationFn: signin,
    onSuccess: (data: any) => {
      console.log("data", data.data.token);
      setUser(data.data.user);
      try {
        localStorage.setItem("maintenaid-access-token", data.data.token);
        if (data.data.refreshToken) {
          console.log("data", data.data.refreshToken);
          localStorage.setItem(
            "maintenaid-refresh-token",
            data.data.refreshToken
          );
        }
        // navigate("/");
      } catch (error) {
        console.error("Error storing token in local storage:", error);
      }
    },
    onError: ({ response }: any) => {
      if (response.data.message === SIGNIN_ERRORS.INVALID_PASSWORD) {
        setInvalidPasswordError("Password inválida");
      } else if (response.data.message === SIGNIN_ERRORS.UNREGISTERED_EMAIL) {
        setInvalidEmailError("Email inválido");
      } else {
        console.error("Login error:", response);
      }
    },
  });

  return (
    <div className="flex flex-col   px-4 sm:px-6 lg:px-8">
      <main className="flex-1 bg-cover bg-center">
        <div className="flex justify-start items-start ">
          <div className="w-full max-w-md bg-white shadow-lg rounded-lg p-8">
            <NavigationBar goToSignup={goToSignup} activeTab="Iniciar sessão" />
            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                setInvalidEmailError(null);
                setInvalidPasswordError(null);
                loginMutation.mutate(values);
              }}
            >
              {({ errors, isValid, touched, values }) => {
                const noFieldsEmpty =
                  values.email.length > 0 && values.password.length > 0;
                const disabled = !isValid || !noFieldsEmpty;

                return (
                  <Form className="space-y-6">
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email*
                      </label>
                      <CustomFieldWithError
                        className="mt-1 w-full px-2 border rounded h-10 shadow-sm focus:ring-default focus:border-default"
                        type="email"
                        id="email"
                        name="email"
                        error={errors.email || invalidEmailError}
                        touched={!!touched.email}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Password personalizada*
                      </label>
                      <PasswordField
                        error={errors.password || invalidPasswordError}
                        touched={!!touched.password}
                      />
                    </div>

                    <CustomButton
                      type="submit"
                      className={`w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                        disabled
                          ? "bg-gray-400"
                          : "bg-indigo-600 hover:bg-indigo-700"
                      }`}
                      disabled={disabled}
                      variant={ButtonVariant.PRIMARY}
                      size={ButtonSize.MEDIUM}
                    >
                      Iniciar sessão
                    </CustomButton>
                  </Form>
                );
              }}
            </Formik>

            <div className="text-sm mt-4 text-center">
              <button
                className="items-center text-default hover:text-600 transition-transform hover:translate-x-1"
                onClick={goToResetPassword}
              >
                Esqueceu a password?
              </button>
            </div>

            {/* Go to Signup Button with Arrow */}
            <div className="mt-4 text-sm text-center flex justify-center items-center space-x-2">
              <span>Registe-se agora:</span>
              <button
                className="flex items-center text-default hover:text-600 transition-transform hover:translate-x-1"
                onClick={goToSignup}
              >
                Torne-se um membro <ArrowForwardIcon className="ml-1" />
              </button>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
