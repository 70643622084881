

import { Dialog } from "@mui/material";
import { IMaintenance } from "../../../../../@types";
import { MaintenanceRememberMePeriod } from "../../../../../enums";

const RememberButton = ({ maintenance, value, label, rememberMaintenanceIn }: {
    maintenance: IMaintenance,
    value: MaintenanceRememberMePeriod,
    label: string,
    rememberMaintenanceIn: (rememberMeIn: MaintenanceRememberMePeriod | null) => void
}) => {


    return (
        <div style={{
            backgroundColor: maintenance.rememberMeIn === value ? "orange" : "blue",

        }} onClick={() => rememberMaintenanceIn(maintenance.rememberMeIn === value ? null : value)}
            className=" rounded-full w-full items-center p-3 mb-2">
            <div className="text-white">
                {label}
            </div>
        </div>
    )
}


export function RememberIn({ isOpen, close, rememberMaintenanceIn, maintenance }: {
    isOpen: boolean,
    close: () => void,
    rememberMaintenanceIn: (rememberMeIn: MaintenanceRememberMePeriod | null) => void,
    maintenance: IMaintenance
}) {




    return (
        <Dialog
            open={isOpen}
            // close={close}
            title="Relembrar manutenção"
        >
            <div>
                <div className='h-10 w-full flex flex-row p-2 rounded-t-md items-center justify-between bg-primary'>
                    <div className='text-white font-bold'>Relembrar manutenção em</div>
                    
                    <div onClick={()=> close()}>close</div>
                </div>

                <div className="p-2 border border-gray-300 rounded-b-md bg-white">
                    <div className="my-2">
                        <RememberButton maintenance={maintenance} value={MaintenanceRememberMePeriod.THREE_MONTHS} label="3 meses" rememberMaintenanceIn={rememberMaintenanceIn} />
                    </div>
                    <div className="my-2">
                        <div onClick={() => rememberMaintenanceIn(MaintenanceRememberMePeriod.SIX_MONTHS)}
                            className={`rounded-full w-full items-center p-3 mb-2 ${maintenance.rememberMeIn === MaintenanceRememberMePeriod.SIX_MONTHS ? 'bg-secondary' : 'bg-primary'}`}>
                            <div className="text-white">6 meses</div>
                        </div>
                    </div>

                    <div className="my-2">
                        <div onClick={() => rememberMaintenanceIn(MaintenanceRememberMePeriod.TWELVE_MONTHS)}
                            className={`rounded-full w-full items-center p-3 mb-2 ${maintenance.rememberMeIn === MaintenanceRememberMePeriod.TWELVE_MONTHS ? 'bg-secondary' : 'bg-primary'}`}>
                            <div className="text-white">1 ano</div>
                        </div>
                    </div>

                </div>

            </div>
        </Dialog>
    )

}