import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup"; // Importar Yup para validação
import { useStockActions } from "../hooks/useStockActions";
import {
  ButtonSize,
  ButtonVariant,
} from "../../../../../components/buttons/types";
import CustomButton from "../../../../../components/buttons/Button";
import { StockActionType } from "../../enums";

// Esquema de validação usando o Yup
const validationSchema = Yup.object({
  quantity: Yup.number()
    .min(1, "A quantidade deve ser maior ou igual a 1")
    .required("A quantidade é obrigatória"),
  comment: Yup.string(),
});

interface StockActionSectionProps {
  stockId: number;
}

const StockActionSection: React.FC<StockActionSectionProps> = ({ stockId }) => {
  const { performAction } = useStockActions();
  const [showActions, setShowActions] = useState(false);

  return (
    <>
      {/* Botão para alternar o modal */}
      <div className="mb-4">
        <CustomButton
          type="button"
          className="mt-2"
          variant={ButtonVariant.PRIMARY}
          size={ButtonSize.SMALL}
          onClick={() => setShowActions(!showActions)}
        >
          {"Mostrar Ações"}
        </CustomButton>
      </div>

      {/* Modal */}
      {showActions && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-3xl w-full">
            {/* Botão de Fechar */}

            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-bold">Ações do Stock</h3>
              <button
                className="text-gray-500 hover:text-gray-700"
                onClick={() => setShowActions(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            {/* Formulário de Ações de Stock */}

            <Formik
              initialValues={{ quantity: 1, comment: "" }} // Quantidade padrão para 1
              validationSchema={validationSchema} // Anexar o esquema de validação
              onSubmit={(values) => {
                console.log("Valores submetidos", values);
              }}
            >
              {({ values, handleChange, handleBlur }) => (
                <Form className="flex flex-col space-y-4">
                  <div>
                    <label className="block text-sm font-medium">
                      Quantidade
                    </label>
                    <Field
                      type="number"
                      name="quantity"
                      min="1" // Impedir valores inferiores a 1
                      className="mt-2 p-2 border w-full rounded"
                      placeholder="Quantidade"
                      value={values.quantity}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                    />
                    {/* Mostrar mensagem de erro para quantidade inválida */}
                    <ErrorMessage
                      name="quantity"
                      component="div"
                      className="text-red-600 text-sm mt-1"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium">Comentário</label>
                    <Field
                      type="text"
                      name="comment"
                      className="mt-2 p-2 border w-full rounded"
                      placeholder="Comentário opcional"
                    />
                    <ErrorMessage
                      name="comment"
                      component="div"
                      className="text-red-600 text-sm mt-1"
                    />
                  </div>

                  {/* Botões de Ação */}
                  <div className="flex space-x-2">
                    <CustomButton
                      type="button"
                      className="mt-2"
                      variant={ButtonVariant.PRIMARY}
                      size={ButtonSize.SMALL}
                      onClick={() => {
                        performAction.mutate({
                          stockId,
                          quantity: values.quantity,
                          comment: values.comment,
                          action: StockActionType.INBOUND,
                        });
                        setShowActions(false);
                      }}
                    >
                      Depositar Stock
                    </CustomButton>

                    <CustomButton
                      type="button"
                      className="mt-2"
                      variant={ButtonVariant.PRIMARY}
                      size={ButtonSize.SMALL}
                      onClick={() => {
                        performAction.mutate({
                          stockId,
                          quantity: values.quantity,
                          comment: values.comment,
                          action: StockActionType.OUTBOUND,
                        });
                        setShowActions(false);
                      }}
                    >
                      Retirar Stock
                    </CustomButton>

                    <CustomButton
                      type="button"
                      className="mt-2"
                      variant={ButtonVariant.PRIMARY}
                      size={ButtonSize.SMALL}
                      onClick={() => {
                        performAction.mutate({
                          stockId,
                          quantity: values.quantity,
                          comment: values.comment,
                          action: StockActionType.RESERVATION_PENDING,
                        });
                        setShowActions(false);
                      }}
                    >
                      Reservar Stock
                    </CustomButton>

                    <CustomButton
                      type="button"
                      className="mt-2"
                      variant={ButtonVariant.PRIMARY}
                      size={ButtonSize.SMALL}
                      onClick={() => {
                        performAction.mutate({
                          stockId,
                          quantity: values.quantity,
                          comment: values.comment,
                          action: StockActionType.RESERVATION_FULFILLED,
                        });
                        setShowActions(false);
                      }}
                    >
                      Cumprir Reserva
                    </CustomButton>

                    <CustomButton
                      type="button"
                      className="mt-2"
                      variant={ButtonVariant.PRIMARY}
                      size={ButtonSize.SMALL}
                      onClick={() => {
                        performAction.mutate({
                          stockId,
                          quantity: values.quantity,
                          comment: values.comment,
                          action: StockActionType.RETURN_PENDING,
                        });
                        setShowActions(false);
                      }}
                    >
                      Devolução Pendente
                    </CustomButton>

                    <CustomButton
                      type="button"
                      className="mt-2"
                      variant={ButtonVariant.PRIMARY}
                      size={ButtonSize.SMALL}
                      onClick={() => {
                        performAction.mutate({
                          stockId,
                          quantity: values.quantity,
                          comment: values.comment,
                          action: StockActionType.RETURN_CONFIRMED,
                        });
                        setShowActions(false);
                      }}
                    >
                      Confirmar Devolução
                    </CustomButton>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </>
  );
};

export default StockActionSection;
