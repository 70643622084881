import { Formik } from "formik";
import { IClient } from "../../../../../@types";
import { WarningModal } from "../../../../../components/WarningModal";
import { InputFieldWithLabel } from "../../../../../components/FieldWithLabel";
import { CustomTextInput } from "../../../../../components/CustomTextInput";
import { Locations } from "./Locations";



export function ClientForm({ client }: { client?: IClient }) {


    return (
        <Formik
            initialValues={{
                id: client?.id || 0 ,
                name: client?.name || '',
                nif: client?.nif || '',
                email: client?.email || '',
                phone: client?.phone || '',
                address: client?.address || '',
                locations: client?.locations || [],
            }}
            onSubmit={values => {
                console.log("submit")
            }}>
            {({
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setErrors,
                values,
                touched,
                errors,
                dirty,
            }) => {
                return (
                    <>
                        <InputFieldWithLabel propKey='nif' editable={!values?.id} direction="row" label="NIF">
                            <CustomTextInput
                                value={values?.nif}
                                onChangeText={(text: string) => {
                                    const sanitizedText = text.replace(/\D/g, '');
                                    if (sanitizedText.length === 9) {
                                        setErrors({
                                            ...errors,
                                            nif: ''
                                            
                                        })
                                    }
                                    setFieldValue('nif', sanitizedText);
                                }}
                                keyboardType="numeric"
                                maxLength={9}
                                placeholder="NIF do cliente"
                            />
                        </InputFieldWithLabel>
                        <InputFieldWithLabel propKey='name' editable={true} direction="row" label="Nome">
                            <CustomTextInput
                                value={values?.name}
                                onChangeText={(text: string) => {
                                    if (text.length > 2) {
                                        setErrors({
                                            ...errors,
                                            name: ''
                                            
                                        })
                                    }
                                    setFieldValue('name', text)
                                }}
                                placeholder="Nome do cliente"
                            />
                        </InputFieldWithLabel>

                        <InputFieldWithLabel propKey='email' editable={true} direction="row" label="Email">
                            <CustomTextInput
                                value={values?.email}
                                onChangeText={(text: string) =>
                                    setFieldValue('email', text)
                                }
                                placeholder="Email do cliente"
                            />
                        </InputFieldWithLabel>
                        <InputFieldWithLabel
                            editable={true}
                            direction="row"
                            propKey='phone'
                            label="Telefone">
                            <CustomTextInput
                                value={values?.phone}
                                onChangeText={(text: string) => {
                                    const sanitizedText = text.replace(/\D/g, '');
                                    setFieldValue('phone', sanitizedText);
                                }}
                                keyboardType="phone-pad"
                                maxLength={9}
                                placeholder="Telefone do cliente"
                            />
                        </InputFieldWithLabel>

                        <Locations />
                    </>
                );
            }}
        </Formik>
    )






}