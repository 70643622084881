import { useContext, useEffect } from "react";
import { AuthContext } from "../../../contexts/AuthContext";


export function Logout() {

    // const {log} = useContext(AuthContext);

    // useEffect(()=>{
    //     logoutUser();
    // },[logoutUser])

    return null;


}