import {createContext, useEffect, useState} from 'react';
import {IMaintenance} from '../@types';
import {useQuery} from '@tanstack/react-query';
import { getMaintenanceList } from '../services/maintenances.service';

export type MaintenanceContextData = {
  maintenances: IMaintenance[] | undefined;
  total: number;
  refetchMaintenanceList: () => void;
  setPage: (page: number) => void;
  setSortBy: (sortBy: string) => void;
  setSortOrder: (sortOrder: 'ASC' | 'DESC') => void;
  setSearch: (search: string) => void;
  setStatus: (status: string) => void;
  sortBy: string;
  sortOrder: 'ASC' | 'DESC';
  page: number;
  search: string;
  status: string;
  isLoading: boolean;
  schedulingNewObra: boolean;
  setSchedulingNewObra: (value: boolean) => void;
};

export const MaintenanceListContext = createContext<MaintenanceContextData>(
  {} as MaintenanceContextData,
);

export function MaintenanceListContextProvider({
  children,
}: {
  children: React.ReactElement;
}) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState('updatedAt');
  const [sortOrder, setSortOrder] = useState<'ASC' | 'DESC'>('DESC');
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('');

  const [schedulingNewObra, setSchedulingNewObra] = useState<boolean>(false);

  const { data, refetch: refetchMaintenanceList, isLoading } = useQuery({
    queryKey: ['maintenances', page, limit, sortBy, sortOrder, search, status],
    queryFn: () => getMaintenanceList(page, limit, sortBy, sortOrder, search, status),
  });

  useEffect(() => {
    refetchMaintenanceList();
  }, [page, limit, sortBy, sortOrder, search, status]);

  return (
    <MaintenanceListContext.Provider
      value={{
        maintenances: data?.data,
        total,
        refetchMaintenanceList,
        setPage,
        setSortBy,
        setSortOrder,
        setSearch,
        setStatus,
        sortOrder,
        page,
        search,
        status,
        sortBy,
        isLoading,
        schedulingNewObra,
        setSchedulingNewObra,
      }}>
      {children}
    </MaintenanceListContext.Provider>
  );
}
