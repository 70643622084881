import { useContext, useState } from "react";
import { ReactComponent as Logo } from "../../../assets/MDP.svg";
import { AuthContext } from "../../../contexts/AuthContext";
import {
  StepAddressParameters,
  StepContactsParameters,
  StepLogoParameters,
  StepNameParameters,
  StepSignatureParameters,
  UserDetails,
} from "../../../@types";
import StepName from "./stepName";
import StepNeeds from "./stepContacts";
import SubmitStep from "./submitStep";
import { PublicFooter } from "../../../components/footer/PublicFooter";
import StepAddress from "./stepAddress";
import StepLogo from "./stepLogo";
import StepSignature from "./stepSignature";
import { useMutation } from "@tanstack/react-query";
import StepExtras from "./stepExtras";
import { patchOnboardingData } from "../../../services/user-details";

export function Onboarding() {
  const { setUser } = useContext(AuthContext);

  const [currentStep, setCurrentStep] = useState<number>(1);
  const [nameStepInfo, setNameStepInfo] = useState<StepNameParameters>({
    nif: "",
    companyName: "",
    address: "",
    mobileNumber: "",
    phoneNumber: "",
    website: "",
    pc3: "",
    pc4: "",
    activity: "",
  });

  const [contactsStepInfo, setContactsStepInfo] =
    useState<StepContactsParameters>({
      firstName: "",
      lastName: "",
    });

  const [extrasStepInfo, setExtrasStepInfo] = useState<any>({
    seriesPrefix: "",
    nextNumber: "",
  });

  const [logoStepInfo, setLogoStepInfo] = useState<StepLogoParameters>({
    logo: "",
  });

  const [signatureStepInfo, setSignatureStepInfo] =
    useState<StepSignatureParameters>({
      signature: "",
    });

  const handleNameNextStep = (data: StepNameParameters) => {
    setNameStepInfo(data);
    skipStep();
  };

  const handleContactsNextStep = (data: StepContactsParameters) => {
    setContactsStepInfo(data);
    skipStep();
  };

  const handleLogoNextStep = (data: StepLogoParameters) => {
    setLogoStepInfo(data);
    skipStep();
  };

  const handleExtrasNextStep = (data: any) => {
    setExtrasStepInfo(data);
    skipStep();
  };

  const skipStep = () => setCurrentStep(currentStep + 1);

  const handleSubmit = async () => {
    const updatedContactsStepInfo = Object.fromEntries(
      Object.entries(contactsStepInfo).map(([key, value]) => {
        return [key, value === "" ? null : value];
      })
    );

    const updatedLogoStepInfo = Object.fromEntries(
      Object.entries(logoStepInfo).map(([key, value]) => {
        return [key, value === "" ? null : value];
      })
    );

    const payload: UserDetails = {
      ...nameStepInfo,
      ...updatedContactsStepInfo,
      ...updatedLogoStepInfo,
      ...extrasStepInfo,
    };
    try {
      await submitNewUserDetails.mutate( payload );
    } catch (error) {
      console.log("ERROR: ", error);
    }
  };

  const submitNewUserDetails = useMutation({
    mutationFn: patchOnboardingData,
    onSuccess: (response) => {
      setUser(response);
    },
    onError: (error: any) => {
      console.log("Error: ", error);
    },
  });

  let content;
  switch (currentStep) {
    case 1:
      content = <StepName onNext={handleNameNextStep} onSkip={skipStep} />;
      break;
    case 2:
      content = <StepNeeds onNext={handleContactsNextStep} onSkip={skipStep} />;
      break;
    case 3:
      content = <StepLogo onNext={handleLogoNextStep} onSkip={skipStep} />;
      break;
    case 4:
      content = <StepExtras onNext={handleExtrasNextStep} onSkip={skipStep} />;
      break;
    case 5:
      content = <SubmitStep onNext={handleSubmit} />;

      break;
    default:
      content = <div>Invalid step</div>;
  }

  return (
    <div className="flex flex-col h-screen overflow-auto">
      {/* Navbar */}
      <div className="flex w-full items-center p-4 justify-center bg-Grey-navbar fixed top-0 inset-x-0 z-10">
        <a href="/" className="cursor-pointer">
          <Logo className="h-10" />
        </a>
      </div>

      {/* Main Content */}
      <div className="flex justify-center flex-1 ">
        {" "}
        {/* Spacing from navbar */}
        <div className="w-full px-4 md:px-6 lg:px-8">
          <div className="mx-auto max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl p-8">
            {content}
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="mt-auto w-full">
        <PublicFooter />
      </div>
    </div>
  );
}
