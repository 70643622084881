import React from "react";

const Pagination: React.FC<{
  currentPage: number;
  totalPages: number;
  setCurrentPage: (page: number | ((prev: number) => number)) => void;
}> = ({ currentPage, totalPages, setCurrentPage }) => {
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  return (
    <div className="flex justify-end space-x-2 mt-4">
      <button
        className={`bg-white border border-black text-black px-2 py-1 rounded-md transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm ${
          currentPage === 1 ? "text-gray-400 cursor-not-allowed" : "hover:bg-gray-100"
        }`}
        onClick={() => setCurrentPage(currentPage - 1)}
        disabled={currentPage === 1}
      >
        {"<"}
      </button>
      {pageNumbers.map((number: number) => (
        <button
          key={number}
          onClick={() => setCurrentPage(number)}
          disabled={number === currentPage}
          className={`bg-white border border-black text-black px-2 py-1 rounded-md transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm ${
            number === currentPage ? "opacity-50 cursor-default" : "hover:bg-gray-100"
          } ${number === currentPage ? "text-gray-400 cursor-not-allowed" : ""}`}
        >
          {number}
        </button>
      ))}
      <button
        className={`bg-white border border-black text-black px-2 py-1 rounded-md transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm ${
          currentPage === totalPages ? "text-gray-400 cursor-not-allowed" : "hover:bg-gray-100"
        }`}
        onClick={() => setCurrentPage(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        {">"}
      </button>
    </div>
  );
};

export default Pagination;
