import axios, { AxiosError } from "axios";

import axiosInstance from ".";
import { IMaintenance } from "../@types";
import { MaintenanceRememberMePeriod } from "../enums";


export async function getMaintenanceList(
  page: number,
  limit: number,
  sortBy: string,
  sortOrder: 'ASC' | 'DESC',
  search: string,
  status: string
): Promise<{ data: any; total: number }> {
  try {
    const response = await axiosInstance.get('/maintenance', {
      params: { page, limit, sortBy, sortOrder, search, status },
    });
    return {
      data: response.data.data,
      total: response.data.total,
    };
  } catch (error) {
    console.error('Error getting maintenance list:', error);
    throw error;
  }
}

export async function getMaintenance(id?: number): Promise<IMaintenance | null> {
  console.log("Id: ", id);
  try {
    if (!id) return null;
    return (await axiosInstance.get(`/maintenance/${id}`)).data;
  } catch (error) {
    console.error(`Error getting maintenance with id ${id}`, error);
    throw error;
  }

}


export async function downloadReport(id: number): Promise<any> {
  try {
    return (await axiosInstance.post(`/maintenance/${id}/report`)).data;
  } catch (error) {
    console.error('Error downloading report:', error);
    throw error;
  }
}

export async function sendNotificationFormDownload({
  title,
  body,
}: {
  title: string;
  body: string;
}): Promise<any> {
  try {
    const response = await axiosInstance.post('/notification/send', {
      title,
      body,
    });
    return response.data;
  } catch (error) {
    console.error('Error sending notification:', error);
    throw error;
  }
}

export const createMaintenance = async (maintenance: IMaintenance): Promise<IMaintenance> => {

  try {
    return (await axiosInstance.post('/maintenance', maintenance)).data;
  } catch (error) {
    console.error('Error signing in:', error);
    throw error;
  }
}

export const patchMaintenance = async (maintenance: IMaintenance): Promise<any> => {

  try {
    return await axiosInstance.patch(`/maintenance`, maintenance);
  } catch (error: any) {
    console.error('Error patching form:', error);
    throw error;
  }
}

export const submitMaintenance = async (maintenance: IMaintenance): Promise<IMaintenance> => {

  try {
    return (await axiosInstance.post(`/maintenance/submit`, maintenance)).data;
  } catch (error) {
    console.error('Error signing in:', error);
    throw error;
  }
}

const maxRetryAttempts = 3; // Maximum number of retry attempts
const retryDelay = 1000; // Delay between retries in milliseconds

let retryAttempts = 0;

export const submitMaintenanceAnexos = async (maintenanceId: number, formData: FormData): Promise<any> => {
  try {
    const response = await axiosInstance.post(`/maintenance/${maintenanceId}/photos`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    // Reset retryAttempts upon successful upload
    retryAttempts = 0;

    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;

      // Retry on network error or timeout, up to maxRetryAttempts
      if (axiosError.code === 'ECONNABORTED' || !axiosError.response) {
        if (retryAttempts < maxRetryAttempts) {
          retryAttempts++;
          console.warn(`Retrying request (${retryAttempts}/${maxRetryAttempts})...`);
          await new Promise(resolve => setTimeout(resolve, retryDelay));
          return submitMaintenanceAnexos(maintenanceId, formData);
        }
      }

      if (axiosError.response) {
        console.error('Request was made but server responded with error:', axiosError.response.status);
        console.error('Response data:', axiosError.response.data);
        console.error('Response headers:', axiosError.response.headers);
      } else if (axiosError.request) {
        console.error('Request was made but no response received:', axiosError.request);
      } else {
        console.error('Error setting up request:', axiosError.message);
      }
    } else {
      console.error('Error uploading maintenance anexos:', error);
    }

    throw error;
  }
};


export const deleteMaintenancePhoto = async (photoId: number): Promise<{ message: string }> => {
  try {
    const response = await axiosInstance.delete(`/maintenance/photos/${photoId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting maintenance photo:', error);
    throw error;
  }
};

export const getMaintenanceAnexo = async (anexoId: number): Promise<any> => {
  try {
    const response = await axiosInstance.get(`/maintenance/anexos/${anexoId}`, { responseType: 'blob' });
    return response.data;
  } catch (error) {
    console.error('Error fetching maintenance anexos:', error);
    throw error;
  }
};
export const billMaintenance = async (input: { billingNumber: string, billingValue: string, maintenanceId: number }): Promise<any> => {

  const payload = { billingNumber: input.billingNumber, billingValue: parseFloat(input.billingValue) };
  try {
    return await axiosInstance.post(`/maintenance/billing/${input.maintenanceId}`, payload);
  } catch (error) {
    console.error('Erro a faturar obra:', error);
    throw error;
  }

}


export const rememberMaintenance = async (input: {maintenanceId: number, rememberMeIn: MaintenanceRememberMePeriod | null}): Promise<any> => {

  try {
    return await axiosInstance.patch(`/maintenance/${input.maintenanceId}/remember`, {
      rememberMeIn: input.rememberMeIn,
    });
  } catch (error) {
    console.error('Error remembering maintenance:', error);
    throw error;
  }

}

export const markMaintenanceAsSent = async (input: {maintenanceId: number} ): Promise<void> =>{
  try {
    return await axiosInstance.put(`/maintenance/${input.maintenanceId}/mark-sent`);
  } catch (error) {
    console.error('Error marking maintenance as sent:', error);
    throw error;
  }
}
