
import { useFormikContext } from 'formik';
import { IMaintenance } from '../../../../../@types';

import { Locations } from './Locations';
import { useRef, useState } from 'react';
import { Dialog } from '@mui/material';
import { InputFieldWithLabel } from '../../../../../components/FieldWithLabel';
import { CustomTextInput } from '../../../../../components/CustomTextInput';
import CustomButton from '../../../../../components/buttons/Button';
import { WarningModal } from '../../../../../components/WarningModal';



export function ClientNotSavedModal({
  isOpen,
  close,
  confirm
}: {
  isOpen: boolean,
  close: () => void,
  confirm: () => void
}) {

  return (
    <WarningModal
      isOpen={isOpen}
      close={close}
      onConfirm={confirm}
      mainText={'Este cliente não está guardado na sua base de dados. Pretende prosseguir com esta operação?'}
      description={<div>
        <div>Os dados relativos a este cliente serão perdidos</div>
        {/* <div className='p-2'>
          <div className='mb-3'>Caso pretenda guardar os dados deste cliente:</div>
          <div className='pl-4 text-xs'>1- Clique confirmar para sair do menu de edição do cliente</div>
          <div className='pl-4 text-xs'>2- Clique em guardar (botão de opções no canto superior direito / Guardar)</div>
          <div className='pl-4 text-xs'>3- Caso pretenda utilizar outro cliente pode agora fazê-lo</div>

        </div> */}

      </div>}
    />
  )


}



export function ClientModal({
  open,
  close,
}: {
  open: boolean;
  close: () => void;
}) {

  const { values, setFieldValue, validateForm, setTouched, setErrors, errors } = useFormikContext<IMaintenance>();

  const [noEmailWarningModalOpen, setNoEmailWarningModalOpen] = useState(false);
  const [closeAttempt, setCloseAttempt] = useState(false);
  const [clientNotSavedWarningModalOpen, setClientNotSavedWarningModalOpen] = useState(false);

  const scrollRef = useRef<any>(null);


  const handleConfirmAttempt = async () => {
    setCloseAttempt(true);
    const errors = await validateForm();
    setTouched({
      client: {
        name: true,
        email: true,
        phone: true,
        nif: true,
        locations: values.client?.locations?.map(() => {
          return {
            address: true,
            coordinates: true,
            name: true,
            cp4: true,
            cp3: true
          }
        }) || null
      }
    });
    if (!Object.keys(errors).length) {
      if (!values.client?.email) {
        setNoEmailWarningModalOpen(true);
      } else {
        handleConfirmSuccess()
      }
    } else {
      scrollRef.current?.scrollTo({
        y: 0,
        animated: true,
      });
    }
  }

  const handleConfirmSuccess = () => {
    close();
    setCloseAttempt(false);
    setFieldValue('clientLocation', values.client.locations[0])
  }


  const closeWithoutSaving = () => {
    close();
    if (!values.client?.id && !values.clientLocation) {
      setFieldValue('client', null);
      setFieldValue('clientLocation', null);
    }
    setCloseAttempt(false);
    setTouched({
      client: {
        name: false,
        email: false,
        phone: false,
        nif: false,
      }
    })
  }

  return (
    <>
      <WarningModal
        isOpen={noEmailWarningModalOpen}
        close={() => setNoEmailWarningModalOpen(false)}
        onConfirm={handleConfirmSuccess}
        mainText={'Pretende registar cliente sem um email?'}
        description={'Ao registar um cliente sem email, este não receberá automaticamente a folha de obra, terá de ser enviada manualmente'}
      />

      <ClientNotSavedModal
        isOpen={clientNotSavedWarningModalOpen}
        close={() => setClientNotSavedWarningModalOpen(false)}
        confirm={closeWithoutSaving}
      />

      <Dialog open={open}>
        <div
    
          className=" m-2 h-[98%] opacity-100 z-50 rounded-md border border-gray-400 border-0.5 bg-white">
          <div
            
            className="h-10 w-full flex flex-row  p-2 rounded-t-md items-center justify-between bg-primary shadow-md">
            <div className="text-white font-bold">Registo cliente</div>

            <div
              
              color={'white'}
              
              onClick={() => {
                if (!values?.client?.id && (values.client && Object.keys(values.client)?.length > 0) && (!values.clientLocation || !Object.values(values.clientLocation)?.length)) {
                  setClientNotSavedWarningModalOpen(true);
                  return;
                }
                closeWithoutSaving()
              }}
            >close</div>
          </div>
          <div ref={scrollRef}>
            <div className="p-3">
              {!!Object.keys(errors).length && closeAttempt ? <div className='flex flex-row p-2 mb-2 max-w-full items-center'>
                <div>Warning</div>
                <div className='text-xs text-red-500 ml-2 w-80'>Formulário contém campos inválidos, por favor confirme que toda a informação está devidamente preenchida</div>
              </div> : null}
              <InputFieldWithLabel propKey='client.nif' editable={!values.client?.id} direction="row" label="NIF">
                <CustomTextInput
                  value={values.client?.nif}
                  onChangeText={(text: string) => {
                    const sanitizedText = text.replace(/\D/g, '');
                    if (sanitizedText.length === 9) {
                      setErrors({
                        ...errors,
                        client: {
                          ...errors.client,
                          nif: ''
                        }
                      })
                    }
                    setFieldValue('client.nif', sanitizedText);
                  }}
                  keyboardType="numeric"
                  maxLength={9}
                  placeholder="NIF do cliente"
                />
              </InputFieldWithLabel>
              <InputFieldWithLabel propKey='client.name' editable={true} direction="row" label="Nome">
                <CustomTextInput
                  value={values.client?.name}
                  onChangeText={(text: string) => {
                    if (text.length > 2) {
                      setErrors({
                        ...errors,
                        client: {
                          ...errors.client,
                          name: ''
                        }
                      })
                    }
                    setFieldValue('client.name', text)
                  }}
                  placeholder="Nome do cliente"
                />
              </InputFieldWithLabel>

              <InputFieldWithLabel propKey='client.email' editable={true} direction="row" label="Email">
                <CustomTextInput
                  value={values.client?.email}
                  onChangeText={(text: string) =>
                    setFieldValue('client.email', text)
                  }
                  placeholder="Email do cliente"
                />
              </InputFieldWithLabel>
              <InputFieldWithLabel
                editable={true}
                direction="row"
                propKey='client.phone'
                label="Telefone">
                <CustomTextInput
                  value={values.client?.phone}
                  onChangeText={(text: string) => {
                    const sanitizedText = text.replace(/\D/g, '');
                    setFieldValue('client.phone', sanitizedText);
                  }}
                  keyboardType="phone-pad"
                  maxLength={9}
                  placeholder="Telefone do cliente"
                />
              </InputFieldWithLabel>

              <Locations />

              <div
                onClick={async () => {
                  handleConfirmAttempt();
                }}
                className="my-5">
                <CustomButton  onClick={() => null} >Confirmar</CustomButton>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}
