import React from "react";
import { Link } from "react-router-dom";
import DetailsList from "./DetailsList"; 

const RenderClients: React.FC<{ clients: any[] }> = ({ clients }) => (
  <div className="grid grid-cols-1 gap-4">
    {clients.length > 0 ? (
      clients.map((client) => (
        <Link
          key={client.id}
          to={`/clientes/${client.id}`}
          className="block p-4 border border-gray-300 rounded-lg shadow-md bg-white hover:bg-gray-100 transition"
        >
          <h2 className="text-xl font-bold">Client ID: {client.id}</h2>
          <p className="text-gray-700">Name: {client.name}</p>
          <p className="text-gray-700">Email: {client.email}</p>
          {client.matches.length > 0 && <DetailsList matches={client.matches} />}
        </Link>
      ))
    ) : (
      <p className="text-gray-500 text-center">No Clients found.</p>
    )}
  </div>
);

export default RenderClients;
