import { useState } from "react";

export function useTableState() {
  const [currentPage, setCurrentPage] = useState(1);
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  const toggleSortDirection = () => {
    setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
  };

  return {
    setCurrentPage,
    currentPage,
    sortDirection,
    toggleSortDirection,
  };
}
