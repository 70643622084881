import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Tooltip,
} from "@mui/material";
import { formatDate } from "../../../../utils";
import { ITEMS_PER_PAGE } from "../consts";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useToolList } from "../hooks/useToolList";
import CancelIcon from '@mui/icons-material/Cancel';

const ToolList = () => {
  const navigate = useNavigate();

  const {
    tools,
    totalCount,
    currentPage,
    isLoadingTools,
    setCurrentPage,
    toggleSortDirection,
    sortDirection,
  } = useToolList();

  const totalPages = Math.ceil(totalCount / ITEMS_PER_PAGE);
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
  console.log(tools);

  if (isLoadingTools) return <p>A carregar ferramentas...</p>;

  if (tools?.length === 0) {
    return (
      <div >
        <Typography variant="h5" gutterBottom>
          Lista de Ferramentas
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Não há ferramentas disponíveis de momento.
        </Typography>
      </div>
    );
  }

  return (
    <div>
      <div className="mb-4 mx-4">
        <button onClick={toggleSortDirection} className="text-default ">
          Ordenar por data {sortDirection === "asc" ? "▲" : "▼"}
        </button>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="body1" fontWeight="bold">
                  Referência
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" fontWeight="bold">
                  Nome
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" fontWeight="bold">
                  Fornecedor
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" fontWeight="bold">
                  Data
                </Typography>
              </TableCell>
              <TableCell align="center" sx={{ width: "10%" }}>
                <Tooltip title="Estado" arrow>
                  <CheckCircleIcon />
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tools?.map((tool: any) => (
              <TableRow
                key={tool.id}
                hover
                role="button"
                tabIndex={0}
                onClick={() => navigate(`/tool/${tool.id}`)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    navigate(`/tool/${tool.id}`);
                  }
                }}
                aria-label={`Ver detalhes de ${tool.name}`}
              >
                <TableCell component="th" scope="row">
                  {tool.reference}
                </TableCell>
                <TableCell>{tool.name}</TableCell>
                <TableCell>{tool.provider}</TableCell>
                <TableCell>{formatDate(new Date(tool.updatedAt))}</TableCell>
                <TableCell align="center">
                  {tool?.status === "available" ? (
                    <CheckCircleIcon style={{ color: "green" }} />
                  ) : (
                    <CancelIcon style={{ color: "red" }} />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="flex justify-end space-x-2 mt-4">
        <button
          className="bg-white border border-black text-black px-2 py-1 rounded-md hover:bg-gray-100 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          {"<"}
        </button>
        {pageNumbers.map((number: number) => (
          <button
            key={number}
            onClick={() => setCurrentPage(currentPage)}
            disabled={number === currentPage}
            className={`bg-white border border-black text-black px-2 py-1 rounded-md hover:bg-gray-100 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm ${
              number === currentPage ? "opacity-50 cursor-default" : ""
            }`}
          >
            {number}
          </button>
        ))}
        <button
          className="bg-white border border-black text-black px-2 py-1 rounded-md hover:bg-gray-100 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          {">"}
        </button>
      </div>
    </div>
  );
};

export default ToolList;
