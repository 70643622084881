export enum JWT_AUTH_ERRORS {
  INVALID_PAYLOAD = "INVALID_PAYLOAD",
  USER_NOT_FOUND = "USER_NOT_FOUND",
  INVALID_TOKEN = "INVALID_TOKEN",
  EXPIRED_TOKEN = "EXPIRED_TOKEN",
}

export enum SIGNUP_ERRORS {
  EMAIL_ALREADY_REGISTERED = "EMAIL_ALREADY_REGISTERED",
  PASSWORD_NOT_STRONG_ENOUGH = "PASSWORD_NOT_STRONG_ENOUGH",
}

export enum PASSWORD_RESET_ERRORS {
  INVALID_RESET_PASSWORD_VERIFICATION_TOKEN = "INVALID_RESET_PASSWORD_VERIFICATION_TOKEN",
  INVALID_EMAIL = "INVALID_EMAIL",
}

export enum SIGNIN_ERRORS {
  NO_AUTHORIZATION_HEADER = "NO_AUTHORIZATION_HEADER",
  INVALID_AUTH_SCHEME = "INVALID_AUTH_SCHEME",
  UNREGISTERED_EMAIL = "UNREGISTERED_EMAIL",
  INVALID_PASSWORD = "INVALID_PASSWORD",
  EXPIRED = "EXPIRED",
  BLOCKED = "BLOCKED",
}

export enum INTERNAL_SERVER_ERROR {
  ERROR_UPDATING_USER = "ERROR_UPDATING_USER",
  ERROR_SENDING_EMAIL = "ERROR_SENDING_EMAIL",
  ERROR_FETCHING_USER = "ERROR_FETCHING_USER",
}

export enum NOT_FOUND_ERRORS {
  NOT_FOUND_USER = "NOT_FOUND_USER",
  NOT_FOUND_EMAIL = "NOT_FOUND_EMAIL",
  NOT_FOUND_MEMBERSHIP = "NOT_FOUND_MEMBERSHIP"
}

export enum MEMBERSHIP_ERRORS {
  MEMBERSHIP_INVALID= "MEMBERSHIP_INVALID",
  MEMBERSHIP_ALREADY_USED = "MEMBERSHIP_ALREADY_USED",
}

export type ERROR_CODE =
  | JWT_AUTH_ERRORS
  | SIGNUP_ERRORS
  | PASSWORD_RESET_ERRORS
  | SIGNIN_ERRORS
  | INTERNAL_SERVER_ERROR
  | NOT_FOUND_ERRORS;
