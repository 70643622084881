import { ReactElement } from "react";



export function FormSectionWrapper({ label, children }: { label: string, children: ReactElement | ReactElement[] | null }) {


    return (
        <div className="mb-14 ">
            
            <div className={`text-xl font-bold `}>{label}</div>
            <div
            className="h-[6px] bg-primary mb-3 rounded-md"
            />
            {children}
        </div>
    )


}