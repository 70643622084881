import React from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import CustomButton from "../../../../components/buttons/Button";
import {
  ButtonSize,
  ButtonVariant,
} from "../../../../components/buttons/types";
import { CreateTool } from "../types";
import { useTool } from "./hooks/useTool";
import ToolActionSection from "./components/ToolActionSection";
import ToolActionsHistory from "./components/ToolActionHistory";
import ToolQRCode from "./components/ToolQRCode";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { EntityWrapper } from "../../../../components/EntityWrapper";

const ToolDetails: React.FC = () => {
  const { tool, isLoadingTool, updateToolMutation, downloadAttachment } =
    useTool();
  const navigate = useNavigate();
  const handleSubmit = async (values: CreateTool, files: File[]) => {
    updateToolMutation.mutate({
      toolData: values,
      id: tool?.tool?.id,
      files,
    });
  };

  const handleCancel = () => {
    navigate("/tool");
  };

  if (isLoadingTool) return <p>A carregar...</p>;
  if (!tool) return <p>Ferramenta não encontrada.</p>;

  return (
    <EntityWrapper title={tool?.tool?.name} parentRoute="tool">
      <>
        <h2 className="text-xl">

          {tool?.tool?.status === "available" ? (
            <div className="flex items-center">
              <CheckCircleIcon
                style={{
                  color: "green",
                  marginBottom: "0.25rem",
                  fontSize: "32px",
                }}
              />
              <div className="ml-2 font-bold">Disponível</div>
            </div>
          ) : (
            <div className="flex items-center">
              <CancelIcon
                style={{
                  color: "red",
                  marginBottom: "0.25rem",
                  fontSize: "32px",
                }}
              />

              <div className="ml-2 font-bold">Em utilização</div>
            </div>
          )}
        </h2>

        <div className="flex justify-end">
          {" "}
          <ToolActionSection toolId={tool?.tool?.id} />
          <span className="mr-2"></span>
          <ToolActionsHistory />
        </div>
        <Formik
          initialValues={
            {
              reference: tool?.tool?.reference,
              name: tool?.tool?.name,
              description: tool?.tool?.description || "",
              qrCode: tool?.tool?.qrCode,
              provider: tool?.tool?.provider || "",
              attachments: [],
            } as any
          }
          onSubmit={async (values, { setSubmitting }) => {
            const files = values.attachments || [];
            await handleSubmit(values, files);
            setSubmitting(false);
          }}
        >
          {({ setFieldValue, isSubmitting, values }) => (
            <Form>
              {/* Campos de formulário para editar os detalhes do Ferramenta */}
              <div className="mb-4">
                <label className="block text-sm font-medium">Referência</label>
                <Field
                  type="text"
                  name="reference"
                  className="mt-2 p-2 border w-full rounded"
                  placeholder="Referência da Ferramenta"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium">Nome</label>
                <Field
                  type="text"
                  name="name"
                  className="mt-2 p-2 border w-full rounded"
                  placeholder="Nome do Ferramenta"
                />
              </div>
              <ToolQRCode toolId={tool?.tool?.id} toolName={tool?.tool?.name} />
              <div className="mb-4">
                <label className="block text-sm font-medium">Descrição</label>
                <Field
                  as="textarea"
                  name="description"
                  className="mt-2 p-2 border w-full rounded"
                  placeholder="Descrição"
                  rows={4}
                />
              </div>

              <div className="mb-8">
                <label className="block text-sm font-medium">Fornecedor</label>
                <Field
                  type="text"
                  name="provider"
                  className="mt-2 p-2 border w-full rounded"
                  placeholder="Nome do Fornecedor"
                />
              </div>

              <div className="mb-4">
                <label className="block text-sm font-medium">Anexos</label>
                <input
                  type="file"
                  multiple
                  className="mt-2"
                  onChange={(e) => {
                    const files = Array.from(e.currentTarget.files || []);
                    setFieldValue("attachments", files);
                  }}
                />
              </div>
              <div className="mb-4">
                <h3 className="text-lg font-bold mb-2">Anexos Existentes</h3>
                {tool?.tool?.attachments && tool?.tool?.attachments.length > 0 ? (
                  <ul className="mx-6">
                    {tool?.tool?.attachments.map(
                      (attachment: any, index: any) => {
                        const fileName = attachment.path.split("/").pop();

                        return (
                          <li key={index} className="mb-1">
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                if (attachment.id !== undefined) {
                                  downloadAttachment(attachment.id);
                                } else {
                                  console.error("ID do anexo está indefinido");
                                }
                              }}
                              className="text-blue-600 hover:underline"
                            >
                              {fileName}
                            </button>
                          </li>
                        );
                      }
                    )}
                  </ul>
                ) : (
                  <p>Sem anexos disponíveis.</p>
                )}
              </div>

              <div className="flex justify-end space-x-2">
                <CustomButton
                  type="button"
                  className="mt-2"
                  variant={ButtonVariant.SECONDARY}
                  size={ButtonSize.SMALL}
                  onClick={handleCancel}
                >
                  Cancelar
                </CustomButton>

                <CustomButton
                  type="submit"
                  className="mt-2"
                  variant={ButtonVariant.PRIMARY}
                  size={ButtonSize.SMALL}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "A Atualizar..." : "Atualizar Ferramenta"}
                </CustomButton>
              </div>
            </Form>
          )}
        </Formik>
      </>
    </EntityWrapper>
  );
};

export default ToolDetails;
