export const sanitizeDecimalInput = (text: string): string => {
    // Replace commas with dots and allow only numbers and dots
    let sanitizedText = text.replace(',', '.').replace(/[^0-9.]/g, '');
  
    // Ensure only one decimal point is allowed
    if ((sanitizedText.match(/\./g) || []).length > 1) {
      sanitizedText = sanitizedText.replace(/\.(?=.*\.)/, ''); // Removes extra dots
    }
  
    return sanitizedText;
  };
  
export const sanitizeMinutesInput = (text: string): string => {
    // Remove non-numeric characters
    let sanitizedText = text.replace(/[^0-9]/g, '');
  
    // Convert string to a number and ensure it's between 0 and 1440
    let sanitizedNumber = parseInt(sanitizedText, 10);
    if (isNaN(sanitizedNumber)) sanitizedNumber = 0; // Ensure a number is there
  
    // Cap the value at 1440
    if (sanitizedNumber > 1440) {
      sanitizedNumber = 1440;
    }
  
    return sanitizedNumber.toString();
  };
  