import { useMutation } from "@tanstack/react-query";
import { createContext, useState } from "react";
import { IMaintenance } from "../@types";
import { deleteMaintenancePhoto, submitMaintenanceAnexos } from "../services/maintenances.service";



export const PhotosContext = createContext<{
    previewImage: { id: number, uri: string } | null;
    setPreviewImage: (image: { id: number, uri: string } | null) => void;
    submitPhotos: (photo: Photo) => void;
    deleteExistingPhoto: (photoId: number) => void;
}>({
    previewImage: null,
    setPreviewImage: () => null,
    submitPhotos: () => null,
    deleteExistingPhoto: () => null,
});


export interface Photo {
    id?: number;
    uri: string;
    type: string;
    name: string;
}


export function PhotosContextProvider({ maintenance, refetchMaintenance, children }: { maintenance: IMaintenance, refetchMaintenance: () => void, children: React.ReactElement }) {

    const [previewImage, setPreviewImage] = useState<{ id: number, uri: string } | null>(null);


   


    const { mutate: submitPhotosMutation, isPending: isSubmitting } = useMutation({
        mutationFn: ({ maintenanceId, formData }: { maintenanceId: number, formData: FormData }) => submitMaintenanceAnexos(maintenanceId, formData),
            onSuccess: () => {
                refetchMaintenance();
            },
            onError: (error: any) => {
                console.error('Upload failed', error.message);
            },
        },
    );

    const submitPhotos = async (photo: Photo) => {
        const formData = new FormData();

        formData.append('photos', {
            uri: photo.uri,
            type: photo.type,
            name: photo.name,
        } as any);
        console.log("Submitting with id; ", maintenance.id);

        submitPhotosMutation({ maintenanceId: maintenance.id, formData });
    };



    const deleteExistingPhoto = (photoId: number) => {
        deletePhotoMutation.mutate(photoId);
    };

    const deletePhotoMutation = useMutation({
            mutationFn: (photoId: number) => deleteMaintenancePhoto(photoId),
            onSuccess: () => {
                console.log('Success... Photo deleted successfully!');
                setPreviewImage(null);
                refetchMaintenance();
            },
            onError: (error: any) => {
                console.error('Delete failed', error);
            },
        },
    );



    return (
        <PhotosContext.Provider value={{
            previewImage,
            setPreviewImage,
            submitPhotos,
            deleteExistingPhoto
        }}>
            {children}
        </PhotosContext.Provider>
    )
}