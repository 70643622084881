import { useFormikContext } from "formik";
import { createContext, ReactElement } from "react";
import { getNestedPropByStringKey } from "../utils";


export const InputFieldContext = createContext<{
    editable: boolean,
    hasError: boolean
}>({
    editable: false,
    hasError: false
})




export function InputFieldWithLabel({
    label,
    children,
    className,
    direction = "col",
    editable,
    hideSeparator = false,
    propKey,
    info
}: {
    label: string,
    children: ReactElement,
    className?: string,
    direction?: "col" | "row",
    editable: boolean,
    hideSeparator?: boolean,
    propKey?: string,
    info?: ReactElement
}) {



    const { touched, errors } = useFormikContext<any>();


    const getError = (): string | null => {
        if (propKey) {
            
            return getNestedPropByStringKey(propKey, errors);
        }
        
        return null;
    }

    const isTouched = (): boolean => {
        if (propKey) {
            // if(propKey.split('.')[1] === 'locations') {
            //     console.log("PropKey: ", propKey);
            //     console.log("Touched: ", touched);
            //     console.log("=====> Error: ", getNestedPropByStringKey(propKey, touched));
            // }
            
            return getNestedPropByStringKey(propKey, touched);
        }
        return false;
    }

    const hasError =  !!(isTouched() && getError());


    return (
        <InputFieldContext.Provider value={{ editable, hasError }}>
            <div className={`${className}`}>
                <div
                    style={{
                        maxWidth: '100%'
                    }}
                    className={`${direction === "col" ? '' : 'flex my-1 flex-row items-center justify-between'} `}>
                    {direction === "row" ?
                        <div className="p-2 font-bold">{label}</div> :
                        <>
                            {info ?
                                <div className="flex flex-row items-center">
                                    <div className="p-2 font-bold mr-2">{label}</div>
                                    <div>info</div>
                                </div>
                                : <div className="p-2 font-bold">{label}</div>}
                        </>
                    }

                    <div className={`${direction === "row" ? "w-2/3 " : "w-full"} ${!editable && direction === "col" ? 'ml-2' : ''}`}>
                        {children}
                    </div>
                </div>
                {propKey ?
                    <div className="flex flex-row justify-end px-3">
                        {isTouched() && errors && propKey ? (
                            <div className="text-xs text-red-500">{getError() || ""}</div>
                        ) : null}

                    </div>
                    : null}
                {!editable && direction === "row" && !hideSeparator ? <div className={`mx-2 border-b-0.5 border-gray-400`}></div> : null}

            </div>


        </InputFieldContext.Provider>

    )


}