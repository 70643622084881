import { useMutation } from "@tanstack/react-query";
import { useTool } from "./useTool";
import { ToolActionType } from "../../enums";
import { performActionTool } from "../../../../../services/tool";

export const useToolActions = () => {
  const { refetchTool } = useTool();

  const performAction = useMutation({
    mutationFn: ({
      toolId,
      comment,
      action,
    }: {
      toolId: number;
      comment: string;
      action: ToolActionType;
    }) => performActionTool(toolId, comment, action),
    onSuccess: () => {
      refetchTool();
      alert("Action successful!");
    },
    onError: (error) => {
      alert(`Error during tool action: ${error}`);
    },
  });

  return {
    performAction,
  };
};
