import React, { useEffect, useRef, useState } from 'react';

import { CustomDropdownPicker } from '../../../../../components/CustomDropdownPicker';
import { FieldArray, FormikProps } from 'formik';
import { useQuery } from '@tanstack/react-query';

import { ClientModal, ClientNotSavedModal } from './ClientModal';
import { IClient, IMaintenance, Location, NifApiResponse } from '../../../../../@types';
import { getClientList, getNifInfo } from '../../../../../services/client/getClientList';
import { FormSectionWrapper } from '../../../../../components/FormSectionWrapper';
import { InputFieldWithLabel } from '../../../../../components/FieldWithLabel';
import { CustomTextInput } from '../../../../../components/CustomTextInput';
import { WarningModal } from '../../../../../components/WarningModal';
import { LatLngExpression } from 'leaflet';
import { Map } from '../../../../../components/Map';


export function ClientForm({
  formikProps,
  editable,
}: {
  formikProps: FormikProps<IMaintenance>;
  editable: boolean;
}) {
  const { values, setFieldValue } = formikProps;
  const [modalOpen, setModalOpen] = useState(false);
  const [showValues, setShowValues] = useState(!editable);
  const [clientExists, setClientExists] = useState<IClient | null>(null);
  const [clientNotSavedWarningModalOpen, setClientNotSavedWarningModalOpen] = useState(false);



  const { data: clients, isLoading } = useQuery<IClient[]>({
    queryKey: ['clients'],
    queryFn: getClientList,
  });

  const { data: clientData } = useQuery<NifApiResponse | IClient>({
    queryKey: ['clientInfo', values?.client?.nif],
    queryFn: () => getNifInfo(values?.client?.nif || ''),
    enabled: !!(values.client?.nif?.length === 9) && modalOpen,
  });

  useEffect(() => {
    const nif = values?.client?.nif as string;

    if (clientData && 'contacts' in clientData) {
      const nifValues = clientData[nif];
      setFieldValue('client.name', nifValues?.title || '');
      setFieldValue('client.email', nifValues?.contacts.email || '');
      setFieldValue('client.phone', nifValues?.contacts.phone || '');
    } else if (!values.client?.id) {
      setClientExists(clientData as IClient);
    }

  }, [clientData])

  const handleClientSelection = () => {
    if (clientExists) {
      setModalOpen(false);
      setFieldValue('client', clientExists);
      if (clientExists.locations?.length === 1) {
        setFieldValue('clientLocation', clientExists.locations[0]);
      } else {
        setFieldValue('clientLocation', {});
      }

      setShowValues(true);
      setClientExists(null);
    }
  };

  return (
    <>
      <ClientNotSavedModal
        isOpen={clientNotSavedWarningModalOpen}
        close={() => setClientNotSavedWarningModalOpen(false)}
        confirm={() => {
          setFieldValue('client', null)
          setFieldValue('clientLocation', null)
        }}
      />
      <FormSectionWrapper label="Cliente">
        <>
          {modalOpen ?
            <ClientModal
              open={modalOpen}
              close={() => setModalOpen(false)}
            />
            : null}


          {!isLoading ? (
            <>
              {!modalOpen && editable && !values?.client ? (
                <InputFieldWithLabel
                  editable={editable && !showValues}
                  label="Cliente existente">
                  <CustomDropdownPicker<IClient>
                    editable={editable}
                    value={values.client}
                    title='Clientes existentes'
                    setValue={(client: IClient | null) => {
                      setFieldValue('client', client);
                      if (client?.locations?.length === 1) {
                        setFieldValue('clientLocation', client.locations[0]);
                      } else {
                        setFieldValue('clientLocation', {});
                      }
                      setShowValues(true);
                    }}
                    options={clients || []}
                    labelFunction={(option: IClient) => {
                      if (option) return option.name;
                      return "";
                    }}
                  />
                </InputFieldWithLabel>
              ) : null}
            </>
          ) : (

            <div>Loading</div>
          )}

          {editable && !values?.client ? (
            <div className="flex flex-row w-full justify-end">
              <div
                onClick={() => {
                  setFieldValue('client', null);
                  setFieldValue('clientLocation', null);
                  setModalOpen(true);
                }}>
                <div className="p-2  underline font-bold text-primary">
                  Registar cliente
                </div>
              </div>
            </div>
          ) : null}

          {!modalOpen && values.client ? (
            <div

              className="border border-0.5 border-gray-400 rounded-md bg-white">
              <div

                className="p-2 flex flex-row w-full justify-between  rounded-t-md bg-primary">
                <div>
                  <div className="div-sm div-white">{'Dados cliente'}</div>
                </div>
                {editable ? (
                  <div className="flex flex-row items-center">
                    <div className="mr-2">
                      <div>Edit</div>

                    </div>
                    <div onClick={() => {
                      if (!values.client?.id) {
                        setClientNotSavedWarningModalOpen(true)
                      } else {
                        setFieldValue('client', null)
                        setFieldValue('clientLocation', null)
                      }

                    }}>Close</div>

                  </div>
                ) : null}
              </div>
              <InputFieldWithLabel
                editable={false}
                direction="row"
                label="NIF">
                <CustomTextInput
                  value={values.client?.nif}
                  onChangediv={(div: string) =>
                    setFieldValue('client.nif', div)
                  }
                  placeholder="NIF do cliente"
                />
              </InputFieldWithLabel>
              <InputFieldWithLabel
                editable={false}
                direction="row"
                label="Nome">
                <CustomTextInput
                  value={values.client?.name}
                  onChangediv={(div: string) =>
                    setFieldValue('client.name', div)
                  }
                  placeholder="Nome do cliente"
                />
              </InputFieldWithLabel>

              <InputFieldWithLabel
                editable={false}
                direction="row"
                label="Email">
                <CustomTextInput
                  value={values.client?.email}
                  onChangediv={(div: string) =>
                    setFieldValue('client.email', div)
                  }
                  placeholder="Email do cliente"
                />
              </InputFieldWithLabel>
              <InputFieldWithLabel
                editable={false}
                direction="row"
                label="Telefone">
                <CustomTextInput
                  value={values.client?.phone}
                  onChangediv={(div: string) =>
                    setFieldValue('client.phone', div)
                  }
                  placeholder="Telefone do cliente"
                />
              </InputFieldWithLabel>

              <InputFieldWithLabel
                editable={editable}
                direction="col"
                label={editable ? 'Localizações' : 'Localização da obra'}>
                <>
                  {editable ? (
                    <div className="div-xs pl-2">
                      (Seleccione a localização da obra em questão)
                    </div>
                  ) : null}
                  <div className="p-2">
                    {values?.client?.locations?.length
                      ? values?.client.locations
                        ?.filter((location: Location) => {
                          if (editable) return true;
                          return (
                            location.name === values?.clientLocation?.name
                          );
                        })
                        ?.map((location: Location, idx: number) => {
                          return (
                            <div className="mb-2" key={idx}>
                              <div className="flex flex-row justify-between items-center">
                                <div>
                                  <div className="font-bold">
                                    {location.name}
                                  </div>
                                  <div>Morada: {location.address}</div>
                                  <div>
                                    Código postal: {location.cp4}-
                                    {location.cp3}
                                  </div>
                                </div>
                                {editable ? (
                                  <input
                                    type='checkbox'
                                    disabled={!editable}
                                    checked={
                                      !!(
                                        (location.id &&
                                          values?.clientLocation?.id ===
                                          location.id) ||
                                        values?.clientLocation?.name ===
                                        location.name
                                      )
                                    }
                                    onChange={() =>
                                      setFieldValue(
                                        'clientLocation',
                                        location,
                                      )
                                    }
                                  />
                                ) : null}
                              </div>
                              {location.coordinates ? (
                                <div className="mt-3 mr-4 border-0.5 border-gray-400 rounded-lg overflow-hidden flex w-fit items-center">
                                  <Map
                                    coords={[
                                      location.coordinates.latitude || 51.505,
                                      location.coordinates.longitude || -0.09,
                                    ] as LatLngExpression}
                                    popupLabel={location.name}
                                  />
                                </div>
                              ) : null}
                            </div>
                          );
                        })
                      : null}
                  </div>
                </>
              </InputFieldWithLabel>
            </div>
          ) : null}
        </>
        <InputFieldWithLabel editable={editable} label="Nome do responsável">
          <CustomTextInput
            value={values.content.responsiblePersonName}
            onChangediv={(div: string) =>
              setFieldValue('content.responsiblePersonName', div)
            }
            placeholder="Nome do responsável"
            editable={editable}
          />
        </InputFieldWithLabel>
      </FormSectionWrapper>
      <WarningModal
        isOpen={!!clientExists}
        close={() => setClientExists(null)}
        cancel={() => {
          setClientExists(null)
          setFieldValue('client.nif', null)
        }}
        mainText={`Cliente ${clientExists?.name}`}
        description={`O NIF introduzido pertence a um cliente já registado, pretende carregar os dados deste cliente?`}
        onConfirm={handleClientSelection}
      />

    </>
  );
}