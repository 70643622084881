import { Route, Routes } from "react-router-dom";
import { useInterceptorResponse, useInterceptRequest } from "./hooks/useInterceptor";
import { useAuth } from "./contexts/AuthContext";
import { WrapperGuard } from "./guards/WrapperGuard";
import Public from "./pages/public";
import { IRoute } from "./@types";
import { routes } from "./routes";
import { SideBar } from "./components/SideBar";
import { Topbar } from "./components/Topbar/Topbar";
import { Onboarding } from "./pages/private/Onboarding/Onboarding";




export function AppContainer() {
  const { loggedUser, logoutUser, softLogoutUser } = useAuth();

  useInterceptRequest(loggedUser);
  useInterceptorResponse(loggedUser, logoutUser, softLogoutUser);
  return (
    <WrapperGuard
      accessCondition={!!loggedUser}
      guardView={<Public />}
      dependencies={[loggedUser]}
    >
         <WrapperGuard
          guardView={<Onboarding />}
          accessCondition={!!loggedUser?.onboarded}
          dependencies={[!!loggedUser?.onboarded]}
        >
      {/* <Navbar /> */}
      <div className="flex w-full h-full flex-1 bg-Grey-light">
        <SideBar />
        <div className="flex flex-col h-full w-full">
          <Topbar />

          {/* <div className="flex w-full bg-white p-3 py-0 pt-1 border-b">
              <BreadCrumbs />
            </div> */}

          {/* <div className="h-1 bg-grey-background flex w-full"></div> */}
          <div className="p-3 h-full max-h-screen overflow-y-auto bg-white shadow-md  rounded-tl-lg">

            <>
              <Routes>
                {routes.map((prop: IRoute, key: number) => (
                  <Route
                    path={prop.path}
                    element={prop.element}
                    key={key}
                  />
                ))}
              </Routes>
            </>

          </div>
        </div>
      </div>
      </WrapperGuard>
    </WrapperGuard>
  );
}
