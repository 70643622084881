import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Tooltip,
} from "@mui/material";
import { Inventory2, ShoppingCart, Pending, Undo } from "@mui/icons-material";
import { useStockList } from "../hooks/useStockList";
import { formatDate } from "../../../../utils";
import { ITEMS_PER_PAGE } from "../consts";


const StockList = () => {
  const navigate = useNavigate();

  const {
    stocks,
    totalCount,
    currentPage,
    isLoadingStocks,
    setCurrentPage,
    toggleSortDirection,
    sortDirection,
  } = useStockList();

  const totalPages = Math.ceil(totalCount / ITEMS_PER_PAGE);
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);


  if (isLoadingStocks) return <p>A carregar stocks...</p>;

  if (stocks?.length === 0) {
    return (
      <div className="p-4">
        <Typography variant="h5" gutterBottom>
          Lista de Stock
        </Typography>
        <Typography variant="body1" color="textSecondary">
        Não há stocks disponíveis de momento.
        </Typography>
      </div>
    );
  }

  return (
    <div>
      <div className="mb-4 mx-4">
        <button onClick={toggleSortDirection} className="text-default ">
        Ordenar por data {" "} {sortDirection === "asc" ? "▲" : "▼"}
        </button>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="body1" fontWeight="bold">
                  Referência
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" fontWeight="bold">
                  Nome
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" fontWeight="bold">
                  Fornecedor
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body1" fontWeight="bold">
                  Data
                </Typography>
              </TableCell>
              <TableCell align="center" sx={{ width: "5%" }}>
                <Tooltip title="Quantidade Total" arrow>
                  <Inventory2 />
                </Tooltip>
              </TableCell>
              <TableCell align="center" sx={{ width: "5%" }}>
                <Tooltip title="Stock Disponível" arrow>
                  <ShoppingCart />
                </Tooltip>
              </TableCell>
              <TableCell align="center" sx={{ width: "5%" }}>
                <Tooltip title="Reservado Pendente" arrow>
                  <Pending />
                </Tooltip>
              </TableCell>
              <TableCell align="center" sx={{ width: "5%" }}>
                <Tooltip title="Devoluções Pendentes" arrow>
                  <Undo />
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stocks?.map(({ stock, metrics }: any) => (
              <TableRow
                key={stock.id}
                hover
                role="button"
                tabIndex={0}
                onClick={() => navigate(`/stock/${stock.id}`)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    navigate(`/stock/${stock.id}`);
                  }
                }}
                aria-label={`Ver detalhes de ${stock.name}`}
              >
                <TableCell component="th" scope="row">
                  {stock.reference}
                </TableCell>
                <TableCell>{stock.name}</TableCell>
                <TableCell>{stock.provider}</TableCell>
                <TableCell>{formatDate(new Date(stock.updatedAt))}</TableCell>
                <TableCell align="center">{metrics.totalStock}</TableCell>
                <TableCell align="center">{metrics.availableStock}</TableCell>
                <TableCell align="center">{metrics.reservedPending}</TableCell>
                <TableCell align="center">{metrics.pendingReturns}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className="flex justify-end space-x-2 mt-4">
        <button
          className="bg-white border border-black text-black px-2 py-1 rounded-md hover:bg-gray-100 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          {"<"}
        </button>
        {pageNumbers.map((number: number) => (
          <button
            key={number}
            onClick={() => setCurrentPage(currentPage)}
            disabled={number === currentPage}
            className={`bg-white border border-black text-black px-2 py-1 rounded-md hover:bg-gray-100 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm ${
              number === currentPage ? "opacity-50 cursor-default" : ""
            }`}
          >
            {number}
          </button>
        ))}
        <button
          className="bg-white border border-black text-black px-2 py-1 rounded-md hover:bg-gray-100 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          {">"}
        </button>
      </div>
    </div>
  );
};

export default StockList;
