import { ReactElement, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { useOnPageMount } from "../../../hooks/useOnPageMount";
import { PersonalInformation } from "./Profile";
import { Settings } from "./Settings";
import { Logout } from "./Logout";
import { MyAreaTitle } from "./components/MyAreaTitle";
import { ReactComponent as ProfileInactiveIcon } from "../../../assets/myArea/profile-inactive.svg";
import { ReactComponent as ProfileActiveIcon } from "../../../assets/myArea/profile-active.svg";
import { ReactComponent as SettingsActive } from "../../../assets/myArea/settings-active.svg";
import { ReactComponent as SettingsInactive } from "../../../assets/myArea/settings-inactive.svg";
import { ReactComponent as SignoutIcon } from "../../../assets/myArea/sign-out.svg";
import { Tabs } from "./components/Tabs";


export type MenuSection = {
  name: string;
  iconActive?: ReactElement;
  iconInactive?: ReactElement;
  subMenuComponent?: ReactElement;
  route: string;
  title?: string;
};

export type MenusObject = {
  [key: string]: MenuSection[];
};

const menus: MenusObject = {
  "my-profile": [
    {
      name: "personal-information",
      iconInactive: <ProfileInactiveIcon />,
      iconActive: <ProfileActiveIcon />,
      route: "",
      title: "profile.title",
    }
  ],
  preferences: [
    {
      name: "settings",
      iconActive: <SettingsActive />,
      iconInactive: <SettingsInactive />,
      route: "settings",
      title: "tabs.settings",
    },
  ],
  login: [
    {
      name: "sign-out",
      iconInactive: <SignoutIcon />,
      iconActive: <SignoutIcon />,
      route: "tabs.sign-out",
    },
  ],
};

const UserLayout = () => {
  const { user } = useContext(AuthContext);

  return (
    <div className="md:px-4 pb-0 md:pt-6">
      <div className="flex items-center bg-Yellow-light rounded-md h-20">
        

        <div className="flex flex-col w-4/5 px-4 pl-0">
          <div className="font-bold mb-1">
            {user?.companyName}
          </div>
         
        </div>
      </div>
    </div>
  );
};

export function MyArea() {
  const { t } = useTranslation("myArea");

  useOnPageMount();
  const location = useLocation();
  const activeLocation = location.pathname.split("/")[2] || "";

  const isMobileView = window.innerWidth < 1024;

  const menuToActivate = Object.values(menus)
    .flatMap((m) => m)
    .filter((m) => m.route === activeLocation)[0];

  const [activeMenu, setActiveMenu] = useState<MenuSection | null>(
    menuToActivate
  );

  const [mobileActiveMenu, setMobileActiveMenu] = useState<boolean>(false);


  useEffect(() => {
    if (!mobileActiveMenu && isMobileView) setActiveMenu(null);
  }, [mobileActiveMenu, isMobileView]);

  useEffect(() => {
    if (location.pathname === "/profile/settings") {
      setActiveMenu(menus["preferences"][0]);
      setMobileActiveMenu(true)
    }
  }, [location.pathname ]);

  const currentComp =
    location.pathname.split("/")[location.pathname.split("/").length - 1];

  const getProfilePage = () => {

    switch (activeMenu) {
      case menus["my-profile"][0]:
        return <PersonalInformation />;
      case menus["preferences"][0]:
        return <Settings />;
      case menus["login"][0]:
        return <Logout />;
      default:
        return <PersonalInformation />;
    }
  };

  return (
    <div className="w-full">
      {/* MOBILE BREAKPOINT */}
      <div className="lg:hidden px-6 py-4 md:px-9 ">
        {!mobileActiveMenu ? (
          <div className="w-full">
            <UserLayout />
            <Tabs
              menus={menus}
              activeMenu={activeMenu}
              setActiveMenu={setActiveMenu}
              setMobileActiveMenu={setMobileActiveMenu}
            />
          </div>
        ) : (
          <div className="w-full">
            <div className="flex items-center mb-9">
             
              <MyAreaTitle
                className="text-center -ml-1 w-full"
                title={t(
                  `${
                    activeMenu?.subMenuComponent
                      ? currentComp.split("-").join(" ")
                      : activeMenu?.title
                  }`
                )}
              />
            </div>
            {getProfilePage()}
          </div>
        )}
      </div>
      {/* AFTER MD BREAKPOINT */}
      <div className="hidden lg:flex py-6 px-9">
        <div className="w-1/4">
          <UserLayout />
          <Tabs
            menus={menus}
            activeMenu={activeMenu}
            setActiveMenu={setActiveMenu}
            setMobileActiveMenu={() => null}
          />
        </div>
        <div className="w-1/2 p-5">{getProfilePage()}</div>
      </div>
    </div>
  );
}
