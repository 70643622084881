import React, { useState } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import CustomButton from "../../../../../components/buttons/Button";
import {
  ButtonSize,
  ButtonVariant,
} from "../../../../../components/buttons/types";
import { QRCodeCanvas } from "qrcode.react";
interface ToolQRCodeProps {
  toolId: number;
  toolName: string;
}

const ToolQRCode: React.FC<ToolQRCodeProps> = ({ toolId, toolName }) => {
  const [showModal, setShowModal] = useState(false);
  const [size, setSize] = useState(128);

  const handleDownload = async () => {
    const pdf = new jsPDF("p", "pt", "a4", true);
    const qrCodeElement = document.getElementById("qrCodePreview");

    if (qrCodeElement) {
      const canvas = await html2canvas(qrCodeElement);
      const imgData = canvas.toDataURL("image/png");

      pdf.addImage(imgData, "PNG", 10, 10, size, size, undefined, "FAST");
      pdf.save(`${toolName}-qrcode.pdf`);
    }
    setShowModal(false);
    setSize(128);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSize(128);
  };

  return (
    <div className="mb-4">
      <label className="block text-sm font-medium">Código QR</label>
      <div className="mt-2">
        {/* Display the QR code */}
        <div>
          <div className="mx-4">
            <QRCodeCanvas value={toolId.toString()} size={size} />
          </div>
          <CustomButton
            type="button"
            className="mr-2 mt-4"
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.SMALL}
            onClick={() => setShowModal(true)}
          >
            Download Código QR (.pdf)
          </CustomButton>
        </div>
      </div>

      {/* Modal for size input */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-5 rounded shadow-lg">
            <h2 className="text-lg font-medium mb-2">
              Personalizar Tamanho do Código QR
            </h2>
            <div>
              <label className="block text-sm mb-1">Dimensão (px)</label>
              <input
                type="number"
                value={size}
                onChange={(e) => {
                  const newSize = parseInt(e.target.value) || 50;
                  setSize(Math.min(newSize, 500));
                }}
                className="border p-1 w-full mb-4"
                min={50}
                max={500}
              />
              <div className="mb-6">
                <p className="mb-2">Pré-visualização:</p>
                <div
                  id="qrCodePreview"
                  className="flex justify-center items-center "
                  style={{ width: size, height: size }}
                >
                  <QRCodeCanvas value={toolId.toString()} size={size} />
                </div>
              </div>
              <div className="flex justify-end ">
                <CustomButton
                  type="button"
                  className="mr-2"
                  variant={ButtonVariant.SECONDARY}
                  size={ButtonSize.SMALL}
                  onClick={handleCloseModal}
                >
                  Cancelar
                </CustomButton>
                <CustomButton
                  type="button"
                  className="mr-2"
                  variant={ButtonVariant.PRIMARY}
                  size={ButtonSize.SMALL}
                  onClick={handleDownload}
                >
                  Download
                </CustomButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ToolQRCode;
