import "./index.css"
import { ButtonProps, ButtonSize, ButtonVariant } from "./types"

const CustomButton = ({ className = "", disabled, onClick, children, type, variant, size = ButtonSize.SMALL }: ButtonProps) => {
    const buttonVariant = 
        variant === ButtonVariant.PRIMARY ? "btn-primary text-white" : 
        variant === ButtonVariant.SECONDARY ? "btn-secondary " : 
        variant === ButtonVariant.GHOST_LIGHT ? "btn-ghost-light" : 
        variant === ButtonVariant.GHOST_DARK ? "btn-ghost-dark" : 
        ""

    const buttonSize = 
        size === ButtonSize.MEDIUM ? "btn-medium" : 
        size === ButtonSize.SMALL ? "btn-small" :
        ""

    const classNames = `btn ${buttonVariant} ${className} ${buttonSize} `

    return (
        <button
            type={type}
            disabled={disabled}
            className={classNames}
            onClick={onClick}
        >
            {children}
        </button>
    )
}

export default CustomButton