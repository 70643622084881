import { AddIcon } from "../../../assets/icons/AddIcon";
import CustomButton from "../../../components/buttons/Button";
import { ButtonSize, ButtonVariant } from "../../../components/buttons/types";
import { PrivatePageWrapper } from "../../../components/PrivatePageWrapper";
import { IClient } from "../../../@types";
import { useNavigate } from "react-router-dom";
import { ListItemInfoWrapper } from "../Maintenances/MaintenanceList/MaintenanceListItem";
import { useContext } from "react";
import { ClientsContext, ClientsContextProvider } from "../../../contexts/ClientsContext";
import { NewClient } from "./NewClient";



function ClientsContent() {

    const navigate = useNavigate();

    const { companyClients, setNewClientDialogOpen } = useContext(ClientsContext);



    return (
        <PrivatePageWrapper title="Clientes" headerButtons={<CustomButton
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.SMALL}
            onClick={() => {
                console.log("Adding client...")
                setNewClientDialogOpen(true)
            }}
        >
            {/* <div className="flex items-center">
                <AddIcon /> */}
                <div >Adicionar cliente</div>
            {/* </div> */}

        </CustomButton>}>
        
            <NewClient/>

            <div className="flex flex-col">
                {companyClients?.map((client: IClient) => {
                    return <div
                        key={client.id}
                        className='flex flex-col w-full my-3 transition-all duration-300 bg-white shadow hover:shadow-lg cursor-pointer rounded-lg'
                        style={{
                            position: 'relative'
                        }}
                        onClick={() => navigate(`/clientes/${client.id}`)}
                    >
                        <div className="flex w-full bg-primary rounded-t-lg p-3">
                            <div className="text-white font-bold">{client.name}</div>
                        </div>
                        <div className="p-3">

                            <ListItemInfoWrapper
                                label={'Email'}
                                value={`${client.email}`} />
                            <ListItemInfoWrapper
                                label={'Morada'}
                                value={`${client.address}`} />
                            <ListItemInfoWrapper
                                label={'Contacto'}
                                value={`${client.phone}`} />
                            <ListItemInfoWrapper
                                label={'NIF'}
                                value={`${client.nif}`} />
                            <ListItemInfoWrapper
                                label={'Localizações'}
                                value={`${client.locations?.length || 0}`} />

                        </div>




                    </div>
                })}
            </div>
        </PrivatePageWrapper>
    );
}


export function Clients() {


    return (
        <ClientsContextProvider>
            <ClientsContent />
        </ClientsContextProvider>
    )

}