export enum Role {
    ADMIN = "ADMIN",
    COMPANY = "COMPANY",
    EMPLOYEE = "EMPLOYEE",
  }
  
  
  export enum MaintenanceStatus {
    SCHEDULED = "SCHEDULED",
    STARTED = "STARTED",
    CREATED = "CREATED",
    SUBMITTED = "SUBMITTED",
    BILLED = "BILLED",
  }
  
  
  export enum TrialStatus {
    STARTED = "STARTED",
    ENDED = "ENDED",
  }
  
  export enum MaintenanceType {
    SPORADIC = "Esporádica",
    PREVENTIVE = "Preventiva",
    PERMANENT = "Permanente",
  }
  
  
  export enum MaintenanceRememberMePeriod {
    THREE_MONTHS = 3,
    SIX_MONTHS = 6,
    TWELVE_MONTHS = 12
  }