import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthContextProvider } from "./contexts/AuthContext";
import { AppContainer } from "./AppContainer";
import { BrowserRouter as Router } from 'react-router-dom';
import "leaflet/dist/leaflet.css"
import "react-datepicker/dist/react-datepicker.css";

const queryClient = new QueryClient();

function App() {
  return (
    <div id="App" className='flex w-full h-screen max-h-screen overflow-hidden'>
      <QueryClientProvider client={queryClient}>
        <AuthContextProvider>
          <Router>
            <AppContainer />
          </Router>
        </AuthContextProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
