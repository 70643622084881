import { useMutation, useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";


import { IMaintenance } from "../../../../@types";
import { MaintenanceRememberMePeriod, MaintenanceStatus } from "../../../../enums";
import { MaintenanceListContext } from "../../../../contexts/MaintenanceListContext";
import { useLocation } from "react-router-dom";
import { createMaintenance, getMaintenance, markMaintenanceAsSent, patchMaintenance, rememberMaintenance, submitMaintenance } from "../../../../services/maintenances.service";


export function useMaintenanceForm() {

const location = useLocation();

    const maintenanceId = parseInt(location.pathname.split('/').pop() || '0');
    console.log("MaintenanceId: ", maintenanceId);

    const { refetchMaintenanceList } = useContext(MaintenanceListContext);

    
    const [validating, setValidating] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const [billingOpen, setBillingOpen] = useState(false);

    const [photosOpen, setPhotosOpen] = useState(false);

    const [rememberInOpen, setRememberInOpen] = useState(false);



    const { data : maintenance, isLoading, refetch: refetchMaintenance } = useQuery<IMaintenance | null>({
        enabled: !!maintenanceId,
        queryKey: ['maintenance', maintenanceId],
        queryFn: () => getMaintenance(maintenanceId as any),
       
    })



    const createFormMutation = useMutation({
        mutationFn: createMaintenance,
        onError: ({ response }: any) => {

        },
    });

    const patchMaintenanceMutation = useMutation<
        any,
        unknown,
        IMaintenance
    >({
        mutationFn: (data: any) => patchMaintenance(data),
        // onSuccess: () => {
        //     setMaintenance(null)
        //     refetchMaintenance();
        //     refetchMaintenanceList();
        //     Toast.show({
        //         type: 'success',
        //         text1: 'Folha de obra guardada com sucesso',
        //     })
        // },
        onError: (error: any) => {
            console.error('Error updating form:', error);
        },
    });


    const submitMaintenanceMutation = useMutation<
        any,
        unknown,
        IMaintenance
    >({
        mutationFn: submitMaintenance,
        // onSuccess: (data: IMaintenance) => {
        //     setMaintenanceId(data?.id);
        //     refetchMaintenance();
        //     refetchMaintenanceList();
        //     setValidating(false);
        //     setSubmitting(false);
        //     Toast.show({
        //         type: 'success',
        //         text1: 'Folha de obra submetida com sucesso',
        //     })
        // },
        onError: (error: any) => {
            setSubmitting(false);
            console.error('Error submitting form:', error);
        },
    });

    const rememberMaintenanceMutation = useMutation({
        mutationFn: rememberMaintenance,
        onSuccess: () => {
            refetchMaintenanceList();
            refetchMaintenance();
         
        }
    });

    const markSentMutation = useMutation({
        mutationFn: markMaintenanceAsSent,
        onSuccess: () => {
            refetchMaintenanceList();
            refetchMaintenance();
            // Toast.show({
            //     type: 'success',
            //     text1: 'Manutenção marcada como enviada com sucesso',
            // })
        }
    })



    const saveMaintenance = (data: IMaintenance) => {
        if (data.id) {
            patchMaintenanceMutation.mutate(data);
        } else {
            createFormMutation.mutate(data);
        }
    }

    const submit = (data: IMaintenance) => {
        setSubmitting(true);
        submitMaintenanceMutation.mutate(data);
    }

    const rememberMaintenanceIn = (rememberMeIn: MaintenanceRememberMePeriod | null) => {
        if (!maintenanceId) return;
        rememberMaintenanceMutation.mutate({
            maintenanceId,
            rememberMeIn
        });
    }

    const markMaintenanceSent = (maintenanceId: number) => {
        markSentMutation.mutate({
            maintenanceId
        })
    }



    return {
        isLoading,
        maintenance,
        saveMaintenance,
        submitMaintenance: submit,
        validating,
        setValidating,
        submitting,
        billingOpen,
        setBillingOpen,
        refetchMaintenance,
        setPhotosOpen,
        photosOpen,
        setRememberInOpen,
        rememberInOpen,
        rememberMaintenanceIn,
        markMaintenanceSent
    }

}