import { Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { useFormikContext } from "formik"
import { IClient, IMaintenance } from "../../../../../@types";
import { SchedulingStatus } from ".";




export function ClientPicker({ clients }: { clients: Array<IClient> }) {

    const { values, setFieldValue } = useFormikContext<IMaintenance & { scheduledDateTime: Date, schedulingStatus: SchedulingStatus, timezone: string, client: IClient }>();


    return (
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Cliente</InputLabel>
            <Autocomplete
                disablePortal
                options={clients}
                getOptionKey={(option) => option.id}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <TextField {...params} label="Cliente" />}
                onChange={(e, client) => {
                    setFieldValue('client', client)
                }}
            />
            {/* <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={values.client.id}
                label="Cliente"
                name="client"
                onChange={(e) => {
                    const client = clients?.find(client => client.id === e.target.value)
                    setFieldValue('client', client)
                }}
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: 200,  // Set the maximum height for the dropdown
                        },
                    },
                }}
            >
                {clients?.map(client => (
                    <MenuItem key={client.id} value={client.id}>{client.name}</MenuItem>
                ))}

            </Select> */}
        </FormControl>
    )


}