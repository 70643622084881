import { IMaintenance } from "../../../../../@types";
import ExistingPhotoComponent from "./ExistingPhotoComponent";




export function Album({ maintenance }: { maintenance: IMaintenance }) {


    return (
        <>
            <div className="text-lg font-bold mb-3">Álbum</div>
            <div>
                {maintenance?.anexos?.map((item) => <ExistingPhotoComponent
                    key={item.id}
                    photo={item}
                />)}
            </div>
            


        </>
    )


}
