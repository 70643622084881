import { ReactElement, useContext, useState } from "react";
import { IClient, IEmployee, IMaintenance } from "../../../../../@types";

import { MaintenanceStatus, Role } from "../../../../../enums";
import { WarningModal } from "../../../../../components/WarningModal";
import { useAuth } from "../../../../../contexts/AuthContext";



export function ClientInfoMissingWarningModal({
  close,
  isOpen,
  confirm,
  client,
}: {
  close: () => void,
  isOpen: boolean,
  confirm: () => void,
  client: IClient
}) {

  return (
    <WarningModal
      isOpen={isOpen}
      close={close}
      onConfirm={confirm}
      mainText={!client ? 'Nenhum cliente associado' : 'Nenhuma localização do cliente selecionada'}
      description={!client ? 'A folha de obra não pode ser submetida sem um cliente associado, por favor retorne ao topo do formulário e seleccione o cliente em questão' :
        'Não foi selecionada nenhuma localização do cliente, por favor retorne ao topo do formulário e seleccione a localização do cliente em questão'}
    />
  )


}


function ActionButton({
  onPress,
  label,
  icon,
  disabled,
  disabledInfo
}: {
  onPress: () => void;
  label: string;
  icon?: ReactElement;
  disabled?: boolean;
  disabledInfo?: string
}) {


  return (
    <div


      className={`p-3 px-3 my-2 rounded-full flex flex-row items-center justify-center shadow-md ${disabled ? "bg-gray-400" : 'bg-primary'}`}
      onClick={onPress}>
      {icon}
      <div className="ml-2 font-bold text-white">{label}</div>
      {/* {disabled && disabledInfo ?
        // <div className="ml-2">
        //   <InfoCircle
        //     size={18}
        //     content={<div>De modo a poder adicionar fotografias guarde primeiro a folha de obra</div>}
        //   />
        // </div>
        : null} */}

    </div>
  );
}

export function ActionMenu({
  normalFieldsEditable,
  validationFieldsEditable,
  saveMaintenance,
  setValidating,
  setActionsOpen,
  scrollRef,
  values,
  maintenance,
  openBilling,
  openPhotos,
  openRememberIn,
  markMaintenanceSent
}: {
  normalFieldsEditable: boolean;
  validationFieldsEditable: boolean;
  saveMaintenance: (values: IMaintenance) => void;
  setValidating: (validating: boolean) => void;
  setActionsOpen: (actionsOpen: boolean) => void;
  scrollRef: any;
  values: IMaintenance;
  maintenance: IMaintenance;
  openBilling: () => void;
  openPhotos: () => void;
  openRememberIn: () => void;
  markMaintenanceSent: (maintenanceId: number) => void
}) {

  const { loggedUser } = useAuth();

  // const { downloading, downloadMaintenanceReport } = useMaintenanceDownloader();

  const [warningModalOpen, setWarningModalOpen] = useState(false);

  const canBill = (loggedUser as IEmployee).canBill;


  const gotoValidation = () => {
    setValidating(true);
    scrollRef.current?.scrollTo({
      y: 0,
      animated: true,
    });
    setActionsOpen(false);
  }

  return (
    <>
      <ClientInfoMissingWarningModal
        close={() => setWarningModalOpen(false)}
        isOpen={warningModalOpen}
        confirm={() => {
          setWarningModalOpen(false);
          setActionsOpen(false);
          scrollRef.current?.scrollTo({
            y: 0,
            animated: true,
          });
        }}
        client={values.client}
      />
      {/* {downloading ? <SpinnerScreen text="Downloading..." /> : null} */}
      <div className="flex w-full" >
        {normalFieldsEditable ? (
          <ActionButton
            onPress={() => {
              saveMaintenance(values);
              setActionsOpen(false);
            }}
            label="Guardar"
          />
        ) : null}

        {normalFieldsEditable ? (
          <ActionButton
            onPress={() => {
              if (!values.client || !Object.keys(values.clientLocation).length) {
                setWarningModalOpen(true);
                return;
              }
              gotoValidation();
            }}
            label="Validar"
          />
        ) : null}

        {validationFieldsEditable ? (
          <ActionButton
            onPress={() => {
              setValidating(false);
              setActionsOpen(false);
            }}
            label="Editar"
          />
        ) : null}

        {maintenance.status !== MaintenanceStatus.CREATED ? (
          <ActionButton
            onPress={() => {
              setActionsOpen(false);
              // downloadMaintenanceReport(maintenance.id);
            }}
       
            label="Download"
          />
        ) : null}

        {maintenance.status === MaintenanceStatus.CREATED ? (
          <ActionButton
            onPress={() => {
              setActionsOpen(false);
              openPhotos();
            }}
            label="Fotos"
            disabled={!maintenance.id}
            disabledInfo={"De modo a poder adicionar fotos primeiro guarde a folha de manutenção"}
          />
        ) : null}

        {maintenance.status !== MaintenanceStatus.CREATED && !maintenance.sentToClient ? (
          <ActionButton
            onPress={() => {
              setActionsOpen(false);
              markMaintenanceSent(maintenance.id)
            }}
            label="Marcar como enviado"
          />
        ) : null}

        {maintenance.status !== MaintenanceStatus.CREATED ? (
          <ActionButton
            onPress={() => {
              openRememberIn();
              setActionsOpen(false);
            }}
          
            label="Lembrar"
          />
        ) : null}

        {maintenance.status === MaintenanceStatus.SUBMITTED &&
          canBill ? (
          <ActionButton
            onPress={() => {
              openBilling();
              setActionsOpen(false);
            }}
         
            label="Faturar"
          />
        ) : null}
      </div>
    </>
  );
}