import { useQuery } from "@tanstack/react-query";
import { CalendarIcon } from "../../../../assets/icons/CalendarIcon";
import { IMaintenance } from "../../../../@types";
import { getSchedulingForObra } from "../../../../services/obra";



export function ObraScheduling({obra} : {obra: IMaintenance}) {

    const {data} = useQuery({
        queryKey: ['obraScheduling', obra.id],
        queryFn: ()=>getSchedulingForObra(obra.id),
    })

    console.log("DATA: ", data);

    return (
        <div className="flex items-center mb-4 border-b pb-2">
            <CalendarIcon color="orange" size={40} />
            <div className="ml-2">Manutenção agendada para dia: {new Date(data?.scheduling?.scheduledDateTime).toLocaleDateString()}-{new Date(data?.scheduling?.scheduledDateTime).toLocaleTimeString()}</div>
        </div>
    )

}