import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { IClient } from "../../../../@types";
import { getClient } from "../../../../services/client/getClientList";

import { ClientForm } from "./Form/ClientForm";
import { EntityWrapper } from "../../../../components/EntityWrapper";

export function Client() {
    const location = useLocation();

    const clientId = parseInt(location.pathname.split('/').pop() || '0');


    const { data: client, isLoading } = useQuery<IClient>({
        enabled: !!clientId,
        queryKey: ['client', clientId],
        queryFn: () => getClient(clientId as any),
    });

    if(isLoading) return null;

    return (
        <EntityWrapper
            title={client?.name || ""}
            parentRoute="clientes"
        >
           <ClientForm client={client} /> 

        </EntityWrapper>
    )


}