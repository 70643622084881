import { useMemo } from 'react';
import * as Yup from 'yup';


export const EmployeeValidationSchema = Yup.object().shape({
    passwordEditable: Yup.boolean(),
    firstName: Yup.string().required(
        'O primeiro nome do utilizador é obrigatório',
    ),
    lastName: Yup.string().required(
        'O último nome do utilizador é obrigatório',
    ),
    email: Yup.string()
        .email('O email deve ser válido')
        .required('O email é obrigatório'),
    password: Yup.string().when('passwordEditable', {
        is: true,
        then: ()=> Yup.string()
            .required('A palavra-passe é obrigatória')
            .min(8, 'A palavra-passe deve ter pelo menos 8 caracteres')
            .matches(
                /[A-Z]/,
                'A palavra-passe deve conter pelo menos uma letra maiúscula',
            )
            .matches(/[0-9]/, 'A palavra-passe deve conter pelo menos um número')
            .matches(
                /[^\w\s]/,
                'A palavra-passe deve conter pelo menos um caractere especial',
            ),
        otherwise: ()=> Yup.string().notRequired(),
    } as any),
});